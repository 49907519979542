 import React, { useEffect, useState } from 'react'
import { Button } from '../../components/ui/Button'
import IconAdd from '../../assets/svg/IconAdd'
import IconAddressGlobal from '../../assets/svg/IconAddressGlobal'
import AddAddressModal from '../../components/model/AddAddressModal'
import SuccessMessageModal from '../../components/model/SuccessMessageModal'
import { SEND_API_URL } from '../../api/api'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const SelectAddress = () => {
  const [selectedAddress, setSelctedAddress] = useState(0);
  const [openAddAddressModal, setOpenAddAddressModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [address, setAddress] = useState([])
  const [selectedCoin, setSelectedCoin] = useState();
  const location = useLocation();
  const axiosInstance = useAxiosPrivate();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOpenAddAddressModal = () => {
    setOpenAddAddressModal(true)
  }
  const handleCloseAddAddressModal = () => {
    setOpenAddAddressModal(false)
  }
  const handleOpenSuccessModal = () => {
    setOpenSuccessModal(true)
  }
  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false)
  }

  useEffect(() => {
    if (location) {
      setSelectedCoin(location?.state?.coin)
    }
  }, [location])

  async function getAddress() {
    if (selectedCoin !== undefined && selectedCoin?.id !== undefined) {
      const response = await axiosInstance.get(SEND_API_URL.getAddress + '?currencyId=' + selectedCoin?.id);
      return response.data;
    }
  }
  const { data: addresList, refetch, isLoading } = useQuery(['addresList'], () => getAddress(), { keepPreviousData: true, })

  useEffect(() => {
    if (addresList) {
      setAddress(addresList);
    }
  }, [addresList])


  const handleAddress = (address) => {
    setSelctedAddress(address)
    navigate("/dashboard/send", { state: { address: address, coin: selectedCoin } })
  }
  const handleGoBack = () => {
    navigate("/dashboard/send", { state: { address: address, coin: selectedCoin } })
  }

  useEffect(() => {
    if (location && selectedCoin) {
      refetch();
    }
  }, [location, selectedCoin, refetch]);

  return (
    <div className="sm:p-12 p-6 bg-dark h-full">
      <div className='sm:w-[80%] bg-card min-h-full mx-auto rounded-[20px] border border-light-100 flex flex-col md:p-12 p-8'>
        <div className="md:flex block justify-between items-center mb-12">
          <p className='text-white text-20 font-500'>{t('Address_List')}</p>
          <div className="flex items-center md:justify-end justify-between gap-4">
            <div className="py-2 md:px-8 px-4 bg-dark flex items-center gap-3 rounded-full border border-light-100 cursor-pointer" onClick={handleOpenAddAddressModal}>
              <IconAdd className="text-white w-[20px]" />
              <p className='text-white md:text-[16px] text-[14px]'>{t('Add_Address')}</p>
            </div>
            <div className="py-2 px-8 bg-dark flex items-center gap-4 rounded-full border border-light-100 cursor-pointer" onClick={handleGoBack}>
              <p className='text-white'>{t('Back')}</p>
            </div>
          </div>
        </div>
        <div className={`flex flex-col ${address?.length == 0 ? "flex-grow justify-center" : ""} gap-8`}>
          {
            address?.length > 0 ?
              address?.map((data, index) =>
                <div className={`p-3 w-full bg-dark flex justify-between items-center rounded-full cursor-pointer`} onClick={() => handleAddress(data)}>
                  <div className="token-detail flex items-center gap-4">
                    <div className="w-[44px] h-[44px] flex justify-center items-center rounded-full bg-light-theme flex-shrink-0">
                      <IconAddressGlobal className="text-theme" />
                    </div>
                    <div className="text flex-grow">
                      <h6 className='text-14 text-white mb-1'>{data?.code ?? data?.coin}</h6>
                      <p className='text-12 text-gray break-all'>{data?.address}</p>
                    </div>
                  </div>
                </div>
              )
              : <p className='text-white text-10 font-500 text-center'>{t('Address_not_found')}</p>
          }
        </div>

        {/* <div className="mt-auto">
          <Button className="w-full mt-20" size="sm">{t('Select')}</Button>
        </div> */}
      </div>
      <AddAddressModal open={openAddAddressModal} handleClose={handleCloseAddAddressModal} handleOpenSuccessModal={handleOpenSuccessModal} selectedCoinFromSend={selectedCoin} />
      <SuccessMessageModal
        open={openSuccessModal}
        handleClose={handleCloseSuccessModal}
        message={"Send Successfully"}
      />
    </div >
  )
}

export default SelectAddress
import React, { useEffect, useState } from 'react'
import { Button } from '../../components/ui/Button'
import Input from '../../components/ui/Input'
import CustomSelect from '../../components/ui/CustomSelect'
import { Form, FormikProvider, useFormik } from 'formik'
import { createAppSchema } from '../../validation/CommonValidation'
import { APP_API_URL, SEND_API_URL } from '../../api/api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import MultiSelectInput from '../../components/ui/MultiSelectInput'

const CreateApi = () => {
  const [selectedStatus, setSelectedStatus] = useState("");
  const [enableNotifications, setEnableNotification] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const axiosInstance = useAxiosPrivate();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [editData, setIsEditData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [tokens, setTokens] = useState([]);
  const { t } = useTranslation();



  async function getCoin() {
    const response = await axiosInstance.get(SEND_API_URL.getCoin);
    return response.data;
  }

  const { data: coinList } = useQuery(['coinList'], () => getCoin(), { keepPreviousData: true, })

  useEffect(() => {
    if (coinList) {
      const activeCoins = coinList?.filter(token => token.status === 1 && token?.isPaymentLink === 1);
      setTokens(activeCoins);
    }
  }, [coinList])

  let selectedCurrencies = [];
  useEffect(() => {
    if (state?.appDetails) {
      setIsEdit(true)
      setIsEditData(state?.appDetails)


      state?.appDetails?.currencies?.map((item) => {
        selectedCurrencies.push(item?._id)
      })

      const status = state?.appDetails?.status === 1 ? "Active" : "Inactive"
      const type = state?.appDetails?.withdrawType === 0 ? "Manual" : "Automatic"
      setSelectedStatus(status)
      setWithdrawType(type)
      if (state?.appDetails?.enablePaidNotification == 1) {

        setEnableNotification(true)
      } else {
        setEnableNotification(false)
      }
    }
  }, [state])

  const status = [
    'Active',
    'Inactive',
  ]
  const withdrawTypeOption = ['Manual', 'Automatic']

  const handleSelectedStatus = (status) => {
    setSelectedStatus(status)
    setValues({ ...formik.values, status: status });
  }

  const [withdrawType, setWithdrawType] = useState("");

  const handleSelectedWithdrawType = (type) => {
    setWithdrawType(type)
    setValues({ ...formik.values, withdrawType: type });
  }


  const handleEnableNotification = (e) => {
    if (e.target.checked) {
      setEnableNotification(true)
    } else {
      setEnableNotification(false)
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: editData?.title || "",
      whitelistedIPAddress: editData?.whitelistedIPAddress,
      status: editData?.status == 2 ? "Inactive" : "Active" || "", // 2 for pending status 1 for active status
      description: editData?.description || "",
      enablePaidNotification: "", // 1 for check, 2 for uncheck
      currencyIds: editData?.currencies?.length > 0 ? selectedCurrencies : [],
      withdrawType: editData?.withdrawType == 0 ? 'Manual' : 'Automatic' || withdrawType == 'Manual' ? 0 : 1,
    },
    validationSchema: createAppSchema(t),
    onSubmit: async (values, { setSubmitting }) => {

      const appData = {
        title: values?.title,
        whitelistedIPAddress: values?.whitelistedIPAddress,
        status: selectedStatus == 'Active' ? 1 : 2,
        description: values?.description,
        enablePaidNotification: enableNotifications == true ? 1 : 2,
        currencyIds: values?.currencyIds,
        withdrawType: withdrawType == 'Manual' ? 0 : 1,
      }

      if (isEdit) {
        appData.appId = editData?.id
      }

      setLoading(true);
      await createApp(appData)
    }
  });

  const { mutateAsync: createApp } = useMutation(
    async (data) => {
      try {
        let response;
        if (!isEdit) {
          response = await axiosInstance.post(APP_API_URL.createApp, data);
        } else {
          response = await axiosInstance.post(APP_API_URL.updateApp, data);
        }
        if (response) {
          const message = !isEdit ? "App Created!" : "App Updated!";
          enqueueSnackbar(message, {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 1000,
          });
          navigate("/dashboard/api");
          setSubmitting(false);
          setLoading(false);
          resetForm();
          setSelectedStatus("")
        }
      } catch (error) {
        if (error?.response?.data.errors) {
          setLoading(false);
          const errors = error?.response?.data.errors;
          Object.keys(errors).map(function (key) {
            enqueueSnackbar(errors[key], {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        }
      }
    }
  );

  const {
    handleSubmit,
    getFieldProps,
    errors,
    touched,
    setValues,
    setSubmitting,
    resetForm,
    setFieldValue
  } = formik;

  // useEffect(() => {
  //   console.log(formik.values);
  // }, [])


  const handleSelectedCurrencies = (currencies) => {
    const selectedTokenArray = currencies?.map(selectedItem => selectedItem?.id);

    setValues({
      ...formik.values,
      currencyIds: selectedTokenArray,
    });

  }



  return (
    <div className="p-12 bg-dark h-full">
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <div className='sm:w-[80%] bg-card min-h-full mx-auto rounded-[20px] border border-light-100 flex flex-col p-12'>
            <p className='text-white text-20 font-500 mb-12'>{isEdit ? "Edit Api " : t('Create_API')}</p>
            <div className="flex gap-12 sm:flex-row flex-col">
              <div className='sm:w-[50%]'>
                <Input lable="Title" lableClass="text-14" className="bg-dark" placeholder={t("Enter_Your_API_Title")}   {...getFieldProps('title')}
                  errorMsg={errors.title && touched.title ? errors.title : null} />
                <label htmlFor="" className='!text-white text-14 font-500 mb-4 block mt-8'>
                  Whitelisted IP Address
                </label>
                <textarea cols={3} rows={4} className="w-full p-6 bg-dark mb-4 rounded-3xl text-white text-14 outline-0 font-400 resize-none theme-scrollbar" placeholder={t("Enter_Your_API_Title")}   {...getFieldProps('whitelistedIPAddress')}
                />
                {errors.whitelistedIPAddress && touched.whitelistedIPAddress && (
                  <span className="text-red-500 text-xs mt-1 mb-2" style={{ color: "red", fontSize: '13px', margin: '8px' }}>{errors.whitelistedIPAddress}</span>
                )}
                <p className='text-gray text-12 sm:mb-12 mb-8'>Separate by comma.Leave empty to allow all ip addresses.Example: 52.28.22.118, 2001:db8:0:0:0:ff00:42:8329</p>

                <MultiSelectInput data={tokens} getMultiSelectedToken={handleSelectedCurrencies} editData={editData} errorMsg={errors.currencyIds && touched.currencyIds ? errors.currencyIds : null} />

              </div>
              <div className='sm:w-[50%]'>
                <label htmlFor="" className='!text-white text-14 font-500 mb-4 block'>
                  Status
                </label>
                <CustomSelect listButtonRootClass="w-full justify-between rounded-full bg-dark p-[14px] ps-[16px]" data={status}
                  getChildData={handleSelectedStatus} selectedApiStatus={selectedStatus} errorMsg={errors.status && touched.status ? errors.status : null} type="status" />

                <label htmlFor="" className='!text-white text-14 font-500 mb-4 block mt-8'>
                  Integration URL Description
                </label>
                <textarea cols={3} rows={4} className="w-full p-6 bg-dark mb-4 rounded-3xl text-white text-14 outline-0 font-400 resize-none theme-scrollbar" placeholder='Type Description'   {...getFieldProps('description')}
                />
                {errors.description && touched.description && (
                  <span className="text-red-500 text-xs mt-1 mb-2" style={{ color: "red", fontSize: '13px', margin: '8px' }}>{errors.description}</span>
                )}
                <p className='text-gray text-12 mb-8'>{t("Provide_the_URL_or_description_where_you_plan_to_integrate_the_API_Using_the_API_on_a_different_website_or_multiple_websites_may_result_in_suspension_of_your_account")}</p>
                <label htmlFor="" className='!text-white text-14 font-500 mb-4 block'>
                  Withdraw type
                </label>
                <CustomSelect listButtonRootClass="w-full justify-between rounded-full bg-dark p-[14px] ps-[16px]" data={withdrawTypeOption}
                  getChildData={handleSelectedWithdrawType} selectedApiStatus={withdrawType} errorMsg={errors.withdrawType && touched.withdrawType ? errors.withdrawType : null} type="status" />

                <div className='form-item flex items-start mt-6' >
                  <input type="checkbox" className=' flex-shrink-0 mt-1 bg-transparent api-checkbox' onChange={handleEnableNotification}
                    checked={enableNotifications} />
                  <div className='text-14 ml-4 text-white font-400'>{t("Send_a_notification_to_my_email_each_time_a_payment_is_received_with_this_app")}</div>
                </div>
              </div>
            </div>
            <div className="mt-auto">
              <Button disabled={isLoading} className={`w-full mt-20 text-[14px] text-white ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`} size="sm">{isLoading ? t('loading') : t('Submit')}</Button>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </div>
  )
}

export default CreateApi
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import IconClose from '../../assets/svg/IconClose'
import { Button } from '../ui/Button'
import Input from '../ui/Input'
import CustomSelect from '../ui/CustomSelect'
import { PAYMENT_LINK_API_URL, SEND_API_URL } from '../../api/api'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Form, FormikProvider, useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { _randomString, generateJwt, generateToken } from '../../Helper'
// import CryptoJS, { enc } from 'crypto-js';
// import jwt from "jwt-simple"
import * as jose from 'jose'
import { paymentLinkchema } from '../../validation/CommonValidation'
import { useTranslation } from 'react-i18next'
import CustomTimeSelect from '../ui/CustomTimeSelect'



const generatePayload = (expirationSeconds, issuedAtSeconds, signatureString) => {
  const currentTimestamp = Math.floor(Date.now() / 1000);

  // Calculate expiration time and issued at time
  const exp = currentTimestamp + expirationSeconds;
  const iat = issuedAtSeconds ? currentTimestamp - issuedAtSeconds : currentTimestamp;

  // Generate a unique JWT ID (jti)

  // Create the payload object
  const payload = {
    exp,
    iat,
    // jti,
    payload: {
      signatureString,
    },
  };

  return payload;
};

const times = [
  '5 Minutes',
  '10 Minutes',
  '15 Minutes',
  '20 Minutes',
  '25 Minutes',
  '30 Minutes',
]

const PaymentLinkModal = ({ open, handleClose }) => {

  const [coins, setCoins] = useState([]);
  const [selectedCoin, setSelectedCoin] = useState(undefined);
  const [expireTime, setExpireTime] = useState()
  const axiosInstance = useAxiosPrivate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient()
  const [loading, setIsLoading] = useState(false)
  const { t } = useTranslation();

  useEffect(()=>{
    setExpireTime(times[5])
  }, [])





  async function getCoin() {
    const response = await axiosInstance.get(SEND_API_URL.getCoin);
    return response.data;
  }

  const { data: coinList } = useQuery(['coinList'], () => getCoin(), { keepPreviousData: true, })

  useEffect(() => {
    if (coinList) {
      const activeCoins = coinList?.filter(token => token.status === 1 && token?.isPaymentLink === 1);
      setCoins(activeCoins);
    }
  }, [coinList])

  

  const [amount, setAmount] = useState(undefined);
  const handleAmount = (e) => {
    setAmount(e.target.value)
  }
  const expireTimeFormtted = expireTime?.replace(" Minutes", "")
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      coin: selectedCoin ? selectedCoin?.symbol : "",
      amount: amount ? amount : "",
      expireTime: expireTimeFormtted || "",
      email: "",
      note: "",
    },
    validationSchema: paymentLinkchema(t),
    onSubmit: async (values, { setSubmitting }) => {

      setIsLoading(true)
      if (values?.amount < selectedCoin?.minPaymentLinkUSD) {
        setIsLoading(false)
        enqueueSnackbar(`Amount should be grater than ${selectedCoin?.minPaymentLinkUSD}`, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 4000, // Adjust the duration as needed
        });
        return
      }

      const data = {
        currency: selectedCoin?.symbol,
        currencyId: selectedCoin?.id,
        code: selectedCoin?.code,
        currencyName: selectedCoin?.name,
        amount: values?.amount,
        expireTime: expireTimeFormtted,
        email: values?.email,
        note: values?.note
      }
      try {
     
        await createPaymentLink(data);
      } catch (error) {
        console.error("Error generating JWT:", error);
        setIsLoading(false)
      }

    },
  });

  const { mutateAsync: createPaymentLink } = useMutation(
    async (data) => {

      // DO NOT CHANGE THIS SIGNATURE STRING SEQUENCE ( currency:amount;expireTime )
      const signatureString = `${selectedCoin?.id}:${data?.amount};${data.expireTime}`;
      const currentTime = new Date();
      currentTime.setSeconds(currentTime.getSeconds() + 30);
      const claimSet = {
        'urn:example:claim': true,
        'jwtId': _randomString(32),
        'payload': {
          'signatureString': signatureString,
        },
        'iat': Math.floor(Date.now() / 1000), //current time
        "exp": currentTime,
      };

      const jwtToken = await generateJwt(claimSet)
      try {
        const response = await axiosInstance.post(PAYMENT_LINK_API_URL.createPaymentLink, data, {
          headers: {
            'signatureToken': jwtToken,
          },
        })
        if (response) {
          enqueueSnackbar(t('Payment_Link_Created'), {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 1000,
          });
          resetForm();
          setSelectedCoin(undefined);
          setExpireTime(undefined)
          setAmount(undefined)
          formik.setFieldValue('coin', '');
          queryClient.invalidateQueries('paymentList')
          handleClose()
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        if (error?.response?.data.errors) {
          const errors = error?.response?.data.errors;
          Object.keys(errors).map(function (key) {
            enqueueSnackbar(errors[key], {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        } else if (error?.response?.data?.message) {
          enqueueSnackbar(error?.response?.data?.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
      }
    }
  );


  const {
    handleSubmit,
    getFieldProps,
    errors,
    touched,
    setErrors,
    resetForm,
    setValues
  } = formik;
  
  const handleSelectedTime = (value) => {
    setExpireTime(value);
    formik.setFieldValue('amount', formik.values.amount);
    formik.setValues({
      ...formik.values,
      expireTime: value || "",
    });
  }

  const handleSelectedCoin = (value) => {

    setSelectedCoin(value)
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {
        setIsLoading(false)
        return;
      }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all">
                <div className="flex justify-center p-8 bg-card border-b-light-100 border-b">
                  <p className='text-white ms-auto'>{t("Payment_Link")}</p>
                  <IconClose onClick={() => {
                    handleClose()
                    resetForm()
                    setSelectedCoin(undefined)
                    setExpireTime(undefined)
                    setAmount(undefined)
                  }} className="text-white w-[18px] ms-auto cursor-pointer" />
                </div>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <div className="p-12">
                      <label htmlFor="" className='!text-white text-14 font-500 mb-4 block mt-8'>
                        {t('Select_Currency')}
                      </label>
                      <CustomSelect listButtonRootClass="w-full justify-between rounded-full bg-card p-[14px] ps-[16px]" getChildData={handleSelectedCoin} selectedCoin={selectedCoin} data={coins} errorMsg={errors.coin && touched.coin ? errors.coin : null} />

                      <Input
                        lable={t("Amount")} lableClass="text-14 mt-8" className="bg-card" placeholder={t('Please_enter_amount')} errorMsg={errors.amount && touched.amount ? errors.amount : null} {...getFieldProps('amount')} value={formik.values.amount} onChange={(e) => handleAmount(e)} />
                      {
                        selectedCoin?.minPaymentLinkUSD && (
                          <div className="flex items-start justify-end mt-4 sm:flex-row flex-col gap-[8px]">
                            <div>
                              <p className='text-white text-14'>
                                {selectedCoin?.minPaymentLinkUSD}
                                <span className='text-theme'> Minimum {selectedCoin?.code} </span>
                              </p>
                            </div>
                          </div>
                        )
                      }
                      <label htmlFor="" className='!text-white text-14 font-500 mb-4 block mt-8'>
                        {t('Expire_Time')}
                      </label>
                      <CustomTimeSelect listButtonRootClass="w-full justify-between rounded-full bg-card p-[14px] ps-[16px]" data={times} getChildData={handleSelectedTime} selectedCoin={expireTime} errorMsg={errors.expireTime && touched.expireTime ? errors.expireTime : null} type="time" />

                      <Input lable={t('Email_Address')} lableClass="text-14 mt-8" className="bg-card" placeholder={t('Enter_Email_Address')} {...getFieldProps('email')} errorMsg={errors.email && touched.email ? errors.email : null} />

                      <label htmlFor="" className='!text-white text-14 font-500 mb-4 block mt-8'>
                        {t('Note')}
                      </label>
                      <textarea cols={3} rows={4} className="w-full p-6 bg-card rounded-3xl border border-light-100 text-white text-14 outline-0 font-400 resize-none theme-scrollbar" placeholder={t('Enter_Your_Message')} {...getFieldProps('note')} />
                      {/* <Button size="sm" className="w-full mt-12">Create</Button> */}

                      <Button className={`w-full mt-20 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`} size="sm" disabled={loading}>
                        {loading ? t('loading') : t('Create')}
                      </Button>
                    </div>
                  </Form>
                </FormikProvider>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default PaymentLinkModal

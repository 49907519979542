import React, { useState } from "react";
import DownloadApp from "./DownloadApp";
import ScanQr from "./ScanQr";
import BackupKey from "./BackupKey";
import { useTranslation } from "react-i18next";

const GoogleAuth = () => {
  const [step, setStep] = useState(1);
  const { t } = useTranslation();
  const [secretKey, setSecretKey] = useState()
  return (
    <div className="bg-card mx-auto border border-light-100 border-b-0 flex flex-col p-12 h-full">
      <div className="step flex sm:w-[80%] w-[100%] max-w-[500px] flex-col mx-auto relative">
        <div className="flex justify-between relative z-[4]">
          <div className="number bg-linear-horizontal text-white w-[34px] aspect-square flex justify-center items-center rounded-full text-14">
            1
          </div>
          <div
            className={`number ${step >= 2
              ? "bg-linear-horizontal text-white"
              : "bg-light-500 border border-light-100 text-gray"
              } w-[34px] aspect-square flex justify-center items-center rounded-full text-14`}
          >
            2
          </div>
          <div
            className={`number ${step >= 3
              ? "bg-linear-horizontal text-white"
              : "bg-light-500 border border-light-100 text-gray"
              } w-[34px] aspect-square flex justify-center items-center rounded-full text-14`}
          >
            3
          </div>
          <div
            className={`number ${step >= 4
              ? "bg-linear-horizontal text-white"
              : "bg-light-500 border border-light-100 text-gray"
              } w-[34px] aspect-square flex justify-center items-center rounded-full text-14`}
          >
            4
          </div>
          <div
            className={`step-line absolute w-full h-[2px] border border-dashed border-light-300 -z-[2] top-[50%] bg-black`}
          ></div>
          <div
            className={`step-line absolute ${step === 2
              ? "w-[36%]"
              : step === 3
                ? "w-[65%]"
                : step === 3
                  ? "w-80%"
                  : "w-0"
              } h-[2px] border border-dashed border-theme -z-[1] top-[50%] bg-black`}
          ></div>
        </div>
        <div className="sm:flex justify-between absolute w-[calc(100%+50px)] -left-[28px] top-[30px] z-[4] mt-6 hidden">
          <div className="text-14 font-400 text-white text-center">
            {t('Download_App')}
          </div>
          <div
            className={`text-14 font-400 ${step >= 2 ? "text-white" : "text-gray"
              } text-center`}
          >
           {t('Scan_QR_Code')}
          </div>
          <div
            className={`text-14 font-400 ${step >= 3 ? "text-white" : "text-gray"
              } text-center`}
          >
           {t('Backup_Key')}
          </div>
          <div
            className={`text-14 font-400 ${step >= 4 ? "text-white" : "text-gray"
              } text-center`}
          >
            {t('Enabled_Google')} <br /> {t('Authenticator')}
          </div>
        </div>
      </div>
      {step === 1 ? (
        <DownloadApp setStep={setStep} />
      ) : step === 2 ? (
        <ScanQr setStep={setStep} setSecretKey={setSecretKey} />
      ) : (
        <BackupKey setStep={setStep} secretKey={secretKey} />
      )}
    </div>
  );
};

export default GoogleAuth;

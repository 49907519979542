import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import IconClose from '../../assets/svg/IconClose'
import { Button } from '../ui/Button'
import Input from '../ui/Input'
import CustomSelect from '../ui/CustomSelect'
import { SEND_API_URL } from '../../api/api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Form, FormikProvider, useFormik } from 'formik'
import { addAddressSchema } from '../../validation/CommonValidation'
import { useSnackbar } from 'notistack'
import CustomSelectAddressOrigin from '../ui/CustomSelectAddressOrigin'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import { useTranslation } from 'react-i18next'
import { seperateToken } from '../../Helper'
import { logDOM } from '@testing-library/react'
import IcnPast from '../../assets/svg/IcnPast'

const AddAddressModal = ({ open, handleClose, handleOpenSuccessModal, selectedCoinFromSend }) => {
  const [addressOrigin, setAddressOrigin] = useState();
  const [coins, setCoins] = useState();
  const [selectedCoin, setSelectedCoin] = useState();
  const [selectedAddress, setSelectedAddress] = useState();
  const [loading, setLoading] = useState(false);
  const [add, setAdd] = useState()
  const [copiedSentence, setCopiedSentence] = useState('');

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const axiosInstance = useAxiosPrivate();
  const { t } = useTranslation();


  async function getAddressOrigin() {
    const response = await axiosInstance.get(SEND_API_URL.getAddressOrigin);
    return response.data;
  }
  const { data: originAddressList, refetch } = useQuery(['originAddressList'], () => getAddressOrigin(), { keepPreviousData: true, })

  useEffect(() => {
    if (originAddressList) {
      setAddressOrigin(originAddressList?.addressOriginData);
    }
  }, [originAddressList])


  async function getCoin() {
    const response = await axiosInstance.get(SEND_API_URL.getCoin);
    return response.data;
  }
  const { data: coinList } = useQuery(['coinList'], () => getCoin(), { keepPreviousData: true, })

  useEffect(() => {
    if (coinList) {
      setCoins(coinList);
    }
  }, [coinList])

  const getSelectedCoin = (value) => {
    setSelectedCoin(value)
    formik.setFieldValue('coin', value?.id);
  }

  const getSelectedAddress = (value) => {
    setSelectedAddress(value);
    const currentAddress = formik.values.address;
    setAdd(currentAddress)
    formik.setFieldValue('addressOrigin', value?.id);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // coin: selectedCoin ? selectedCoin?.id : "",
      address: add ? add : copiedSentence ? copiedSentence : "",
      addressOrigin: selectedAddress ? selectedAddress?.id : "",
      walletLable: ""
    },
    validationSchema: addAddressSchema(t),
    onSubmit: async (values, { setSubmitting }) => {
      if (selectedCoinFromSend) {
        setLoading(true)

        let response;
        if (values?.address) {
          const data = {
            currency: selectedCoinFromSend?.code,
            address: values?.address
          }
          response = await validateAddress(data)
        }
        if (response?.message) {
          const data = {
            coin: selectedCoinFromSend?.id,
            address: values?.address,
            addressOrgin: selectedAddress?.id,
            walletLabel: values?.walletLable
          }
          await addAddress(data);

        }
      }
    },
  });

  const { mutateAsync: validateAddress } = useMutation(
    async (data) => {
      try {
        return await axiosInstance.post(SEND_API_URL.validateAddress, data).then((res) => res.data)

      } catch (error) {
        setLoading(false)
        if (error?.response?.data.message) {
          enqueueSnackbar(error?.response?.data.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
      }
    },
    {
      onSuccess: (data) => {
      },
      onError: (error) => {
      },

    }
  );
  const { mutateAsync: addAddress } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(SEND_API_URL.addAddress, data)
        if (response) {
          queryClient.invalidateQueries('addresList')
          enqueueSnackbar(response?.data?.message, {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 1000,
          });
          handleClose();
          setLoading(false)
          resetForm();
        }
      } catch (error) {
        setLoading(false)
        if (error?.response?.data.errors) {
          const errors = error?.response?.data.errors;
          Object.keys(errors).map(function (key) {
            enqueueSnackbar(errors[key], {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        }
      }
    },
  );

  const {
    handleSubmit,
    getFieldProps,
    errors,
    touched,
    setErrors,
    resetForm
  } = formik;


  const handlePaste = () => {
    navigator.clipboard.readText()
      .then((text) => {
        setCopiedSentence(text);
      })
      .catch(err => {
        console.error('Failed to read clipboard contents: ', err);
      });
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => { return; }} disableBackdropClick="true">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all">
                <div className="flex justify-center p-8 bg-card border-b-light-100 border-b">
                  <p className='text-white ms-auto'>{t('Add_Address')}</p>
                  <IconClose onClick={() => { handleClose(); resetForm(); }} className="text-white w-[18px] ms-auto cursor-pointer" />
                </div>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <div className="p-12">
                      {/* <div className="mb-8">
                        <label htmlFor="" className='!text-white text-14 font-500 mb-4 block mt-8'>
                          {t('Coin')}
                        </label>
                        <CustomSelect listButtonRootClass="w-full justify-between rounded-full bg-dark p-[14px] ps-[16px]" data={coins} getChildData={getSelectedCoin} selectedCoin={selectedCoinFromSend} errorMsg={errors.coin && touched.coin ? errors.coin : null} />
                      </div> */}

                      <Input lable={t('Address_or_name')} lableClass="text-14" className="bg-dark" placeholder="ENS, .bnb, .eth, .crypto etc..." errorMsg={errors.address && touched.address ? errors.address : null}  {...getFieldProps('address')} suffix={<IcnPast className="h-[20px] w-[20px] text-white cursor-pointer" onClick={() => handlePaste()} />} suffixClass="bg-transparent" />
                        
                      {addressOrigin?.length > 0 ?
                        <>
                          <label htmlFor="" className='!text-white text-14 font-500 mb-4 block mt-8'>
                            {t('Address_Origin')}
                          </label>
                          <CustomSelectAddressOrigin listButtonRootClass="w-full justify-between rounded-full bg-dark p-[14px] ps-[16px] mb-8" data={addressOrigin} getChildData={getSelectedAddress} errorMsg={errors.addressOrigin && touched.addressOrigin ? errors.addressOrigin : null} />
                        </>
                        :
                        <>
                          <Input lable={t('Address_Origin')} disabled classWrapper="mt-8" lableClass="text-14" className="bg-dark" placeholder="No origin found" />

                        </>
                      }


                      <Input lable={t('wallet_lable')} classWrapper="mt-8" placeholder='Wallet lable' lableClass="text-14" className="bg-dark" errorMsg={errors.walletLable && touched.walletLable ? errors.walletLable : null} {...getFieldProps('walletLable')} />
                      {/* <CustomSelect listButtonRootClass="w-full justify-between rounded-full bg-dark p-[14px] ps-[16px]" data={walletLable} /> */}
                      <Button className={`w-full mt-20 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`} size="sm" disabled={loading}>
                        {loading ? 'Loading...' : 'Save'}
                      </Button> 
                    </div>
                  </Form>
                </FormikProvider>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default AddAddressModal

import React from 'react';

const IcnDot = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
            <circle xmlns="http://www.w3.org/2000/svg" cx="40" cy="1230" r="6" transform="translate(-30 -1220)" fill="currentColor" class="color000 svgShape"></circle>
        </svg>
    );
}

export default IcnDot;

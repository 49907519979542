import CryptoJS, { enc } from 'crypto-js';
import * as jose from 'jose'


const handleEncrypt = (originalWords) => {
    const encryptionKey = '3b229739d7d2c70a45548dfd35eee2e28d8e9dc1744487c64fc99b1610e59a8b'
    const encryptionIV = 'b7a9841bf2d98d3b37d75e9f1747b126'
    // Convert the encryption key and IV to WordArray
    const key = CryptoJS.enc.Utf8.parse(encryptionKey);
    const iv = CryptoJS.enc.Utf8.parse(encryptionIV);
    const originalWordsString = JSON.stringify(originalWords);


    // Encrypt the original words
    const encrypted = CryptoJS.AES.encrypt(originalWordsString, key, { iv: iv });
    return encrypted?.toString()
};


const handleDecrypt = (encryptedData) => {

    const encryptionKey = '3b229739d7d2c70a45548dfd35eee2e28d8e9dc1744487c64fc99b1610e59a8b'
    const encryptionIV = 'b7a9841bf2d98d3b37d75e9f1747b126'
    // Convert the encryption key and IV to WordArray
    const key = CryptoJS.enc.Utf8.parse(encryptionKey);
    const iv = CryptoJS.enc.Utf8.parse(encryptionIV);

    // Decrypt the encrypted data
    const decrypted = CryptoJS.AES.decrypt(encryptedData, key, { iv: iv });
    const decryptedWords = decrypted.toString(CryptoJS.enc.Utf8);
    return decryptedWords;
};

const files = (name, type) => {
    switch (type) {
        case "image":
            return `${process.env.REACT_APP_BASE_URL}/uploads/attachments/${name}`;

        case "file":
            return `${process.env.REACT_APP_BASE_URL}/uploads/files/${name}`;

        case "audio":
            return `${process.env.REACT_APP_BASE_URL}/uploads/audio/${name}`;

        case "video":
            return `${process.env.REACT_APP_BASE_URL}/uploads/images/${name}`;

        default:
            return `${process.env.REACT_APP_BASE_URL}/uploads/images/${name}`;
    }
}

const DateFomat = (originalDateString) => {
    const dateObject = new Date(originalDateString);
    const dateOptions = { day: 'numeric', month: 'short', year: '2-digit' };
    const timeOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };

    const formattedDate = dateObject.toLocaleDateString('en-US', dateOptions);
    const formattedTime = dateObject.toLocaleTimeString('en-US', timeOptions);

    return `${formattedDate}, ${formattedTime}`;
}

function getMonthName(month) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[month];
}

// Function to format time
function formatTime(hours, minutes) {
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

const formatDate = (originalDateString) => {
    const timestamp = originalDateString;
    const date = new Date(timestamp);

    // Format the date into desired string format
    const formattedDate = `${date.getDate()} ${getMonthName(date.getMonth())} ${date.getFullYear()} ${formatTime(date.getHours(), date.getMinutes())}`;
    return formattedDate
}

const _randomString = (length) => {

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};


const generateJwt = async (claimSet) => {
    return new Promise(async (resolve, reject) => {
        try {
            const secret = new TextEncoder().encode('P@ym3nt_L!nk');
            const alg = 'HS256';

            const jwt = await new jose.SignJWT(claimSet)
                .setProtectedHeader({ alg })
                .setIssuedAt()
                .setIssuer('urn:example:issuer')
                .setAudience('urn:example:audience')
                .setExpirationTime('2h')
                .sign(secret);

            resolve(jwt);
        } catch (error) {
            reject(error);
        }
    });
};

// date formatter 2024-01-22T06:53:25.458Z -> 03-12-2019 / 09:51:51
const dateFormatter = (originalDate) => {
    const dateToFormat = new Date(originalDate);

    const formattedDate = dateToFormat.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    });

    return formattedDate
}

function maskEmail(email) {
    const atIndex = email?.indexOf('@');
    if (atIndex > 2) {
        const visiblePart = email?.substring(0, 2);
        const maskedPart = '*'.repeat(atIndex - 2);
        const domainPart = email?.substring(atIndex);
        return visiblePart + maskedPart + domainPart;
    } else {
        // Handle invalid email case
        return email;
    }
}

function seperateToken(token) {
    const separatorIndex = token?.indexOf('_');
    const coin = token?.substring(0, separatorIndex);
    return coin;
}


const checkFileSize = (size) => {

    if ((size / 1024 / 1024) > 1) // checking file size is greater then 1 MB
        return false;

    return true;
}


const checkFileType = (validImageTypes, fileType) => {
    if (!validImageTypes.includes(fileType)) {
        return false;
    }

    return true;
}

const toFixedWithoutZeros = (num, precision) => {
    return `${1 * parseFloat(num).toFixed(precision)}`;
};



const fileSizeExceedMsg = "File size must be less then 1 Mb";
const fileTypeMismatchMsg = "Only png,jpg and jpeg files are allowed!";




function fixDecimal(amount) {
    // Convert the amount to a string and split it using the decimal point
    const decimalCount = amount?.toString().split('.')[1]?.length || 0;

    // If decimal points are greater than 8, fix it to 8, else return original value
    if (decimalCount > 8) {
        // Convert the amount to a string with fixed 8 decimal places
        return parseFloat(amount).toFixed(8);
    } else {
        return amount;
    }
}


function checkKyc(user) {
    switch (user?.kycStatus) {
        case 2:
            return "KYC In-Review";
        case 1:
            return "KYC Unverified";
        case 0:
            return "KYC Now";
        case 3:
            return "KYC Failed";
        case 5:
            return "KYC Final Rejected";
        case 6:
            return "KYC Approved";
        default:
            break;
    }
}
function kycColourCode(user) {
    switch (user?.kycStatus) {
        case 2:
            return "#f0b931";
        case 1:
            return "#F61D1D";
        case 0:
            return "linear-gradient(-180deg, #2FD0DE 0%, #7684ED 50%, #CA24FD 100%)";
        case 3:
            return "#fc0b03";
        case 5:
            return "#fc0b03";
        case 6:
            return "linear-gradient(-180deg, #2FD0DE 0%, #7684ED 50%, #CA24FD 100%)";
        default:
            break;
    }
}




export {
    handleEncrypt,
    handleDecrypt,
    files,
    DateFomat,
    _randomString,
    generateJwt,
    dateFormatter,
    maskEmail,
    seperateToken,
    checkFileSize,
    fileSizeExceedMsg,
    checkFileType,
    fileTypeMismatchMsg,
    formatDate,
    toFixedWithoutZeros,
    fixDecimal,
    checkKyc,
    kycColourCode
}
import React from 'react';

const IconPlus = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <path d="M20 34.3734C18.9211 34.3734 18.0469 33.4992 18.0469 32.4203V7.57968C18.0469 6.50078 18.9211 5.62656 20 5.62656C21.0789 5.62656 21.9531 6.50078 21.9531 7.57968V32.4203C21.9531 33.4992 21.0789 34.3734 20 34.3734Z" fill="currentColor" />
            <path d="M32.4207 21.9531H7.58008C6.50117 21.9531 5.62695 21.0789 5.62695 20C5.62695 18.9211 6.50117 18.0469 7.58008 18.0469H32.4207C33.4996 18.0469 34.3738 18.9211 34.3738 20C34.3738 21.0789 33.4996 21.9531 32.4207 21.9531Z" fill="currentColor" />
        </svg>

    );
}

export default IconPlus;

import React, { useRef, useState } from 'react';
import AuthInput from '../../ui/AuthInput';
import { Button } from '../../ui/Button';
import { Form, FormikProvider, useFormik } from 'formik';
import { contactUsSchema } from '../../../validation/CommonValidation';
import { useNavigate } from 'react-router-dom';
import { SUPPORT_TICKET_API_URL, USER_API_URL } from '../../../api/api';
import { useMutation } from 'react-query';
import useAxiosPrivate from '../../../api/useAxiosPrivate';
import { useSnackbar } from 'notistack';
import FileUploadDragAndDrop from '../../ui/FileUploadDragDrop';
import IconClose from '../../../assets/svg/IconClose';

const ContactForm = () => {
    const navigate = useNavigate();
    const [uploadFile, setUploadFile] = useState('');
    const [isFilePreview, setIsFilePreview] = useState('');
    const axiosInstance = useAxiosPrivate();
    const fileInputRef = useRef();
    const [isFormSubmiting,setIsFormSubmiting] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const { mutateAsync: uploadImage } = useMutation(
        async () => {
            try {
                const formData = new FormData();
                formData.append("images", uploadFile)
                const response = await axiosInstance.post(USER_API_URL.uploadUserImage, formData, {
                    headers: {
                        'Content-type': 'multipart/form-data',
                    },
                });
                if (response) {
                    return response?.data
                }
            } catch (error) {
                if (error?.response?.data?.message) {
                    enqueueSnackbar(error?.response?.data.message, {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
            }
        }
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
            email: "",
            message: "",
        },
        validationSchema: contactUsSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setIsFormSubmiting(true);
            let image;
            if (isFilePreview) {
                image = await uploadImage();
            }
            const data = {
                name: values.name,
                email: values.email,
                message: values.message,
                attachment: image?.[0]
            }
            await addContact(data)
            setIsFormSubmiting(false);
        }
    });

    const {
        handleSubmit,
        getFieldProps,
        errors,
        touched,
    } = formik;


    const { mutateAsync: addContact } = useMutation(
        async (data) => {
            try {
                const response = await axiosInstance.post(USER_API_URL.addContactUs, data);
                if (response) {
                    enqueueSnackbar("Support request sent successfully! Our team will contact you soon.", {
                        variant: "success",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                    navigate('/')
                }
            } catch (error) {
                if (error?.response?.data.errors) {
                    const errors = error?.response?.data.errors;
                    Object.keys(errors).map(function (key) {
                        enqueueSnackbar(errors[key], {
                            variant: "error",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: 2000,
                        });
                    });
                }
            }
        }
    );

    const handleCloseImagePreview = (event) => {
        setIsFilePreview(false);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }

    return (
        <div className="bg-light-200 border border-light-gray rounded-[20px] lg:p-[50px_170px] md:p-[30px_80px] p-[20px] text-center">
            <h2 className='md:text-[42px] sm:text-[36px] text-[28px] md:mb-12 mb-6'>Submit a Request</h2>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <div className="form-group md:mb-12 mb-6 text-start">
                        <AuthInput lable="Your Name" placeholder="Enter Name" className="border-light-gray bg-white"    {...getFieldProps('name')} errorMsg={errors.name && touched.name ? errors.name : null} />
                    </div>
                    <div className="form-group md:mb-12 mb-6 text-start">
                        <AuthInput lable="Your Email" placeholder="Enter Your Email" className="border-light-gray bg-white"  {...getFieldProps('email')}
                            errorMsg={errors.email && touched.email ? errors.email : null} />
                    </div>
                    <div className="form-group md:mb-12 mb-6 text-start">
                        <label className='text-black text-16 font-500 block mb-4' style={{ textAlign: 'left' }}>Description</label>
                        <textarea
                            cols={50}
                            rows={4}
                            className='w-full text-light-600 text-14 font-400 outline-none border border-light-gray rounded-[16px] p-[14px] ps-[28px] resize-none' // Reduce margin from 8 to 2
                            placeholder="Enter Your Message"
                            {...getFieldProps('message')}
                        />
                        {errors.message && touched.message && (
                            <p className='text-[15px] text-red-500 font-normal m-[8px]' style={{ color: "red" }}>{errors.message}</p>
                        )}
                        <p className='md:text-16 text-14 font-500'>Please enter the details of your request. A member of our support staff will respond as soon as possible.</p>
                    </div>
                    {
                        isFilePreview && (
                            <div style={{ textAlign: 'left', margin: '7px', position: 'relative', width: '140px', height: '140px' }}>
                                <img
                                    src={isFilePreview}
                                    alt="Selected Preview"
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                        marginLeft: '0',
                                        objectFit: 'cover'
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        height: '24px',
                                        width: '24px',
                                        top: '-5px',
                                        right: '-5px',
                                        backgroundColor: 'white',
                                        borderRadius: '50%',
                                        padding: '5px',
                                        zIndex: 1,
                                        cursor: 'pointer'
                                    }}
                                    onClick={handleCloseImagePreview}
                                >
                                    <IconClose />
                                </div>
                            </div>
                        )
                    }
                    <div className="form-group md:mb-12 mb-6 text-start">
                        <FileUploadDragAndDrop lable="Attachments (optional)" accept={'.jpeg,.png,.jpg'} setUploadFile={setUploadFile} setIsFilePreview={setIsFilePreview} fileInputRef={fileInputRef} errorMsg={errors.name && touched.name ? errors.name : null} />
                    </div>
                    <div className="w-full text-center">
                        <Button variant="filled" className="w-1/2 text-14 font-700 bg-linear border-0 text-white" size={"sm"} disabled={isFormSubmiting}>
                            {isFormSubmiting ? "loading..." : "Submit"}
                        </Button>
                    </div>
                </Form>
            </FormikProvider>
        </div >
    );
}



export default ContactForm;

import React from 'react'

const IconReceiveWallet = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.45927 18.5407C5.31862 18.4001 5.2396 18.2093 5.2396 18.0104L5.2396 9.42613C5.2396 9.01192 5.57539 8.67613 5.9896 8.67613C6.40381 8.67613 6.7396 9.01192 6.7396 9.42613L6.7396 16.1997L17.4801 5.45926C17.773 5.16637 18.2479 5.16637 18.5407 5.45926C18.8336 5.75215 18.8336 6.22703 18.5407 6.51992L7.80026 17.2604L14.5739 17.2604C14.9881 17.2604 15.3239 17.5962 15.3239 18.0104C15.3239 18.4246 14.9881 18.7604 14.5739 18.7604L5.9896 18.7604C5.79069 18.7604 5.59992 18.6814 5.45927 18.5407Z" fill="currentcolor" />
    </svg>
  )
}

export default IconReceiveWallet
import React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import IconDown from '../../assets/svg/IconDown';
import { cn } from '../../lib/utils';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';
import IconLink from '../../assets/svg/IconLink';
import { files } from '../../Helper';
import IcnLogo from '../../assets/svg/IcnLogo';

const CustomSelect = ({ rootClass, errorMsg, data, listButtonTextClass, listButtonRootClass, listDropRootClass, getChildData, selectedApiStatus, type, from, to, selectedCoin }) => {
  const [selectedId, setSelectedId] = useState();
  const [dropDownData, setDropDownData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setDropDownData(data);
  }, [data])

  useEffect(() => {
    if (selectedCoin == undefined) {
      setSelectedId(null)
    } else {
      setSelectedId(selectedCoin)
    }
  }, [selectedCoin])

  useEffect(() => {
    if (selectedApiStatus) {
      setSelectedId(selectedApiStatus)
    }
  }, [selectedApiStatus])


  // const selectedOption = (type === "time" || type === "status") ? selectedId : dropDownData?.find(option => option?.symbol === selectedId?.symbol);

  const selectedOption = dropDownData?.find(option => option?.id == selectedId?.id);


  useEffect(() => {
    if (selectedOption) {
      getChildData(selectedOption);
    }
  }, [selectedOption]);


  useEffect(() => {
    if (from) {

      setSelectedId(from)
    }
  }, [from])

  useEffect(() => {
    if (to) {
      setSelectedId(to)
    }
  }, [to])


  return (
    <Listbox value={selectedId} onChange={setSelectedId}>
      <div className={cn('relative', rootClass)}>
        <Listbox.Button className={cn("relative px-4 py-[10px] ps-[16px] bg-[#262626] flex items-center justify-center text-white border border-[#b3b3b31a] rounded-[10px] gap-2", listButtonRootClass)}>
          <div className="flex items-center gap-[10px]">
            {selectedOption?.icon ? <img src={files(selectedOption?.icon, 'image')} alt='' className='h-[20px] w-[20px] rounded-full object-cover' /> : ''}
            <p className={cn("block truncate text-14 font-400", listButtonTextClass)}>
              {dropDownData ? (selectedOption?.name ? selectedOption?.name : selectedId || t('Select_an_option')) : "Loading..."}
              <span className='pl-[6px]'>
                {dropDownData && selectedOption?.code ? (`(${selectedOption?.code ? selectedOption?.code : selectedId})`) : ""}
              </span>
            </p>
          </div>
          <span className="pointer-events-none">
            <IconDown className="w-6 h-6 text-dark-950" />
          </span>
        </Listbox.Button>
        <Transition
          as={React.Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div>
            <Listbox.Options className={cn("absolute z-10 mt-1 w-full px-4 py-[10px] bg-[#262626] rounded-md cursor-pointer border border-[#b3b3b31a] max-h-[280px] overflow-y-auto overflow-x-hidden theme-scrollbar", listDropRootClass)}>
              {dropDownData?.map((value, index) => {
               
                return (
                  <Listbox.Option
                    key={index}
                    value={value}
                    className={"py-[6px] cursor-pointer"}
                  >
                    {({ selected }) => (
                      <div className='flex items-center gap-[10px]'>
                        {value?.icon && (value?.icon ?

                          (value?.icon || value?.icon !== '') ? <img src={files(value?.icon, 'image')} alt='' className='h-[32px] w-[32px] rounded-full object-cover' /> : ""
                          :
                          <div className='xxl:w-[32px] w-[32px] xxl:h-[32px] h-[32px] rounded-full bg-theme' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 500, borderRadius: '50%', fontSize: '22px', }}>{value?.name?.slice(0, 1).toUpperCase()}</div>
                        )}
                        <div
                          className="text-14 font-400 text-dark-950 text-white flex items-center gap-[8px]"
                        >
                          <p className="">
                            {value?.name ? value?.name : value?.to ? value?.to : value} <span className='text-[14px] text-white/60 pl-[2px]'>{value?.symbol}</span>
                          </p>
                          {value?.tokenId && <button className='text-gray sm:text-14 text-12 bg-dark p-[2px_6px] rounded-[6px]'>{value?.tokenName}</button>}
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                )
              })}
            </Listbox.Options>
          </div>
        </Transition>
        {errorMsg && <p className={cn('text-12 text-red-500 font-normal')} style={{ color: "red", fontSize: '13px', margin: '8px' }}>{errorMsg}</p>}
      </div>
    </Listbox>
  );
};

export default CustomSelect;


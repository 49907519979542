import React from "react";

const IconEmail = (props) => {
  return (
    <svg {...props} viewBox="0 0 25 25" fill="none">
      <g clip-path="url(#clip0_553_10036)">
        <path
          d="M22.8765 4.0625H3.09521C1.92976 4.0625 0.98584 5.01228 0.98584 6.17188V18.8281C0.98584 19.9946 1.9367 20.9375 3.09521 20.9375H22.8765C24.0322 20.9375 24.9858 19.9986 24.9858 18.8281V6.17188C24.9858 5.01434 24.0457 4.0625 22.8765 4.0625ZM22.5811 5.46875L14.4774 13.5297C14.079 13.9281 13.5493 14.1475 12.9858 14.1475C12.4224 14.1475 11.8927 13.9281 11.493 13.5284L3.39062 5.46875H22.5811ZM2.39209 18.5419V6.45898L8.46887 12.5037L2.39209 18.5419ZM3.39151 19.5312L9.4659 13.4955L10.4999 14.5241C11.1639 15.1881 12.0468 15.5538 12.9858 15.5538C13.9249 15.5538 14.8077 15.1881 15.4704 14.5254L16.5058 13.4955L22.5802 19.5312H3.39151ZM23.5796 18.5419L17.5028 12.5037L23.5796 6.45898V18.5419Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_553_10036">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.98584 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconEmail;

import { Dialog, Switch, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import IconClose from '../../assets/svg/IconClose'
import SearchIcon from '../../assets/images/SearchIcon.png'
import { Button } from '../ui/Button'
import Input from '../ui/Input'
import { files } from '../../Helper'
import { WALLET_API_URL } from '../../api/api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import { enqueueSnackbar } from 'notistack'
import { authStore } from '../../context/AuthProvider'
import { useTranslation } from 'react-i18next'

const AddTokenModal = (
  { open, handleClose, openAddCustomTokenModal }
) => {
  const [tokens, setTokens] = useState([]);
  const [search, setSearch] = useState("");
  const [tokenList, settokenList] = useState([]);
  const axiosInstance = useAxiosPrivate();
  const [enabledStates, setEnabledStates] = useState(new Array(tokens?.length).fill(false));
  const queryClient = useQueryClient();
  const { set, tokenListLocal } = authStore()
  const {t} = useTranslation();
  async function getTokens() {
    const response = await axiosInstance.get(WALLET_API_URL.getToken + '?search=' + search);
    settokenList(response.data)
    return response.data;
  }

  const { data: tokenListData, refetch: refetchTokenList } = useQuery(['tokenListData'], () => getTokens(), { keepPreviousData: true, })

  async function getActiveToken() {
    search.trim();
    const response = await axiosInstance.get(WALLET_API_URL.getActiveToken + '?search=' + search);
    return response.data;
  }
  const { data: activeTokenList, refetch: refetch } = useQuery(['activeTokenList'], () => getActiveToken(), {
    keepPreviousData: true, initialData: tokens, // Provide initial data
    staleTime: 5000,
  })


  async function setToken() {
    const promises = tokenList?.map(async (dt) => {
      const foundToken = tokenListLocal?.find((dtt) => dtt?.id === dt?.id);
      if (foundToken) {
        return foundToken.status === 1;
      }
    });
    if (promises) {
      const updatedEnabledStates = await Promise.all(promises);
      setEnabledStates(updatedEnabledStates);
    }
  }

  useEffect(() => {

    if (tokenList) {
      setTokens(tokenList);
    }
    setToken()
  }, [tokenList, activeTokenList])

  const handleSearch = (event) => {
    refetch();
    setSearch(event.target.value);
  };

  const handleSwitchChange = async (token, index) => {
    const updatedEnabledStates = [...enabledStates];
    updatedEnabledStates[index] = !updatedEnabledStates[index];
    setEnabledStates(updatedEnabledStates);
    const filter = updatedEnabledStates?.filter((value) => value == true);
    if (filter?.length <= 2) {
      updatedEnabledStates[index] = !updatedEnabledStates[index];
      setEnabledStates(updatedEnabledStates);
      enqueueSnackbar("You must active atleast three token!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 1000,
      });
    } else {
        const foundToken = tokenListLocal?.find((dtt) => dtt?.id === token?.id);
        if (!foundToken) {
          set(prevState => ({
            tokenListLocal: [...prevState.tokenListLocal, token]
          }));
        } else {
          set(prevState => ({
            tokenListLocal: prevState.tokenListLocal.filter(dtt => dtt.id !== token.id)
          }));
        }
    }
    queryClient.invalidateQueries('tokenList')

  };

  useEffect(() => {
    getTokens()
  }, [search])


  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => { return; }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all">
                <div className="flex justify-center p-8 bg-card border-b-light-100 border-b">
                  <p className='text-white ms-auto'>{t('Add_Tokens')}</p>
                  <IconClose onClick={handleClose} className="text-white w-[18px] ms-auto cursor-pointer" />
                </div>
                <div className="sm:p-12 p-8 pt-8 h-[80vh] overflow-y-auto theme-scrollbar">
                  <div className="relative">
                    <Input className="mb-8" placeholder={t('Search_Tokens')} onChange={(e) => handleSearch(e)} />
                    <img className='absolute top-[16px] right-[16px] cursor-pointer' src={SearchIcon} alt="" />
                  </div>
                  <div className="flex flex-col gap-6">
                    {
                      tokens?.map((token, index) =>
                        <div key={index} className="p-4 w-full bg-card flex justify-between items-center rounded-3xl">
                          <div className="token-detail flex items-center gap-4">
                            <div className="rounded-full ">
                              {token?.icon ?
                                <img className='xxl:w-[36px] w-[30px] xxl:h-[36px] h-[30px] rounded-full object-cover' src={files(token?.icon, 'image')} alt="" />
                                :
                                <div className='xxl:w-[36px] w-[30px] xxl:h-[36px] h-[30px] rounded-full bg-theme' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 500, borderRadius: '50%', fontSize: '22px', }}>{token?.name?.slice(0, 1).toUpperCase()}</div>
                              }
                            </div>
                            <div className="text">
                              <h6 className='text-14 text-white mb-2'>{token?.name}</h6>
                              <p className='text-12 text-gray'>{token?.symbol}</p>
                            </div>
                          </div>
                          <Switch
                            checked={enabledStates[index]}
                            onChange={() => handleSwitchChange(token, index)}
                            className={`${enabledStates[index] ? 'bg-theme' : 'bg-light-200'}
          relative inline-flex h-[20px] w-[34px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                          >
                            <span className="sr-only">Use setting</span>
                            <span
                              aria-hidden="true"
                              className={`${enabledStates[index] ? 'translate-x-[90%] bg-black' : 'translate-x-0'}
            pointer-events-none inline-block h-[16px] w-[16px] bg-[#262626] transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
                            />
                          </Switch>
                        </div>
                      )}
                  </div>
                  <div className="flex gap-4 mt-8">
                    <Button className="w-full !text-14 bg-transparent border border-white text-white" onClick={openAddCustomTokenModal}>{t('Add_Custom')}</Button>
                    {/* <Button className="w-full text-14">Add Token</Button> */}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default AddTokenModal

import React, { forwardRef, useState } from 'react'
import { cn } from '../../lib/utils'
import IconEye from '../../assets/svg/IconEye';
import IconEyeClose from '../../assets/svg/IconEyeClose';

const Input = forwardRef(({ classWrapper, className, mainClass, lableClass, errorClass, lable, icon, errorMsg, placeholder, suffix, prefix, type, suffixClass,disabled, ...props }, ref) => {
  const [togglePassword, setTogglePassword] = useState(false);

  return (
    <div className={cn('form-group flex flex-col', classWrapper)}>
      {lable && <label className={cn('!text-white text-16 font-500 mb-4', lableClass)}>{lable}</label>}
      <div className={cn('relative', mainClass)}>
        {suffix && <span className={cn('absolute bg-linear-horizontal !text-white text-12 p-1 px-2 rounded-full right-[18px] top-[13px]', suffixClass)}>{suffix}</span> || type === "password" && <span className='toogle-pass-btn absolute right-[18px] top-[15px] cursor-pointer' onClick={() => setTogglePassword(!togglePassword)}>{togglePassword ? <IconEye className="w-5 h-5 text-white" /> : <IconEyeClose className="w-5 h-5 text-white" />}</span>}
        <input ref={ref} type={`${(type === "password" && !togglePassword) ? "password" : type === "number" ? "number" : "text"}`} className={cn(`w-full !text-white text-14 font-500 bg-card rounded-full border border-light-100 ${suffix ? "!pe-[48px] ps-[16px]" : "pe-[14px] ps-[16px]"} ${prefix ? "!ps-[48px] pe-[14px]" : "ps-[16px] pe-[14px]"} py-[14px] outline-0`, className)} placeholder={placeholder} {...props} readOnly={disabled} />
        {prefix && <span className='absolute left-[18px] top-[13px]'>{prefix}</span>}
      </div>
      {errorMsg && <p className={cn('text-12 text-red-500 font-normal', errorClass)} style={{ color: "red", fontSize: '13px', margin: '8px' }}>{errorMsg}</p>}
    </div>
  )
})

export default Input
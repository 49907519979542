import React from "react";

const IconFingerPrint = (props) => {
  return (
    <svg {...props} viewBox="0 0 25 25" fill="none">
      <path
        d="M22.3917 0.5H19.0327C18.6444 0.5 18.3296 0.814812 18.3296 1.20312C18.3296 1.59144 18.6444 1.90625 19.0327 1.90625H22.3917C23.0467 1.90625 23.5796 2.43913 23.5796 3.09406V6.45312C23.5796 6.84144 23.8944 7.15625 24.2827 7.15625C24.671 7.15625 24.9858 6.84144 24.9858 6.45312V3.09406C24.9858 1.66372 23.8221 0.5 22.3917 0.5ZM1.68896 7.15625C2.07728 7.15625 2.39209 6.84144 2.39209 6.45312V3.09406C2.39209 2.43913 2.92496 1.90625 3.57995 1.90625H6.93896C7.32728 1.90625 7.64209 1.59144 7.64209 1.20312C7.64209 0.814812 7.32728 0.5 6.93896 0.5H3.57995C2.14956 0.5 0.98584 1.66372 0.98584 3.09406V6.45312C0.98584 6.84144 1.30065 7.15625 1.68896 7.15625ZM6.93896 23.0938H3.57995C2.92496 23.0938 2.39209 22.5609 2.39209 21.9059V18.5469C2.39209 18.1586 2.07728 17.8438 1.68896 17.8438C1.30065 17.8438 0.98584 18.1586 0.98584 18.5469V21.9059C0.98584 23.3363 2.14956 24.5 3.57995 24.5H6.93896C7.32728 24.5 7.64209 24.1852 7.64209 23.7969C7.64209 23.4086 7.32728 23.0938 6.93896 23.0938ZM24.2827 17.8438C23.8944 17.8438 23.5796 18.1586 23.5796 18.5469V21.9059C23.5796 22.5609 23.0467 23.0938 22.3917 23.0938H19.0327C18.6444 23.0938 18.3296 23.4086 18.3296 23.7969C18.3296 24.1852 18.6444 24.5 19.0327 24.5H22.3917C23.8221 24.5 24.9858 23.3363 24.9858 21.9059V18.5469C24.9858 18.1586 24.671 17.8438 24.2827 17.8438ZM20.6115 5.1853C15.731 1.56514 10.4713 1.47303 5.44768 5.12113C5.13348 5.34931 5.06378 5.789 5.29192 6.1032C5.52006 6.41736 5.9597 6.48716 6.274 6.25897C10.8137 2.96239 15.4015 3.07156 19.7737 6.3147C20.0855 6.54603 20.5259 6.48078 20.7573 6.16887C20.9886 5.85702 20.9233 5.41663 20.6115 5.1853Z"
        fill="currentColor"
      />
      <path
        d="M12.9859 5.42188C9.99884 5.42188 6.1587 7.41073 4.5259 9.86C4.31046 10.1831 4.39779 10.6197 4.7209 10.835C5.04405 11.0503 5.4806 10.9632 5.69595 10.64C7.04965 8.60938 10.4562 6.82812 12.9859 6.82812C15.463 6.82812 18.8509 8.14967 20.2504 10.5988C20.3802 10.826 20.6175 10.9533 20.8616 10.9533C21.3921 10.9533 21.7421 10.3748 21.4714 9.9012C19.8594 7.08013 16.0743 5.42188 12.9859 5.42188ZM9.18902 17C9.18902 14.6996 10.8923 12.8281 12.9859 12.8281C15.0795 12.8281 16.7828 14.6996 16.7828 17V17.75C16.7828 18.1383 17.0976 18.4531 17.4859 18.4531C17.8742 18.4531 18.189 18.1383 18.189 17.75V17C18.189 13.9242 15.8549 11.4219 12.9859 11.4219C10.1169 11.4219 7.78277 13.9242 7.78277 17C7.78277 19.9898 10.0307 21.5915 11.0232 23.0252C11.1597 23.2224 11.379 23.3282 11.602 23.3282C12.1618 23.3282 12.5044 22.6941 12.1794 22.2247C11.1053 20.6732 9.18902 19.3866 9.18902 17Z"
        fill="currentColor"
      />
      <path
        d="M20.7397 19.5644C22.9128 15.2185 19.0485 8.42188 12.9858 8.42188C8.71745 8.42188 4.78271 12.0395 4.78271 16.625C4.78271 18.2898 4.99628 19.6978 6.0385 21.1584C6.26406 21.4745 6.70314 21.5479 7.01926 21.3223C7.33534 21.0968 7.40879 20.6577 7.18318 20.3416C6.44917 19.3129 6.18896 18.3403 6.18896 16.625C6.18896 13.0042 9.36507 9.82812 12.9858 9.82812C14.8086 9.82812 16.6088 10.6321 17.9247 12.0339C19.7876 14.0182 20.4571 16.9853 19.482 18.9356C18.4669 20.9655 15.189 20.3901 15.189 17.75C15.189 12.5354 9.84732 14.095 10.8037 17.9205C10.8978 18.2973 11.2797 18.5262 11.6563 18.4321C12.0331 18.338 12.2621 17.9562 12.1679 17.5795C11.9223 16.5969 12.3729 15.8281 12.9858 15.8281C13.5363 15.8281 13.7827 16.7934 13.7827 17.75C13.7827 21.8671 19.0568 22.9305 20.7397 19.5644Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconFingerPrint;

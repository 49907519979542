import React from 'react';

const IcnStep2 = (props) => {
    return (
        <svg {...props} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5826 11.0965C12.3642 10.8781 12.0618 10.7437 11.7258 10.7437H9.90576V13.1741H11.7258C12.7942 13.1741 13.3537 11.8872 12.5826 11.0965ZM9.90576 14.3501V17.2229H12.0562C13.3442 17.2229 13.9758 15.6565 13.0698 14.7701C12.5098 14.2101 12.0147 14.3501 9.90576 14.3501Z" fill="currentColor" />
            <path d="M21.8788 14.0029C21.8788 8.11165 17.1076 3.34045 11.222 3.34045C5.33637 3.34045 0.55957 8.11165 0.55957 14.0029C0.55957 19.8885 5.33637 24.6597 11.222 24.6597C17.1076 24.6597 21.8788 19.8885 21.8788 14.0029ZM15.2652 17.7829C14.7892 18.2645 14.1228 18.5613 13.3892 18.5613H11.9556V20.3365H10.7348V18.5613H9.85557V20.3365H8.63477V18.5613H6.39477V17.3461H7.75557V10.6597H6.39477V9.43885H8.63477V7.66365H9.85557V9.43885H10.7348V7.66365H11.9556V9.43885H13.3668C14.4196 9.43885 15.2708 10.2901 15.2708 11.3429C15.2708 11.8693 15.058 12.3453 14.7164 12.6925C14.3748 13.0285 13.9044 13.2413 13.3892 13.2469C14.8564 13.2469 16.0436 14.4397 16.0436 15.9069C16.0436 16.6405 15.7468 17.3069 15.2652 17.7829Z" fill="currentColor" />
            <path d="M16.252 3.35336C25.2327 7.60432 25.2338 20.3986 16.252 24.6502C22.7592 24.8602 27.4408 19.786 27.4408 13.999C27.4408 8.22536 22.7748 3.1428 16.252 3.35336Z" fill="currentColor" />
        </svg>

    );
}

export default IcnStep2;

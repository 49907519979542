
import * as Yup from "yup";
import { t } from 'i18next'
export const registerSchema = Yup.object().shape({
  email: Yup.string()
    .email(t('Invalid_email_address'))
    .required(t("Email_is_required")),
  password: Yup.string().required(t('Password_is_required')).matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
    t('Password_format_validation')
  ),
  confirmPassword: Yup.string()
    .required(t('Confirm_Password_is_required'))
    .oneOf([Yup.ref('password'), null], t('Passwords_must_match')),
  country: Yup.string().required(t('Country_is_required'))

});

export const updateProfileSchema = Yup.object().shape({
  name: Yup.string()
    .required(t("Name_is_required")),
  user_name: Yup.string()
    .required(t("UserName_is_required")),
  email: Yup.string()
    .email(t('Invalid_email_address'))
    .required(t("Email_is_required")),
  mobile_number: Yup.string().required(t('Mobile_is_required')),


});
export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email(t('Invalid_email_address'))
    .required(t("Email_is_required")),
  // password: Yup.string().required("Password is required"),
});
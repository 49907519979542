import React from 'react';
import { Button } from '../ui/Button';
import { useNavigate } from 'react-router-dom';
import IcnKycPending from '../../assets/svg/IcnKycPending';
import { authStore } from '../../context/AuthProvider';
// "kycRejectedDescription": "Your KYC verification has been rejected. Please complete your KYC verification to unlock this features.",
// "kycPendingDesc": "Your kyc verification is in review. Please wait for sometime to use this features.",
//   "kycRemainingDescription": "Please complete your KYC verification to unlock this features.",
// "kycFailedDesc": "Your KYC verification has been failed. Click on continue button to check more info."



const KycPending = () => {
    const navigate = useNavigate();
    const { user } = authStore();
    const navigateTo = () => {
        navigate('/dashboard/kyc')
    }

    const kycMessage = user?.kycStatus == 5 ? "Your KYC verification has been rejected. Please complete your KYC verification to unlock this features." : user?.kycStatus == 2 || user?.kycStatus == 1 ? "Your kyc verification is in review. Please wait for sometime to use this features." : user?.kycStatus == 3 ? "Your KYC verification has been failed. Click on continue button to check more info." : user?.kycStatus == 0 ? "Please complete your KYC verification to unlock this features." : "";
    const kycTitle = user?.kycStatus == 5 ? "KYC Rejected" : user?.kycStatus == 2 || user?.kycStatus == 1 ? "kyc Pending" : user?.kycStatus == 3 ? "KYC Failed" : user?.kycStatus == 0 ? "KYC Remaining" : "";

    return (
        <div className="w-full bg-dark">
            <div className='max-w-[300px] mx-auto h-[calc(100vh-80px)] text-center flex items-center justify-center flex-col gap-[8px]'>
                <IcnKycPending className='h-[100px] w-[100px] text-[#F61D1D]' />
                <h6 className='text-white font-600'>{kycTitle}</h6>
                <p className='text-white font-500 text-[14px]'>{kycMessage}</p>
                {/* <Button className="w-full py-[8px]" onClick={navigateTo}>KYC Now</Button> */}
                {user.kycStatus == 3 || user?.kycStatus == 5 ? (
                    <Button className="w-full py-[8px]" onClick={navigateTo}>Continue</Button>
                ) : (
                    ""
                )}
                {/* <Button className="w-full py-[8px]" onClick={navigateTo}>Continue</Button> */}
            </div>
        </div>
    );
}

export default KycPending;

import React from 'react'

const IconLogout = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none">
      <path d="M15.24 22.27H15.11C10.67 22.27 8.53002 20.52 8.16002 16.6C8.12002 16.19 8.42002 15.82 8.84002 15.78C9.25002 15.74 9.62002 16.05 9.66002 16.46C9.95002 19.6 11.43 20.77 15.12 20.77H15.25C19.32 20.77 20.76 19.33 20.76 15.26V8.73998C20.76 4.66998 19.32 3.22998 15.25 3.22998H15.12C11.41 3.22998 9.93002 4.41998 9.66002 7.61998C9.61002 8.02998 9.27002 8.33998 8.84002 8.29998C8.42002 8.26998 8.12001 7.89998 8.15001 7.48998C8.49001 3.50998 10.64 1.72998 15.11 1.72998H15.24C20.15 1.72998 22.25 3.82998 22.25 8.73998V15.26C22.25 20.17 20.15 22.27 15.24 22.27Z" fill="currentcolor" />
      <path d="M14.88 12.75H2C1.59 12.75 1.25 12.41 1.25 12C1.25 11.59 1.59 11.25 2 11.25H14.88C15.29 11.25 15.63 11.59 15.63 12C15.63 12.41 15.3 12.75 14.88 12.75Z" fill="currentcolor" />
      <path d="M12.65 16.1C12.46 16.1 12.27 16.03 12.12 15.88C11.83 15.59 11.83 15.11 12.12 14.82L14.94 12L12.12 9.18003C11.83 8.89003 11.83 8.41003 12.12 8.12003C12.41 7.83003 12.89 7.83003 13.18 8.12003L16.53 11.47C16.82 11.76 16.82 12.24 16.53 12.53L13.18 15.88C13.03 16.03 12.84 16.1 12.65 16.1Z" fill="currentcolor" />
    </svg>
  )
}

export default IconLogout



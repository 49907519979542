import React from 'react'

const IconTime = (props) => {
  return (
    <svg {...props} viewBox="0 0 512 512" fill="none">
      <path d="M256.001 21.333C209.588 21.333 164.218 35.096 125.627 60.8815C87.0362 86.667 56.9584 123.317 39.197 166.197C21.4357 209.076 16.7885 256.26 25.8431 301.781C34.8978 347.302 57.2476 389.115 90.0664 421.934C122.885 454.753 164.699 477.103 210.22 486.157C255.74 495.212 302.924 490.565 345.804 472.803C388.684 455.042 425.333 424.964 451.119 386.374C476.904 347.783 490.667 302.412 490.667 256C490.594 193.785 465.847 134.139 421.854 90.1464C377.861 46.1537 318.216 21.4064 256.001 21.333ZM335.083 335.082C331.083 339.082 325.658 341.328 320.001 341.328C314.344 341.328 308.919 339.082 304.918 335.082L240.918 271.082C236.917 267.082 234.669 261.657 234.667 256V128C234.667 122.342 236.915 116.916 240.916 112.915C244.917 108.914 250.343 106.666 256.001 106.666C261.659 106.666 267.085 108.914 271.086 112.915C275.086 116.916 277.334 122.342 277.334 128V247.168L335.083 304.917C339.083 308.918 341.33 314.343 341.33 320C341.33 325.657 339.083 331.082 335.083 335.082Z" fill="currentcolor" />
    </svg>
  )
}

export default IconTime
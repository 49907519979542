import React, { useState } from 'react'
import { Button } from '../../components/ui/Button'
import SuccessMessageModal from '../../components/model/SuccessMessageModal'
import AuthInputPassword from '../../components/ui/AuthInputPassword'

const ForgotPassword = () => {
  const [open, setOpen] = useState(false)
  const handleSubmit = (e) => {
    e.preventDefault()
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <section className='login-screen bg-light-200 sm:p-20 p-10 rounded-[30px] max-w-[600px] mx-auto'>
      <h4 className='mb-8 font-600'>
        New password
      </h4>
      <p className='text-16 text-light-600 my-8 font-400'>Your new password must be different from previous used password.</p>
      <form action="" onSubmit={handleSubmit}>
        <div className="form-group mb-4">
          <AuthInputPassword lable="Password" placeholder="Enter Password" />
        </div>
        <div className="form-group mb-4">
          <AuthInputPassword lable="Confirm Password" placeholder="Enter Confirm Password" />
        </div>
        <Button className="w-full mt-20" size={"sm"} onClick={handleOpen}>
          Continue
        </Button>
      </form>
      <SuccessMessageModal
        open={open}
        handleClose={handleClose}
        message={"Your Password Successfully Change"}
      />
    </section>
  )
}

export default ForgotPassword
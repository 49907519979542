import { Dialog, Transition, Listbox } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import IconClose from '../../assets/svg/IconClose'
import { _randomString, } from '../../Helper'
import IconRight from '../../assets/svg/IconRight'
import moment from 'moment'
import { Button } from '../ui/Button'
import { useMutation } from 'react-query'
import { axiosInstance } from '../../api/axiosInstance'
import { RECEIVE_API_URL, USER_API_URL } from '../../api/api'
import { enqueueSnackbar } from 'notistack'
import Loader from '../ui/Loader'

var cardTypeJson = {
  1: "Consume",
  2: "Recharge",
  3: "Withdrawal",
  4: "Transfer (In)",
  5: "Transfer (Out)",
  6: "Other",
  7: "Settlement adjustment",
  8: "Refund",
  9: "Payment reversal",
  10: "Fee",
  11: "Fee reversal",
  12: "OTC refund",
  13: "OTC refund reversal",
  14: "Consumption failure",
  15: "Binding card verification transaction",
  16: "Transaction service fee",
  17: "Rescission",
  100: "Credit card bill reconciliation",
  101: "Purchase Crypto Coin",
  102: "Cancel Card",
};

var cardStatus = {
  0: 'pending',
  1: 'success',
  2: 'fail'

}

const SetPhoneInboxModal = ({ open, handleClose, transactionList,detail }) => {

  const [dataList, setDataList] = useState([])
  const [loading, setLoading] = useState([])
  const [nextPageUri,setNextPageUri] = useState(false)

  useEffect(() => {
    if(open == true){
      getPhoneInboxList();
    }
  }, [open])

  const getPhoneInboxList = async () => {
    try{
      setLoading(true)
      const response = await axiosInstance.get(USER_API_URL.getInboxList + '?from=' + detail?.phoneNo);
      if(response){
        setLoading(false)
        setDataList(response?.data?.messages)
        setNextPageUri(response?.data?.nextPageUri ? true : false );
        return response;
      }
    }catch(error){
      setLoading(false)
      console.error(error);
    }
  }

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark border border-light-100 shadow-xl transition-all overflow-hidden ">
                  <div className="flex justify-center p-8 bg-card border-b-light-100 border-b rounded-t-[30px]">
                    <p className='text-white ms-auto'>Inbox</p>
                    <IconClose onClick={handleClose} className="text-white w-[18px] ms-auto cursor-pointer"
                    />
                  </div>
                  <div className="">

                    <div className="max-h-[350px] overflow-auto theme-scrollbar">
                      <ul className='sm:px-[24px] px-[12px]'>
                        {loading ?
                          <div className="min-h-[350px] flex items-center justify-center bg-card rounded-[20px] border border-light-400">
                            <Loader innarClass='mt-0' />
                          </div> :
                          dataList.length > 0 ? dataList?.map((data, index) => {

                            return (
                              <>
                                <li className='p-[12px_10px_12px] mb-[14px] cursor-pointer first:mt-[12px] bg-card rounded-[10px]'>
                                  <div className="flex flex-col">
                                    <p className='text-white text-[14px] pb-[4px]'>{data?.from}</p>
                                  </div>
                                  <div className="flex flex-col">
                                    <h5 className='text-gray text-[12px] uppercase text-start'>{data?.body}</h5>
                                    <p className='text-end text-gray text-[12px] pt-[4px]'>{moment(data?.dateCreated).format('DD-MM-YYYY hh:mm A')}</p>
                                  </div>
                                </li>
                              </>
                            )
                          }) : <p className='text-gray text-center py-[10px]'>No Record Found</p>}
                      </ul>
                    </div>
                    {nextPageUri == true ? <div className="flex items-center justify-center mb-[12px]">
                      <Button className="py-2 px-3 text-[12px]" onClick={()=> getPhoneInboxList()}>Load More</Button>
                    </div> : ''}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

    </>
  )
}

export default SetPhoneInboxModal

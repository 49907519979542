import React from "react";

const WalletMadeEasy = () => {
  return (
    <section className="lg:py-[100px] py-[40px]">
      <div className="container">
        <div className="text-center" data-aos="fade-down" data-aos-duration="1500">
          <h5 className="sm:text-32 font-600 capitalize text-center mb-8">
            oppipay Wallet Made Easy
          </h5>
          <p className="text-16 capitalize lg:max-w-[80%] mx-auto lg:mb-20 mb-12">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
        </div>
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 lg:gap-6 gap-8">
          <div className="lg:py-20 py-12 bg-light-200 border border-light-gray rounded-[20px]" data-aos="fade-down" data-aos-duration="2500">
            <div className="grid place-items-center xl:w-[100px] w-[90px] aspect-square bg-linear-horizontal rounded-full mx-auto lg:mb-12 mb-8">
              <img
                src={
                  require("../../../assets/images/BusinessMoney.svg").default
                }
                className="xl:w-[46px] w-[36px]"
                alt=""
              />
            </div>
            <p className="text-center text-18 w-[90%] capitalize font-600 md:max-w-[220px] mx-auto">
              Money Send and receive
            </p>
          </div>
          <div className="lg:py-20 py-12 bg-light-200 border border-light-gray rounded-[20px]" data-aos="fade-up" data-aos-duration="2500">
            <div className="grid place-items-center xl:w-[100px] w-[90px] aspect-square bg-linear-horizontal rounded-full mx-auto lg:mb-12 mb-8">
              <img
                src={require("../../../assets/images/BusinessNft.svg").default}
                className="xl:w-[46px] w-[36px]"
                alt=""
              />
            </div>
            <p className="text-center text-18 w-[90%] capitalize font-600 md:max-w-[220px] mx-auto">
              NFT Send And Receive
            </p>
          </div>
          <div className="lg:py-20 py-12 bg-light-200 border border-light-gray rounded-[20px]" data-aos="fade-down" data-aos-duration="2500">
            <div className="grid place-items-center xl:w-[100px] w-[90px] aspect-square bg-linear-horizontal rounded-full mx-auto lg:mb-12 mb-8">
              <img
                src={require("../../../assets/images/StoreCard.svg").default}
                className="xl:w-[46px] w-[36px]"
                alt=""
              />
            </div>
            <p className="text-center text-18 w-[90%] capitalize font-600 md:max-w-[220px] mx-auto">
              Get Virtual Cards
            </p>
          </div>
          <div className="lg:py-20 py-12 bg-light-200 border border-light-gray rounded-[20px]" data-aos="fade-up" data-aos-duration="2500">
            <div className="grid place-items-center xl:w-[100px] w-[90px] aspect-square bg-linear-horizontal rounded-full mx-auto lg:mb-12 mb-8">
              <img
                src={require("../../../assets/images/BusinessApi.svg").default}
                className="xl:w-[46px] w-[36px]"
                alt=""
              />
            </div>
            <p className="text-center text-18 w-[90%] capitalize font-600 md:max-w-[220px] mx-auto">
              Create App API
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WalletMadeEasy;

import React from 'react'
import ContactForm from '../../components/website/Contact/ContactForm';
import TalkToExperts from '../../components/website/Contact/TalkToExperts';
import { Helmet } from 'react-helmet-async';

const ContactSupport = () => {

    return (
        <section className='container support_section md:py-[100px] py-[40px]'>
            <Helmet prioritizeSeoTags>
                <title>Support | Oppi Wallet
                </title>
                <meta name="description" content="You can find comprehensive support solutions tailored to your needs. Please get the help you need to thrive, backed by our dedicated team." />
                <link rel="canonical" href="https://oppiwallet.com/support" />

                <meta property="og:title" content="Support | Oppi Wallet" />
                <meta property="og:description" content="You can find comprehensive support solutions tailored to your needs. Please get the help you need to thrive, backed by our dedicated team." />
                <meta property="og:url" content="https://oppiwallet.com/support" />

            </Helmet>
            <div className="text-center md:mb-[50px] mb-[30px] md:max-w-[800px] w-full md:mx-auto ">
                <h2 className='md:text-[42px] sm:text-[36px] text-[28px] mb-8'>We Can Help</h2>
                <p className='text-14 font-500 capitalize text-light-600 mb-[22px]'>Kindly submit below form with your concerns or suggestions. Our support team will get back with you with in 24 hours. Reach out for personalised support now with your queries.</p>
            </div>
            <ContactForm />
            <TalkToExperts />
        </section>
    )
}

export default ContactSupport
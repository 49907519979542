import React, { useEffect, useState } from "react";
import { Button } from "../../../components/ui/Button";
import { useNavigate } from "react-router-dom";
import { authStore } from "../../../context/AuthProvider";
import { handleDecrypt } from "../../../Helper";

const SelectBackupPhrase = () => {
  const navigate = useNavigate();
  const [phrases, setPhrases] = useState([]);
  const { user } = authStore();
  
  useEffect(() => {
    if (user && user?.secretKey) {
      const decryptWords = handleDecrypt(user?.secretKey)
      if(decryptWords) {
        setPhrases(JSON.parse(decryptWords))
      }
    }
  }, [user])
  

  return (
    <div className="bg-card min-h-full">
      <div className="py-12 px-8 flex flex-col min-h-[100vh]">
        <div className="flex-grow h-full">
          <p className="text-gray font-400 mb-8">
            write down the 12-word recover seed phrase in the correct order. it
            is require to restore your wallet
          </p>
          <div className="flex sm:gap-x-10 gap-x-6 gap-y-8 flex-wrap">
            {phrases?.length> 0 && phrases?.map((phrase, i) => {
              return (
                <div className="phrase flex gap-2 border border-light-100 p-2 pr-8 rounded-full">
                  <span className="w-[28px] h-[28px] bg-white rounded-full flex justify-center items-center text-14 font-500">
                    {i + 1}
                  </span>
                  <span className="text-white text-20 font-400">{phrase}</span>
                </div>
              );
            })}
          </div>
          {/* <Button size="sm" className="w-[50%] mx-auto block mt-20 py-[9px]" onClick={handleCopy}>
            Copy
          </Button> */}
        </div>
        <div className="flex gap-8">
          <Button
            className="w-full bg-transparent border border-white !text-white text-14"
            size="sm"
            onClick={() => navigate("/dashboard/setting/backup-manually", { state: { words: phrases } })}
          >
            Back Up Manually
          </Button>
          <Button className="w-full text-14 font-600" size="sm" onClick={() => navigate("/dashboard/setting/backup-cloud")}>
            Back Up cloud
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectBackupPhrase;

import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import IconClose from '../../assets/svg/IconClose';
import NftQrCodeIcon from '../../assets/images/NftQrCodeIcon.png';
import BinanceIcon from '../../assets/images/BinanceIcon.png'
import IconCopy from "../../assets/svg/IconCopy";
import { Button } from '../ui/Button';
import QRCode from 'react-qr-code';
import { useSnackbar } from 'notistack';
import { toInteger } from 'lodash';
import { PAYMENT_LINK_API_URL } from '../../api/api';
import useAxiosPrivate from '../../api/useAxiosPrivate';
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { files } from '../../Helper';
import defaultimage from '../../assets/images/logo_new.png'
import { useTranslation } from 'react-i18next';

const PaymentReceiveModal = ({ open, handleClose, paymentLinkDetails }) => {
  const [linkDetails, setLinkDetails] = useState(paymentLinkDetails)
  const { enqueueSnackbar } = useSnackbar();
  const axiosInstance = useAxiosPrivate();
  const [currentUtcTime, setCurrentUtctime] = useState();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  useEffect(() => {
    setLinkDetails(paymentLinkDetails);
  }, [paymentLinkDetails])

  const handleCopy = (address) => {
    navigator?.clipboard?.writeText(address)
      .then(() => {
        enqueueSnackbar(t('Address_copied'), {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      })
      .catch((error) => {
        console.error('Unable to copy text to clipboard', error);
        // Handle the error or provide user feedback
      });
  }

  async function getCurrentTime() {
    const response = await axiosInstance.get(PAYMENT_LINK_API_URL.getCurrentUtcTime);
    return response.data;
  }

  const { data: currentTime } = useQuery(['currentTime'], () => getCurrentTime(), { keepPreviousData: true, })

  useEffect(() => {
    setCurrentUtctime(currentTime?.currentUTCTime)
  }, [currentTime])

  const [remainingTime, setRemainingTime] = useState(0); // Initialize remaining time in seconds
  const [formattedTime, setFormattedTime] = useState("00:00"); // Initialize formatted time

  useEffect(() => {
    if (linkDetails) {
      const originalDate = new Date(linkDetails.createdAt);
      const endDate = new Date(originalDate.getTime() + (linkDetails.expireTime * 60000)); // Add expireTime minutes in milliseconds
      const remainTime = endDate - new Date(currentUtcTime);
      const remainingSeconds = Math.floor(remainTime / 1000);
      setRemainingTime(remainingSeconds);
    }
  }, [linkDetails, currentUtcTime]);

  useEffect(() => {
    // Format remaining time for display
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    setFormattedTime(formattedTime);
    // getRemainTime(formattedTime);

    // Check if remaining time is greater than 0 before starting the interval
    if (remainingTime > 0) {
      let interval = setInterval(() => {
        setRemainingTime(prevTime => prevTime - 1);
      }, 1000);

      // Cleanup function
      return () => {
        clearInterval(interval); // Clear the interval
      };
    } else {

      setFormattedTime("00.00")
      queryClient.invalidateQueries('paymentList')
    }
  }, [remainingTime]);


  useEffect(() => {
    if (formattedTime == "00.00") {
      queryClient.invalidateQueries('paymentList')
      // handleClose()
    }

  }, [formattedTime])

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => { return; }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full md:max-w-[560px] max-w-[480px] transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all">
                <div className="flex justify-center p-8 bg-card border-b-light-100 border-b">
                  <p className='text-white ms-auto'>{paymentLinkDetails?.currency}</p>
                  <IconClose onClick={handleClose} className="text-white w-[18px] ms-auto cursor-pointer" />
                </div>
                <div className='max-h-[600px] overflow-auto hidden-scrollbar'>

                  <div className="token-detail flex gap-4 justify-center items-center mt-12 flex-col">
                    <div className="p-[8px] rounded-full bg-light-200">
                      <img className='w-[28px]' src={defaultimage} alt="" />
                    </div>
                    <div className="text">
                      <p className='text-white'>{paymentLinkDetails?.currencyName} {paymentLinkDetails?.chainName && ` (${paymentLinkDetails.chainName})`} </p>
                    </div>
                    <div className="text">
                      <h6 className='text-white'>{paymentLinkDetails?.amount} {paymentLinkDetails?.currency}</h6>
                    </div>
                  </div>
                  {
                    paymentLinkDetails?.status == 0 ?
                      <div className="p-12">
                        <div className='max-w-[250px] aspect-square flex justify-center items-center bg-card rounded-3xl mx-auto border border-light-100'>
                          <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={linkDetails?.adminAssetAddress || ""}
                            viewBox={`0 0 256 256`}
                          />
                        </div>
                        <div className="flex justify-between items-center sm:max-w-[80%] max-w-[100%] mx-auto bg-card p-2 rounded-full mt-10 border border-light-100">
                          <p className='text-white text-14 ps-3 break-all pe-[4px] mx-auto'  >{!linkDetails?.adminAssetAddress ? "Address Not Found" : linkDetails?.adminAssetAddress?.slice(0, 7) + '...' + linkDetails?.adminAssetAddress?.slice(-7)}</p>
                          <div className="p-4 bg-theme rounded-full cursor-pointer">
                            <IconCopy className="w-[20px] h-[20px]" onClick={() => handleCopy(linkDetails?.adminAssetAddress)} />
                          </div>
                        </div>
                        <div className="flex justify-between items-center mt-8">
                          <p className='text-white text-14'>Please Send Exactly</p>
                          <p className='text-theme2 text-14 font-400 bg-light-theme p-3 rounded-lg'>{linkDetails?.amount + ' ' + paymentLinkDetails?.currency || '-'}</p>
                        </div>
                        <div className="flex justify-between items-center mt-6">
                          <p className='text-white text-14'>Time left until order expires:</p>
                          <p className='text-white text-14 font-400 bg-card p-3 rounded-lg'>{formattedTime}</p>
                        </div>




                        {
                          linkDetails?.note !== "" && (
                            <div className="p-3 ps-6 bg-card rounded-xl mt-6">
                              <p className='text-white text-12 flex items-center gap-3'><div className="w-2 h-2 bg-white rounded-full"></div> Note</p>
                              <p className='text-gray text-12 mt-4'>{linkDetails?.note ? linkDetails?.note : '-'}</p>

                            </div>
                          )
                        }
                        <div className="p-3 ps-6 bg-card rounded-xl mt-6">
                          <p className='text-white text-12 flex items-center gap-3'><div className="w-2 h-2 bg-white rounded-full"></div> Instruction</p>
                          <p className='text-gray text-12 mt-4'>The page will automatically update the payment status as soon as the transaction broadcasted.</p>
                          <p className='text-gray text-12 mt-6'>You have limited time to pay for the order. Your order will be cancelled if you fail to pay in time.</p>
                        </div>
                        {/* <Button className="w-full text-14 mt-12" onClick={handleClose}>Done</Button> */}
                      </div> :
                      <>
                        <div className="p-12 pt-0">
                          <img src={require("../../assets/gif/success.gif")} className='w-[190px] mx-auto' alt="" />
                          <p className='text-theme text-center '>Payment has been successfully done on this payment link</p>
                          {paymentLinkDetails?.note &&
                            <div className='bg-card p-[8px] rounded-[6px] mt-[30px]'>
                              <div className="flex items-center gap-[20px] ps-[12px] pb-[6px]">
                                <div className="bg-white h-[6px] w-[6px] rounded-full" />
                                <h6 className='text-white text-[16px]' >Note</h6>
                              </div>
                              <p className='text-white text-[14px] ps-[36px]'>{paymentLinkDetails?.note}</p>
                            </div>
                          }
                        </div>
                      </>
                  }

                </div>
                {paymentLinkDetails?.status == 0 &&
                  <div className="p-12 pt-8">
                    <Button className="w-full text-[16px]" onClick={handleClose}>Done</Button>
                  </div>
                }
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
export default PaymentReceiveModal

import React from "react";
import { Button } from "../../ui/Button";
import { useTranslation } from "react-i18next";

const DepositeAndSecurity = () => {
  const { t } = useTranslation();

  return (
    <section className="container sm:pt-80 pt-40">
      <div className="text-center md:max-w-[60%] max-w-[80%] mx-auto" data-aos="fade-down" data-aos-duration="1500">
        <h2 className="text-40 font-600 text-card mb-6">{t('Simple_Seamless')}</h2>
        <p className="md:text-16 text-14 font-400 max-w-[530px] mx-auto">
          {t('Download_the_oppi_crypto_wallet_app_to_securely_Send_Recive_swap_and_spend_multiple_cryptocurrencies_Take_full_control_of_your_finances_with_oppiwallet')}
        </p>
      </div>
      <div className="grid md:grid-cols-2 items-center md:pt-0 pt-10">
        <div className="text-center">
          <div className="md:text-start text-center lg:max-w-[60%] w-full lg:ms-auto" data-aos="fade-right" data-aos-duration="1500">
            <h3>{t('Deposit_crypto')}</h3>
            <h3>
              {t('easily_from')} <span className="text-greenweb">{t('Swap')}</span>
            </h3>
            <p className="text-14 font-400 sm:my-12 my-8">
              {t('Take_control_of_your_crypto_Avoid_complicated_steps_and_deposit_directly_to_your_wallet_from_exchanges_like_Binance_and_Coinbase')}
            </p>
            <Button variant="outline">{t('Get_Started_With_Deposite')}</Button>
          </div>
        </div>
        <div className="text-center">
          <img src={require("../../../assets/images/swap_pic.webp")} alt="" className="" data-aos="zoom-in" data-aos-duration="1500" />
        </div>
      </div>
    </section>
  );
};

export default DepositeAndSecurity;

import React, { useEffect, useState } from 'react';
import BinanceIcon from '../../assets/images/BinanceIcon.png';
import { RadioGroup } from '@headlessui/react';
import IconCoinReceive from "../../assets/svg/IconCoinReceive";
import NftIcon from "../../assets/images/NftListIcon.png"
import TransactionDetailModal from '../../components/model/TransactionDetailModal';
import { Button } from '../../components/ui/Button';
import { useQuery } from 'react-query';
import useAxiosPrivate from '../../api/useAxiosPrivate';
import { TRANSACTION_API_URL, USER_API_URL } from '../../api/api';
import { authStore } from '../../context/AuthProvider';
import IconCoinSend from '../../assets/svg/IconCoinSend';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { DateFomat, dateFormatter, files, formatDate, toFixedWithoutZeros } from '../../Helper';
import Loader from '../../components/ui/Loader';
import defaultimage from '../../assets/images/logo.png'
import IcnPending from '../../assets/svg/IcnPending';
import IcnEmpty from '../../assets/svg/IcnEmpty';


const Transaction = () => {
  const { t } = useTranslation();
  const [plan, setPlan] = useState('coins');
  const axiosInstance = useAxiosPrivate();
  const [transactionsList, setTransactionsList] = useState([]);
  const [CurrencyCode, setCurrencyCode] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [detailData, setDetailData] = useState();
  const [coin, setCoin] = useState([]);
  const [nft, setNft] = useState([]);
  const [openTransactionDetailModal, setOpenTransactionDetailModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const { user } = authStore();
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [limit, setLimit] = useState(10); // Initial limit of items to load

  const handleOpenTransactionDetailModal = (data) => {
    setDetailData(data);
    setOpenTransactionDetailModal(true);
  };

  const hanleCloseTransactionDetailModal = () => {
    setOpenTransactionDetailModal(false);
  };

  const getCurrencyCode = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(TRANSACTION_API_URL.getCurrencyCode);
      const data = response.data;
      setCurrencyCode(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCurrencyCode();
  }, []);

  const fetchTransactions = async () => {
    try {
      const response = await axiosInstance.get(`${TRANSACTION_API_URL.broadcastTransactionsList}?limit=${10}&page=${currentPage + 1}`);
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const { isLoading: isLoadingTran, data: transactionsData, refetch } = useQuery(
    ['appliedApiTransactions', limit, currentPage, plan],
    fetchTransactions,
    { keepPreviousData: true }
  );

  useEffect(() => {
    if (transactionsData) {
      // --------------------------------- get tab change duplicate recordthais why i comment this line ------------------------
      setCoin(prevCoin => [...prevCoin, ...transactionsData?.tranData]);
      // setCoin(transactionsData?.tranData);
      setTotalData(transactionsData?.metaData?.total);
      setNft((prevNft) => [...prevNft, ...transactionsData?.tranData.filter(item => item.assetType !== 'BASE_ASSET')]);
      setLoadingMoreData(false);
      setIsLoading(false);
    }
  }, [transactionsData, plan]);

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    setLoadingMoreData(true);
  };

  const items = plan === 'coins' ? coin : nft;

  const updateTransactionStatus = (txId, newStatus) => {
    setCoin((prevCoin) =>
      prevCoin.map((item) =>
        item.txId === txId ? { ...item, status: newStatus } : item
      )
    );
    setNft((prevNft) =>
      prevNft.map((item) =>
        item.txId === txId ? { ...item, status: newStatus } : item
      )
    );
  };

  return (
    <>
      <div className="p-12 bg-dark h-full">
        <div className='sm:w-[80%] bg-card h-full mx-auto rounded-[20px] border border-light-100 flex flex-col p-12'>
          <div className="h-[calc(100%-30px)]">
            <RadioGroup
              className="flex text-20 gap-2 p-3 bg-dark rounded-full w-[80%] mx-auto mb-12"
              value={plan}
              onChange={(value) => {
         
                setPlan(value);
                setCurrentPage(0);
                setLimit(10); // Reset limit when switching between coins and NFT
                setCoin([]); // Clear previous data when switching
                setNft([]);
                refetch(); // Fetch new data based on plan
              }}
            >
              <RadioGroup.Option className="cursor-pointer w-[50%]" value="coins">
                {({ checked }) => (
                  <Button size="sm" className={`!text-14 py-4 w-full ${checked ? 'bg-theme' : 'bg-transparent text-gray'}`}>{t('Coins')}</Button>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option className="cursor-pointer w-[50%]" value="nft">
                {({ checked }) => (
                  <Button size="sm" className={`!text-14 py-4 w-full ${checked ? 'bg-theme' : 'bg-transparent text-gray'}`}>{t('NFTs')}</Button>
                )}
              </RadioGroup.Option>
            </RadioGroup>

            {isLoadingTran && items?.length === 0 && (
              <Loader />
            )}
            {!isLoadingTran && plan === 'coins' &&

              <>
                <div className="h-[calc(100vh-344px)] overflow-auto hidden-scrollbar">
                  {
                    !items.length ?
                      <div className='flex flex-col items-center justify-center h-full w-full'>
                        <IcnEmpty className='h-[114px] w-[114px]' />
                        <p className='text-[14px] font-600 text-white'>There is nothing in the list.</p>
                      </div> :
                      items.map((data, index) => {



                        return (
                          <div
                            key={index}
                            className="p-4 w-full bg-dark flex justify-between items-center rounded-3xl cursor-pointer mb-[12px] last:mb-0"
                            onClick={() => handleOpenTransactionDetailModal(data)}
                          >
                            <div className="token-detail flex items-center w-full">
                              <div className="rounded-full mr-4 w-[44px] aspect-square flex justify-center items-center">
                                <img className='rounded-full object-cover' src={files(data?.icon, 'image')} alt="" />
                              </div>
                              <div className="flex justify-between items-center w-full">
                                <div className="text">
                                  <h6 className='xxl:text-18 text-16 text-white mb-2'>{data?.currencyName}</h6>
                                  <div className="flex gap-3">
                                    <p className='text-14 text-gray'>{formatDate(data?.createdAt)}</p>
                                    {(data?.status == 'Pending') ? (
                                      <IcnPending className="h-[16px] text-[#edab44]" />
                                    ) : data?.fromUserId == user?._id ? (
                                      <IconCoinSend className="text-red w-[16px]" />
                                    ) : data?.toUserId == user?._id ? (
                                      <IconCoinReceive className="text-green w-[16px]" />
                                    ) : null}

                                    {/* <p className='text-14 text-gray'>{formatDate(data?.createdAt)}</p>
                            {(data?.fromUserId == user?._id && data.status == 'Completed') ?
                              (<IconCoinSend className="text-red w-[16px]" />) : 
                              (data?.toUserId == user?._id && data.status == 'Completed') ? 
                              (<IconCoinReceive className="text-green w-[16px]" />) : ((data?.FromUserId == user?._id && data?.toUserId == null) && data?.status == 'Pending') ? <IcnPending className="h-[16px] text-[#edab44]" /> : ''
                            } */}
                                  </div>
                                </div>

                                <div className="text">
                                  {data?.status === 'Pending' ? (
                                    <h6 className='xxl:text-18 text-16 text-red mb-2 text-end'> - {data?.amount} </h6>
                                  ) : data?.fromUserId == data?.toUserId ? <h6 className='xxl:text-18 text-16 text-green mb-2 text-end'>+{data?.amount}</h6> : data?.fromUserId == user?._id ? (
                                    <h6 className='xxl:text-18 text-16 text-red mb-2 text-end'>- {data?.amount}</h6>
                                  ) : data?.toUserId == user?._id ? (
                                    <h6 className='xxl:text-18 text-16 text-green mb-2 text-end'>+{data?.amount}</h6>
                                  ) : null}
                                  <p className='text-14 text-gray text-end'>$ {data?.usdPrice ? parseFloat(data?.usdPrice).toFixed(2) ? parseFloat(data?.usdPrice).toFixed(2) : parseFloat(0).toFixed(2) : '0.0'} USD</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                  }
                </div>
                {items?.length > 0 && (
                  <div className="text-center mt-4">
                    {items.length !== totalData &&
                      <Button className="text-[12px]" onClick={handleLoadMore} disabled={loadingMoreData}>
                         {loadingMoreData ? t('loading') : t('Load_more')}
                      </Button>
                    }
                  </div>
                )}
              </>
            }
            {
              plan == 'nft' &&
              <div className="h-[calc(100vh-341px)] overflow-auto hidden-scrollbar">
                <div className='flex flex-col items-center justify-center h-full w-full'>
                  <IcnEmpty className='h-[114px] w-[114px]' />
                  <p className='text-[14px] font-600 text-white'>There is nothing in the list.</p>
                </div>
              </div>
            }


          </div>

          <TransactionDetailModal
            open={openTransactionDetailModal}
            handleClose={hanleCloseTransactionDetailModal}
            detailData={detailData}
            setDetailData={setDetailData}
            plan={plan}
            updateTransactionStatus={updateTransactionStatus}
          />
        </div>
      </div>
    </>
  );
};

export default Transaction
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import IconClose from "../../assets/svg/IconClose";
import { Button } from "../ui/Button";
import Input from "../ui/Input";
import "react-phone-input-2/lib/style.css";
import SuccessMessageModal from "./SuccessMessageModal";
import { authStore } from "../../context/AuthProvider";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { useSnackbar } from "notistack";
import { AUTH_API_URL, USER_API_URL } from "../../api/api";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { errors } from "jose";
import IconCheck from "../../assets/svg/IconCheck";
import { t } from "i18next";

const EnableGoogleAuthModal = ({ open, handleClose, setStep, isForDisable }) => {
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const setIsVerified2FA = authStore((state) => state?.setIsVerified2FA)
  const [value, setValue] = useState()
  const { user, set } = authStore();
  const axiosInstance = useAxiosPrivate();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [twoFadisabled, setTwoFadisabledDisabled] = useState(true);
  const [startOtpTimer, setStartOtpTimer] = useState(false);
  const [emailDisable, setEmailDisable] = useState(true);
  const otpResendTime = 60;
  const [otpTime, setOtpTime] = useState(otpResendTime);
  const [disbleSubmit, setDisbleSubmit] = useState(true);
  const [isEmailVerify, setIsEmailVerify] = useState(false);


  useEffect(() => {
    if (user?.is2faEnable == 1 && isForDisable == 1) {
      setTwoFadisabledDisabled(false)
    } else {
      setTwoFadisabledDisabled(true)
    }

  }, [user])

  // const handleOpenSuccessModal = () => {
  //   setOpenSuccessModal(true)
  // }
  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false)
    handleClose()
    navigate("/dashboard/setting");
  }
  // handle send code
  const handleSendCodeClick = async () => {
    const data = {
      email: user?.email
    }
    setStartOtpTimer(true);
    setEmailDisable(false)
    await sendCode(data)
  }

  const { mutateAsync: sendCode } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(AUTH_API_URL.send2FaMail, data).then((res) => res.data)
        if (response) {
          enqueueSnackbar(t('Please_check_mail_to_verify'), {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });

        }
      } catch (error) {
        if (error?.response?.data.errors) {
          const errors = error?.response?.data.message;
          enqueueSnackbar(errors, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
      }
    },
  );



  // verify email 
  const handleChange = async (e) => {
    setValue(e.target.value);
    const inputValue = e.target.value;
    if (inputValue.length === 6) {
      setEmailDisable(true)
    } else {
      setEmailDisable(false)
    }

    if (inputValue.length === 6) {
      const data = {
        email: user?.email,
        otp: inputValue,
        isEmail: 1
      }

      await verify2faOrEmail(data)


    }
  }

  // verify 2FA 
  const handleChangeVerificationCode = async (e) => {
    const inputValue = e.target.value;
    if (inputValue.length === 6) {
      setTwoFadisabledDisabled(true)
    } else {
      setTwoFadisabledDisabled(false)
    }
    if (inputValue.length === 6) {
      const data = {
        otp: inputValue,
        email: user?.email,
      }

      await verify2faOrEmail(data)
    }
  }


  const { mutateAsync: verify2faOrEmail } = useMutation(
    async (data) => {
      try {
        const res = await axiosInstance.post(AUTH_API_URL.verify2faOrEmail, data)
        if (res) {
          if (res?.data?.isAuthMailVerify) {
            setStartOtpTimer(false)
            setIsEmailVerify(true)
            setEmailDisable(true);
            setTwoFadisabledDisabled(false);
            enqueueSnackbar(res?.data?.message, {
              variant: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          } else {
            const authData = {
              authenticationType: 1
            }
            await enableAuth(authData)
          }
        }
      } catch (error) {
        if (error) {
          setEmailDisable(false)
          setTwoFadisabledDisabled(false);
          const errorMessage = error?.response?.data?.message ?? 'An error occurred.';
          enqueueSnackbar(errorMessage, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
      }
    }
  );


  const { mutateAsync: enableAuth } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(AUTH_API_URL.enableVerifications, data).then((res) => res.data)
        if (response) {
          setOpenSuccessModal(true)
          set((state) => ({
            user: {
              ...state.user,
              is2faEnable: user?.is2faEnable === 1 ? 0 : 1,
            },
          }));
          setTwoFadisabledDisabled(true)
        }
      } catch (error) {
        setTwoFadisabledDisabled(false)
        if (error?.response?.data.message) {
          enqueueSnackbar(error?.response?.data.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
      }
    },
  );
  const startTimer = () => {
    setTimeout(() => {
      setOtpTime(otpTime - 1)
    }, 1000);
  }
  useEffect(() => {
    if (otpTime < 1) {
      setStartOtpTimer(false);
      setOtpTime(otpResendTime)
    }
    if (startOtpTimer)
      startTimer();
  }, [otpTime, startOtpTimer]);

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[99]" onClose={() => {
          // setEmailDisable(false);
          // setTwoFadisabledDisabled(false);
          // handleClose();
          // setIsEmailVerify(false)

          return;
        }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark border border-light-100 shadow-xl transition-all">
                  <div className="flex justify-center p-8 bg-card border-b-light-100 border-b rounded-t-[30px]">
                    <p className="text-white ms-auto">
                      {user?.is2faEnable === 1 ? "Disable Google Authenticator" : t('Enable_Google_Authenticator')}
                    </p>
                    <IconClose
                      onClick={() => {
                        setEmailDisable(false);
                        setTwoFadisabledDisabled(false);
                        handleClose();
                        setIsEmailVerify(false)
              
                        return;
                      }}
                      className="text-white w-[18px] ms-auto cursor-pointer"
                    />
                  </div>
                  <div className="p-12">
                    {
                      (isForDisable == 0) && (
                        <>
                          <p className="text-theme text-18 mb-8">
                            {t('Step_4_Enabled_Google_Authenticator')}
                          </p>
                          <Input
                            type="number"
                            lable={`${t('Enter_the_6_digit_code_sent_to')} ${user?.email}`}
                            placeholder={t('Enter_the_6_digit_code')}
                            disabled={emailDisable}
                            onChange={(e) => handleChange(e)}

                            suffix={
                              startOtpTimer === true ? `00:${otpTime >= 10 ? otpTime : `0${otpTime}`}` :
                                isEmailVerify == true ? <IconCheck className="h-[18px] w-[18px]" /> :
                                  <span
                                    className="bg-transparent cursor-pointer text-theme text-14"
                                    onClick={handleSendCodeClick}
                                  >
                                    {t('Send_Code')}
                                  </span>
                            }
                            suffixClass="bg-transparent !text-theme text-14"
                            style={{ marginBottom: '10px' }}
                          />

                        </>
                      )
                    }
                    <Input
                      type="number"
                      lable={t('Google_verification_code')}
                      placeholder={t('Enter_the_6_digit_code')}
                      onChange={(e) => handleChangeVerificationCode(e)}
                      disabled={twoFadisabled}
                    />
                    {/* <div className="flex gap-8 mt-16">
                      <Button
                        className="w-full !text-14 bg-transparent border border-white text-white"
                        onClick={() => {
                          handleClose();
                          setEmailDisable(false)
                        }}
                      >
                        previous
                      </Button>
                      <Button className="w-full text-14" onClick={handleOpenSuccessModal} disabled={disbleSubmit}>
                        Submit
                      </Button>
                    </div> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <SuccessMessageModal
        open={openSuccessModal}
        handleClose={handleCloseSuccessModal}
        message={"Google Authenticator has been set up successfully."}
        message2={"You have successfully enabled Google Authenticator for your account."}
        messageClass="w-[70%] mb-8"
      />
    </>
  );
};

export default EnableGoogleAuthModal;

import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { files } from '../../Helper'
import IconClose from '../../assets/svg/IconClose'

const ImagePreviewModal = ({ imagePreview, setImagePreview, image }) => {

  return (
    <Transition appear show={imagePreview} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={setImagePreview}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform rounded-[30px] bg-white overflow-hidden border border-light-100 shadow-xl transition-all">
                < div className='dialog-body p-8' >
                  <div className='relative'>
                    <img src={files(image, 'image')} className='w-full h-full' alt="" />
                    <div className='h-[36px] w-[36px] rounded-full cursor-pointer bg-white flex items-center justify-center absolute top-[10px] end-[10px]' onClick={() => setImagePreview(false)}>
                      <IconClose className="text-card w-[18px]" />
                    </div>
                  </div>
                </div >
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )


}

export default ImagePreviewModal

import React, { useEffect, useState, useRef } from "react";
import { Listbox, Transition } from "@headlessui/react";
import IconDown from "../../assets/svg/IconDown";
import { cn } from "../../lib/utils";


const MultiSelectInput = ({ data, errorMsg, getMultiSelectedToken, editData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const listBoxRef = useRef(null);
    const [selectedPersons, setSelectedPersons] = useState([]);

    useEffect(() => {
        if (editData && editData?.currencies) {
            const modifiedCurrencies = editData?.currencies?.map(currency => {
                const modifiedCurrency = {
                    ...currency,
                    id: currency?._id
                };
                delete modifiedCurrency?._id;
                return modifiedCurrency;
            });

            setSelectedPersons(modifiedCurrencies);
        }
    }, [editData]);

    useEffect(() => {
        getMultiSelectedToken(selectedPersons);
    }, [selectedPersons])

    function isSelected(value) {
        return selectedPersons?.find((el) => el?.id === value?.id) ? true : false;
    }

    function handleSelect(value) {
        if (!isSelected(value)) {
            const selectedPersonsUpdated = [
                ...selectedPersons,
                data?.find((el) => el?.id === value?.id)
            ];
            setSelectedPersons(selectedPersonsUpdated);
        } else {
            handleDeselect(value);
        }
        setIsOpen(true);
    }

    function handleDeselect(value) {
        const selectedPersonsUpdated = selectedPersons?.filter((el) => el?.id !== value?.id);
        setSelectedPersons(selectedPersonsUpdated);
        setIsOpen(true);
    }


    const selectedTokenArray = []
    selectedPersons?.map((selectedItem) => {
        const selectedToken = selectedItem?.name + ' ' + (selectedItem?.code ? selectedItem?.code : selectedItem?.symbol)
        selectedTokenArray?.push(selectedToken)

    })

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (listBoxRef.current && !listBoxRef.current.contains(event.target)) {
            setIsOpen(false);
          }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      });



    return (
        <div className="w-full flex items-center justify-center">
            <div className="w-full mx-auto">
                <Listbox
                    as="div"
                    className="space-y-1"
                    value={selectedPersons}
                    onChange={(value) => handleSelect(value)}
                    open={isOpen}
                    ref={listBoxRef}
                >
                    {() => (
                        <>
                            <Listbox.Label className="block text-[14px] mb-[10px] leading-5 text-white">
                                Select Currency
                            </Listbox.Label>
                            <div className="relative">
                                <span className="inline-block w-full rounded-md shadow-sm">
                                    <Listbox.Button
                                        className="cursor-default relative w-full rounded-full p-[14px] pe-[30px] text-left focus:outline-none transition ease-in-out duration-150 text-[14px] bg-dark border-gray text-gray"
                                        onClick={() => setIsOpen(!isOpen)}
                                        open={isOpen}
                                    >
                                        <span className="block truncate">
                                            {selectedPersons?.length < 1
                                                ? "Select Options"
                                                : selectedTokenArray?.join(", ")}
                                        </span>
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                                            <IconDown className="w-6 h-6 text-dark-950" />
                                        </span>
                                    </Listbox.Button>
                                </span>

                                <Transition
                                    unmount={false}
                                    show={isOpen}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                    className="absolute mt-1 w-full rounded-md bg-[#262626] shadow-lg border border-[#b3b3b31a]"
                                >
                                    <Listbox.Options
                                        static
                                        className="max-h-60 rounded-md py-1 text-[14px] overflow-auto theme-scrollbar focus:outline-none text-white"
                                    >
                                        {data.map((item) => {
                                            let selected;
                                            const matchedId = selectedPersons?.length > 0 ?
                                                selectedPersons?.find((selectedItem) => selectedItem?.id === item?.id) : item
                                            selected = isSelected(matchedId)
                                            return (
                                                <Listbox.Option key={item?.id} value={item}>
                                                    {({ active }) => (
                                                        <div
                                                            className={`${active
                                                                ? "text-white bg-theme/10"
                                                                : "text-gray-900"
                                                                } cursor-pointer select-none relative py-2 pl-8 pr-4`}
                                                        >
                                                            <span
                                                                className={`${selected ? "font-semibold" : "font-normal"
                                                                    } block truncate`}
                                                            >
                                                                <div
                                                                    className="text-14 font-400 text-dark-950 text-white"
                                                                >
                                                                    <p className="">
                                                                        {item?.name} <span className='text-[14px] text-white/60 pl-[2px]'>{item?.code ? item?.code : item?.symbol}</span>
                                                                    </p>
                                                                </div>

                                                            </span>
                                                            {selected && (
                                                                <span
                                                                    className={`${active ? "text-white" : "text-greenweb"
                                                                        } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                                                                >
                                                                    <svg
                                                                        className="h-5 w-5"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 20 20"
                                                                        fill="currentColor"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                            clipRule="evenodd"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            )}
                                                        </div>
                                                    )}
                                                </Listbox.Option>
                                            );
                                        })}
                                    </Listbox.Options>
                                </Transition>
                                {/* <div className="pt-1 text-sm">
                                    {selectedPersons.length > 0 && (
                                        <>Selected persons: {selectedPersons.join(", ")}</>
                                    )}
                                </div> */}
                            </div>
                        </>
                    )}
                </Listbox>
                {errorMsg && <p className={cn('text-12 text-red-500 font-normal')} style={{ color: "red", fontSize: '13px', margin: '8px' }}>{errorMsg}</p>}
            </div>
        </div>
    );
}

export default MultiSelectInput

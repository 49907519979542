import React, { useEffect, useState, useTransition } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import IconWallet from "../../assets/svg/IconWallet";
import IconReceive from "../../assets/svg/IconReceive";
import IconSend from "../../assets/svg/IconSend";
import IconTransaction from "../../assets/svg/IconTransaction";
import IconSwap from "../../assets/svg/IconSwap";
import IconApi from "../../assets/svg/IconApi";
import IconPayment from "../../assets/svg/IconAdd";
import IconBrowser from "../../assets/svg/IconBrowser";
import IconSetting from "../../assets/svg/IconSetting";
import IconLogout from "../../assets/svg/IconLogout";
import { Button } from "../ui/Button";
import LogoutModal from "../model/LogoutModal";
import UseStore, { authStore } from "../../context/AuthProvider";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { USER_API_URL } from "../../api/api";
import { useMutation } from "react-query";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { checkKyc, kycColourCode } from "../../Helper";
import IconLink from "../../assets/svg/IconLink";

const SideBar = ({ setTooglesidebar, tooglesidebar, createPhrase }) => {
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const navigate = useNavigate();
  const { setLogoutResponse, user } = authStore();
  const { pathname } = useLocation();
  const axiosInstance = useAxiosPrivate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();



  const handleOpenLogoutModal = () => {
    setOpenLogoutModal(true);
  };
  const handleCloseLogoutModal = () => {
    setOpenLogoutModal(false);
  };
  const { mutateAsync: logout } = useMutation(
    async () => {
      const response = await axiosInstance.patch(
        USER_API_URL.logout,
      );
      return response
    },
    {
      onSuccess: (response) => {
        setLogoutResponse(response)
      },
      onError: (error) => {
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });

      },
    }
  );
  const handleLogout = async () => {
    await logout()
  }

 

  useEffect(() => {
    setTooglesidebar(false);
  }, [pathname, setTooglesidebar]);




  return (
    <div
      className={`sidebar theme-scrollbar fixed top-[80px] transition-left duration-500 ease-in-out border-e-2 border-[#b3b3b31a] ${tooglesidebar ? "left-0" : "-left-[100%]"
        } flex items-start flex-col z-[48] lg:sticky xl:top-[80px] w-full lg:max-w-[20%] max-w-[320px] py-5 flex-shrink-0 h-[calc(100vh-80px)] overflow-y-auto bg-card`}
    >
      {!createPhrase && (
        <div className="w-full flex-grow flex flex-col">
          <ul className="pb-5">
            <li className="mb-4">
              <Link
                to={"/dashboard"}
                className={`flex items-center relative justify-start text-16 gap-4 font-500 before:absolute before:w-8 before:-left-[10px] before:h-full before:rounded-full py-[15px] px-[40px] rounded-md h-[50px] hover:text-white hover:before:bg-linear-horizontal ${pathname === "/dashboard" || pathname === '/dashboard/active-coins' ? "text-white before:bg-linear-horizontal" : "text-gray"
                  }`}
              >
                <IconWallet className="w-[24px] h-[24px]" />
                {t('Wallet')}
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to={"/dashboard/send"}
                className={`flex items-center relative justify-start text-16 gap-4 font-500 before:absolute before:w-8 before:-left-[10px] before:h-full before:rounded-full py-[15px] px-[40px] rounded-md h-[50px] hover:text-white hover:before:bg-linear-horizontal ${pathname === "/dashboard/send" || pathname === "/dashboard/select-address"
                  ? "text-white before:bg-linear-horizontal"
                  : "text-gray"
                  }`}
              >
                <IconSend className="w-[24px] h-[24px]" />
                {t('Send')}
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to={"/dashboard/receive"}
                className={`flex items-center relative justify-start text-16 gap-4 font-500 before:absolute before:w-8 before:-left-[10px] before:h-full before:rounded-full py-[15px] px-[40px] rounded-md h-[50px] hover:text-white hover:before:bg-linear-horizontal ${pathname === "/dashboard/receive"
                  ? "text-white before:bg-linear-horizontal"
                  : "text-gray"
                  }`}
              >
                <IconReceive className="w-[24px] h-[24px]" />
                {t('Receive')}
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to={"/dashboard/transactions"}
                className={`flex items-center relative justify-start text-16 gap-4 font-500 before:absolute before:w-8 before:-left-[10px] before:h-full before:rounded-full py-[15px] px-[40px] rounded-md h-[50px] hover:text-white hover:before:bg-linear-horizontal ${pathname === "/dashboard/transactions"
                  ? "text-white before:bg-linear-horizontal"
                  : "text-gray"
                  }`}
              >
                <IconTransaction className="w-[24px] h-[24px]" />
                {t('Transactions')}
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to={"/dashboard/swap"}
                className={`flex items-center relative justify-start text-16 gap-4 font-500 before:absolute before:w-8 before:-left-[10px] before:h-full before:rounded-full py-[15px] px-[40px] rounded-md h-[50px] hover:text-white hover:before:bg-linear-horizontal ${pathname === "/dashboard/swap"
                  ? "text-white before:bg-linear-horizontal"
                  : "text-gray"
                  }`}
              >
                <IconSwap className="w-[24px] h-[24px]" />
                {t('Swap')}
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to={"/dashboard/api"}
                className={`flex items-center relative justify-start text-16 gap-4 font-500 before:absolute before:w-8 before:-left-[10px] before:h-full before:rounded-full py-[15px] px-[40px] rounded-md h-[50px] hover:text-white hover:before:bg-linear-horizontal ${pathname === "/dashboard/api" || pathname === "/dashboard/create-api"
                  ? "text-white before:bg-linear-horizontal"
                  : "text-gray"
                  }`}
              >
                <IconApi className="w-[24px] h-[24px]" />
                {t('API')}
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to={"/dashboard/payment"}
                className={`flex items-center relative justify-start text-16 gap-4 font-500 before:absolute before:w-8 before:-left-[10px] before:h-full before:rounded-full py-[15px] px-[40px] rounded-md h-[50px] hover:text-white hover:before:bg-linear-horizontal ${pathname === "/dashboard/payment"
                  ? "text-white before:bg-linear-horizontal"
                  : "text-gray"
                  }`}
              >
                <IconLink className="w-[24px] h-[24px] " />
                {t('Payment_Link')}
              </Link>
            </li>

            <li className="mb-4">
              <Link
                to={"/dashboard/setting"}
                className={`flex items-center relative justify-start text-16 gap-4 font-500 before:absolute before:w-8 before:-left-[10px] before:h-full before:rounded-full py-[15px] px-[40px] rounded-md h-[50px] hover:text-white hover:before:bg-linear-horizontal ${pathname === "/dashboard/setting" || pathname === "/dashboard/setting/support" || pathname === "/dashboard/setting/support-detail" || pathname === "/dashboard/setting/language" || pathname === "/dashboard/setting/google-auth"
                  ? "text-white before:bg-linear-horizontal"
                  : "text-gray"
                  }`}
              >
                <IconSetting className="w-[24px] h-[24px]" />
                {t('Setting')}
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to={"/dashboard/switch-account"}
                className={`flex items-center relative justify-start text-16 gap-4 font-500 before:absolute before:w-8 before:-left-[10px] before:h-full before:rounded-full py-[15px] px-[40px] rounded-md h-[50px] hover:text-white hover:before:bg-linear-horizontal ${pathname === "/dashboard/switch-account"
                  ? "text-white before:bg-linear-horizontal"
                  : "text-gray"
                  }`}
              >
                <IconSetting className="w-[24px] h-[24px]" />
                {t('Change_Account')}
              </Link>
            </li>
          </ul>
          <ul className="pt-5 mt-auto">
            <li className="mb-4 px-[30px]">
              <div className="p-6 bg-white rounded-3xl border border-light-400">
                <p className="text-12 font-500 text-center text-black mb-6">
                  {user?.isKyc == 1 ? "Your kyc is completed" : t('Please_complete_your_KYC_verification_to_unlock_all_features_and_services')}
                </p>
                <Button className="h-[30px] p-0 w-full text-12 text-white" style={{ backgroundColor :kycColourCode(user) }} size={"sm"} onClick={() => {
                  if (checkKyc(user) === "KYC Final Rejected" || checkKyc(user) === "KYC Failed" || checkKyc(user) === "KYC Now"
                    || checkKyc(user) === "KYC Pending") {
                    navigate("/dashboard/kyc");
                  }
                }}>
                  {checkKyc(user) || "KYC Now"}
                </Button>
              </div>
            </li>
            <li className="mb-4 cursor-pointer">
              <div
                className={
                  "flex items-center relative justify-start text-16 gap-4 font-500 py-[15px] px-[40px] rounded-md h-[50px] text-white"
                }
                onClick={handleOpenLogoutModal}
              >
                <IconLogout className="w-[24px] h-[24px]" />
                {t('Logout')}
              </div>
            </li>
          </ul>
          <LogoutModal
            open={openLogoutModal}
            handleClose={handleCloseLogoutModal}
            handleLogout={handleLogout}
          />
        </div>
      )}
    </div>
  );
};

export default SideBar;

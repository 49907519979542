import React from "react";
import IconDone from "../../../assets/svg/IconDone";

const WhatIsWallet = () => {
  return (
    <section className="container md:pt-[100px] pt-[40px]">
      <div className="grid md:grid-cols-2 grid-cols-1 items-center md:gap-10 gap-20">
        <div className="">
          <img
            src={require("../../../assets/images/WhatIsWallet.webp")}
            className=""
            alt=""
            data-aos="zoom-out" data-aos-duration="1500"
          />
        </div>
        <div className="text-center">
          <div className="text-start lg:max-w-[520px] w-full lg:mx-auto">
            <h5 className="sm:text-32 md:text-left text-center capitalize font-700 text-card mb-6" data-aos="fade-down" data-aos-duration="1500">
              What is oppi wallet
            </h5>
            <p className="text-16 font-500 text-light-600 lg:mb-8 mb-6">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </p>
            <div className="" data-aos="fade-up" data-aos-duration="1500">
              <div className="flex lg:mb-8 mb-6">
                <div className="grid place-content-center bg-linear-horizontal w-[24px] p-3 h-max rounded-full me-6">
                  <IconDone className="w-[12px] text-white" />
                </div>
                <div className="text">
                  <p className="text-18 font-600 mb-4">
                    Send, Receive and Swap BTC, ETH, XRP, OKB, Etc...
                  </p>
                  <p className="text-14 font-500 capitalize text-light-600">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="grid place-content-center bg-linear-horizontal w-[24px] p-3 h-max rounded-full me-6">
                  <IconDone className="w-[12px] text-white" />
                </div>
                <div className="text">
                  <p className="text-18 font-600 mb-4">Send NFt</p>
                  <p className="text-14 font-500 capitalize text-light-600">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatIsWallet;

import React from "react";
import CustomBakckupImg from "../../../assets/images/CustomBackup.png";
import { Button } from "../../../components/ui/Button";
import { useNavigate } from "react-router-dom";

const BackupPhrase = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-card min-h-full p-12">
      <div className="xl:w-[60%] sm:w-[90%] mx-auto">
        <img src={CustomBakckupImg} className="mx-auto" alt="" />
        <h6 className="text-white font-600 text-center mt-12">
          Let’s Backup Your Account Now!
        </h6>
        <p className="text-gray text-14 font-400 text-center mt-8">
          in the next step you will see Secret Phrase (12 words) that allows you
          to recover a wallet
        </p>
        <div className="form-item flex items-center mt-12 mb-6 p-6 bg-[#000] rounded-full">
          <input type="checkbox" className="accent-theme" checked />
          <div className="text-14 ml-3 text-white font-400">
            If i lose my secret phrase, my funds will be lost forever
          </div>
        </div>
        <div className="form-item flex items-center mt-12 mb-6 p-6 bg-[#000] rounded-full">
          <input type="checkbox" className="accent-theme" checked />
          <div className="text-14 ml-3 text-white font-400">
            If i expose or share my secret phrase to anbody, my funds can get
            stolen
          </div>
        </div>
        <div className="form-item flex items-center mt-12 p-6 bg-[#000] rounded-full">
          <input type="checkbox" className="accent-theme" checked />
          <div className="text-14 ml-3 text-white font-400">
            Wallet support will never reach out to as for it
          </div>
        </div>
      </div>
      <Button
        className="w-full mt-20"
        size="sm"
        onClick={() => navigate("/dashboard/setting/select-backup-phrase")} 
      >
        Backup Now
      </Button>
    </div>
  );
};

export default BackupPhrase;

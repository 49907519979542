import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import IconClose from "../../assets/svg/IconClose";
import { Button } from "../ui/Button";
import Input from "../ui/Input";
import "react-phone-input-2/lib/style.css";
import SuccessMessageModal from "./SuccessMessageModal";
import { authStore } from "../../context/AuthProvider";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { useSnackbar } from "notistack";
import { AUTH_API_URL, USER_API_URL } from "../../api/api";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { errors } from "jose";
import IconCheck from "../../assets/svg/IconCheck";
import IconFree from "../../assets/images/icn_auto_less_fees.svg";
import { t } from "i18next";

const AutoLessFessModal = ({ open, handleClose, setStep, isForDisable, callAutoLessFeesApi, text, setText }) => {
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const setIsVerified2FA = authStore((state) => state?.setIsVerified2FA)
  const [value, setValue] = useState()
  const { user, set } = authStore();
  const axiosInstance = useAxiosPrivate();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [twoFadisabled, setTwoFadisabledDisabled] = useState(true);
  const [startOtpTimer, setStartOtpTimer] = useState(false);
  const [emailDisable, setEmailDisable] = useState(true);
  const otpResendTime = 60;
  const [otpTime, setOtpTime] = useState(otpResendTime);
  const [disbleSubmit, setDisbleSubmit] = useState(true);
  const [isEmailVerify, setIsEmailVerify] = useState(false);

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[99]" onClose={() => {
          // setEmailDisable(false);
          // setTwoFadisabledDisabled(false);
          // handleClose();
          // setIsEmailVerify(false)

          return;
        }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark border border-light-100 shadow-xl transition-all">
                  <div className="flex justify-center p-8 bg-card border-b-light-100 border-b rounded-t-[30px]">
                    <p className="text-white ms-auto">

                    </p>
                    <IconClose
                      onClick={() => {
                        handleClose();
                        setText('');
                        return;
                      }}
                      className="text-white w-[18px] ms-auto cursor-pointer"
                    />
                  </div>
                  <div className="p-12 text-white cursor-pointer text-15 flex items-center justify-center flex-col gap-8">
                    <img src={IconFree} alt="" className="h-[40%] w-[40%]" />
                    <p className="text-center text-[16px] font-600">What is auto less fee?</p>
                    <p className="text-center text-[14px] font-400">When the 'Auto-less Fees' option is disabled, transaction fees will be deducted directly from the sender account. Enable 'Auto-less Fees' to pay more Fees and use our TRX energy to cover your fees.</p>
                    <Button className="w-full text-[14px] py-[10px] px-[20px]" onClick={() => text ? handleClose() : user?.isAutoLessFees == 0 ? callAutoLessFeesApi() : ''} >
                      {text ? text : 'Enabled auto less fee'}

                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

    </>
  );
};

export default AutoLessFessModal;

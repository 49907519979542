import React from "react";

const IconBackupCloud = (props) => {
  return (
    <svg {...props} viewBox="0 0 25 21" fill="none">
      <path
        d="M21.3256 7.34179C20.0697 -1.32366 8.40258 -2.72325 5.12293 5.39189C-1.18916 7.46718 0.0306983 16.5263 6.68398 16.84L8.16143 19.3995L10.0813 18.6204C10.2574 18.7364 10.4401 18.8421 10.6284 18.9365L10.9135 20.9883H15.0377L15.3228 18.9365C15.5111 18.8421 15.6938 18.7364 15.8699 18.6204L17.7898 19.3995L19.2663 16.8419H20.1877C22.7793 16.8821 25.0512 14.5876 24.9844 11.9962C24.9732 9.77588 23.4361 7.86684 21.3256 7.34179ZM17.183 17.6328L15.6619 17.0152C15.285 17.3218 14.8702 17.6286 14.4145 17.8018L14.0365 17.9545L13.8111 19.5794H12.1401L11.9147 17.9545L11.5366 17.8018C11.0814 17.6288 10.6657 17.3215 10.2893 17.0152L8.76819 17.6328C8.56103 17.2738 8.13842 16.5417 7.93272 16.1858C8.17073 16.0004 9.00488 15.3506 9.22748 15.1775C9.18696 14.8881 9.12849 14.5278 9.13403 14.2388C9.12839 13.9507 9.18705 13.5881 9.22748 13.3L7.93272 12.2921L8.76819 10.8452L10.2893 11.4628C10.6658 11.1556 11.081 10.8498 11.5366 10.6757L11.9147 10.5231L12.1401 8.89861H13.8111L14.0365 10.5231L14.4145 10.6757C14.8698 10.8495 15.2858 11.1558 15.6619 11.4628L17.183 10.8452L18.0185 12.2921L16.7237 13.3C16.7642 13.5894 16.8227 13.9497 16.8172 14.2388C16.8228 14.5268 16.7641 14.8894 16.7237 15.1775C16.9417 15.3471 17.7853 16.0043 18.0185 16.1858C17.8133 16.5408 17.3895 17.2749 17.183 17.6328ZM22.5884 14.4214C21.9469 15.0691 21.0922 15.4283 20.1839 15.433H19.3452L18.2167 14.5543C18.2293 14.3432 18.2293 14.1345 18.2167 13.9232L19.8519 12.65L17.7898 9.07848L15.8699 9.85759C15.6938 9.74112 15.5111 9.63546 15.3228 9.54106L15.0377 7.48972H10.9135L10.6284 9.54106C10.4401 9.63546 10.2574 9.74112 10.0813 9.85759L8.16143 9.07848L6.09929 12.65L7.73454 13.9232C7.72186 14.1345 7.72186 14.3432 7.73454 14.5543L6.61259 15.4279C5.68179 15.3842 4.77822 15.0456 4.04842 14.4637C1.00973 11.9961 2.35297 7.18987 6.20495 6.57489C7.03385 3.55752 9.8466 1.37492 12.9878 1.40479C16.9273 1.39892 20.0273 4.64452 20.0073 8.56236L20.5869 8.63468C23.3704 8.97032 24.5768 12.4425 22.5884 14.4214Z"
        fill="currentColor"
      />
      <path
        d="M12.9756 12.0357C11.2424 12.0252 10.1783 13.9958 11.1253 15.433C11.9728 16.7711 13.9786 16.7711 14.8259 15.4329C15.7726 13.996 14.7088 12.025 12.9756 12.0357ZM12.9756 13.4446C13.4137 13.4446 13.7697 13.801 13.7697 14.2387C13.7263 15.2929 12.2247 15.2926 12.1814 14.2387C12.1814 13.801 12.5374 13.4446 12.9756 13.4446Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconBackupCloud;

import React from 'react'

const IconDown = (props) => {
    return (<svg {...props} viewBox="0 0 20 20" fill="none" >
        <path fillRule="evenodd" clipRule="evenodd" d="M3.04647 7.10477C3.24173 6.90951 3.55832 6.90951 3.75358 7.10477L9.18691 12.5381C9.63332 12.9845 10.3667 12.9845 10.8131 12.5381L16.2465 7.10477C16.4417 6.90951 16.7583 6.90951 16.9536 7.10477C17.1488 7.30004 17.1488 7.61662 16.9536 7.81188L11.5202 13.2452C10.6833 14.0821 9.31673 14.0821 8.4798 13.2452L3.04647 7.81188C2.85121 7.61662 2.85121 7.30004 3.04647 7.10477Z" fill="currentcolor" />
    </svg>
    )
}

export default IconDown

import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect } from 'react'
import IconClose from '../../assets/svg/IconClose'
import { Button } from '../ui/Button'
import { DateFomat, files, formatDate, toFixedWithoutZeros } from '../../Helper'
import { authStore } from '../../context/AuthProvider'
import defaultimage from '../../assets/images/logo.png'
import { pdf } from '@react-pdf/renderer';
import TransactionView from "../../pages/TransactionView/TransactionView";
import IcnCopy from '../../assets/svg/IcnCopy';
import { useSnackbar } from 'notistack'
import { TRANSACTION_API_URL } from '../../api/api'
import { axiosInstance } from '../../api/axiosInstance'

const TransactionDetailModal = ({ open, handleClose, detailData, plan, setDetailData,updateTransactionStatus}) => {
  const { user } = authStore();
  const { enqueueSnackbar } = useSnackbar()
  const handleDownloadPdf = async (document, filename) => {
    saveBlob(await pdf(document).toBlob(), `transaction_detail_${detailData?.createdAt}.pdf`);
  }
  const saveBlob = (blob, filename) => {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleCopy = (text) => {
    navigator?.clipboard?.writeText(text)
      .then(() => {
        enqueueSnackbar("Copied to clipboard!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      })
      .catch((error) => {
        console.error('Unable to copy text to clipboard', error);
        // Handle the error or provide user feedback
      });
  }


  useEffect(() => {

    if (open) {
      fatchBroadcastTransactionsDetails();
    } 

  }, [open])


  const fatchBroadcastTransactionsDetails = async (data) => {
    try {
      const result = await axiosInstance.get(TRANSACTION_API_URL.broadcastTransactionsDetails, {
        params: {
          'txId': detailData?.txId
        }
      })
      if (result.status === 200) {
        setDetailData((prevData) => ({
          ...prevData,
          status: result?.data?.status, // Update only the status property
        }));

        updateTransactionStatus(detailData?.txId, result?.data?.status);


      }
    } catch (error) {
      console.log({ error })
    }
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all">
                <div className="flex justify-center p-8 bg-card border-b-light-100 border-b">
                  <p className='text-white ms-auto'>{plan == 'nft' ? "NFTs" : "Transactions Details"}</p>
                  <IconClose onClick={handleClose} className="text-white w-[18px] ms-auto cursor-pointer" />
                </div>
                <div className="p-12">
                  {
                    plan == 'coins' ? (
                      <div className="flex items-center justify-between border-b border-b-light-200 mb-4 pb-6 ">

                        <div className="token-detail flex items-center w-full">
                          <img className='w-[44px] aspect-square rounded-full mr-4' src={files(detailData?.icon, 'image')} alt="" />
                          <div className="flex justify-between items-center w-full">
                            <div className="text">

                              <h6 className='text-16 text-white mb-2'>{detailData?.currencyName || 'N/A'}</h6>

                              <p className='text-14 text-gray'>{detailData?.toUserId == user?._id ? 'Recive' : 'Send'}</p>



                            </div>
                            <div className="text">
                              {
                                detailData?.destination && user?.vaultAccountAssetId == detailData?.destination?.id ? (
                                  <h5 className='text-theme'>{toFixedWithoutZeros(detailData?.amount, 8) + ' ' || 'N/A'}</h5>

                                ) : detailData?.source && user?.vaultAccountAssetId == detailData?.source?.id ? (
                                  <h5 className='text-red'>{toFixedWithoutZeros(detailData?.amount, 8) + ' ' || 'N/A'}</h5>
                                ) : <></>
                              }
                            </div>
                          </div>
                        </div>
                        {detailData?.toUserId == user?._id ?
                          <p className='text-green'>+{detailData?.amount}</p> :
                          <p className='text-red'>-{detailData?.amount}</p>
                        }
                      </div>
                    ) :
                      <>
                        <h6 className='text-gray text-14 mb-4'>Nfts</h6>
                        <p className='text-white pb-4 border-b border-b-light-200 mb-4' style={{ wordWrap: 'break-word' }}>{detailData?.assetId || 'N/A'}</p>
                        <h6 className='text-gray text-14 mb-4'>Transaction type</h6>
                        <p className='text-white pb-4 border-b border-b-light-200 mb-4' style={{ wordWrap: 'break-word' }}>  {
                          detailData?.destination && (user?.vaultAccountAssetId == detailData?.destination?.id) ?

                            "Recieve"
                            : detailData?.source && (user?.vaultAccountAssetId == detailData?.source?.id) ?

                              "Send"
                              : <></>
                        }</p>
                        <h6 className='text-gray text-14 mb-4'>Amount</h6>
                        <p className='text-theme pb-4 border-b border-b-light-200 mb-4' style={{ wordWrap: 'break-word' }}>{(detailData?.amount)}</p>
                      </>
                  }


                  <div className='border-b border-b-light-200 mb-4'>
                    <h6 className='text-gray text-14 mb-4'>Transaction hash</h6>
                    <div className="flex item-center gap-[12px]">
                      <p className='text-white pb-4 break-all flex-grow'>{detailData?.txId || 'N/A'}
                      </p>
                      <span className='h-[32px] w-[32px] bg-linear-horizontal rounded-full flex-shrink-0 flex items-center justify-center cursor-pointer'>
                        <IcnCopy className="h-[20px] w-[20px] text-white" onClick={() => handleCopy(detailData?.txId)} />
                      </span>
                    </div>
                  </div>
                  <div className='border-b border-b-light-200 mb-4'>
                    <h6 className='text-gray text-14 mb-4'>To</h6>
                    <div className="flex item-center gap-[12px]">
                      <p className='text-white pb-4 break-all flex-grow'>{detailData?.to || 'N/A'}</p>
                      <span className='h-[32px] w-[32px] bg-linear-horizontal rounded-full flex-shrink-0 flex items-center justify-center cursor-pointer'>
                        <IcnCopy className="h-[20px] w-[20px] text-white" onClick={() => handleCopy(detailData?.to)} />
                      </span>
                    </div>
                  </div>
                  <div className='border-b border-b-light-200 mb-4'>
                    <h6 className='text-gray text-14 mb-4'>From</h6>
                    <div className="flex item-center gap-[12px]">
                      <p className='text-white pb-4 break-all flex-grow'>{detailData?.from || 'N/A'}</p>
                      <span className='h-[32px] w-[32px] bg-linear-horizontal rounded-full flex-shrink-0 flex items-center justify-center cursor-pointer'>
                        <IcnCopy className="h-[20px] w-[20px] text-white" onClick={() => handleCopy(detailData?.from)} />
                      </span>
                    </div>
                  </div>
                 
                  <h6 className='text-gray text-14 mb-4'>Fee</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4'>{ parseFloat(detailData?.fees ? detailData?.fees : 0)} {detailData?.symbol} </p>
                
                  <h6 className='text-gray text-14 mb-4'>Date & Time</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4'>{  formatDate(detailData?.createdAt || 'N/A')}</p>
                  {detailData?.note ?
                    <>
                      <h6 className='text-gray text-14 mb-4'>Description</h6>
                      <p className='text-12 text-white pb-4 border-b border-b-light-200 mb-4'>{detailData?.note || 'N/A'}</p>
                    </>
                    : ""}
                  <h6 className='text-gray text-14 mb-4'>Status</h6>
                  {/* <p className={`${detailData?.status == 'Completed' ? 'text-green' : detailData?.status == 'Pending' ? 'text-[#b28237]' : 'text-red' }`}>{detailData?.status}</p> */}
                  <p className={`${detailData?.status === 'Pending' ? 'text-[#b28237]' : detailData?.toUserId === user?._id ? 'text-green' : 'text-red'}`}>{detailData?.status}</p>
                  {/* <p className={`${detailData?.status == 'Completed' ? 'text-green' : detailData?.status == 'Pending' ? 'text-[#b28237]' : 'text-red' }`}>{detailData?.status}</p> */}
                  {detailData?.status == 'COMPLETED' ? <Button size="sm" className="w-full mt-12" onClick={() => handleDownloadPdf(<TransactionView transactionData={detailData} plan={plan} />, '1.pdf')}>Download</Button> : ""}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default TransactionDetailModal

import React from 'react'
import IconMsg from '../../../assets/svg/IconMsg'
import IconCall from '../../../assets/svg/IconCall'
import IconLocation from '../../../assets/svg/IconLocation'
import IconTime from '../../../assets/svg/IconTime'

const ContactUs = () => {
  return (
    <div className="flex w-full h-full justify-center items-center md:py-40 py-20 px-4">
      <div className='container bg-light-200 border border-light-gray rounded-[20px] lg:p-[50px_120px] md:p-[30px_50px] p-[20px]'>
        <h1 className='lg:text-[42px] sm:text-28 text-20 font-700 capitalize text-center mb-4'>Get in touch with Oppi-Wallet</h1>
        <h4 className='md:text-18 text-16 text-dark-gray font-600 text-center mb-12'>We are always available for all your questions, comments and suggestions. You can reach us at any time.</h4>
        <div className='flex flex-col gap-8 max-w-[500px]'>
          <div className="flex gap-4">
            <IconCall className='md:h-[28px] h-[24] md:w-[28px] w-[24px] text-greenweb' />
            <h5 className="md:text-16 text-14 font-500 text-dark-gray">Tel:(+98)2454154513 <br />Fax:(+98)2454154513</h5>
          </div>
          <div className="flex gap-4">
            <IconMsg className='md:h-[28px] h-[24] md:w-[28px] w-[24px] text-greenweb' />
            <h5 className="md:text-16 text-14 font-500 text-dark-gray">support@oppiwallet.com</h5>
          </div>
          <div className="flex gap-4">
            <IconLocation className='md:h-[28px] h-[24] md:w-[28px] w-[24px] text-greenweb' />
            <h5 className="md:text-16 text-14 font-500 text-dark-gray">Postal Code: 18945431451</h5>
          </div>
          <div className="flex gap-4">
            <IconLocation className='md:h-[28px] h-[24] md:w-[28px] w-[24px] text-greenweb' />
            <h5 className="md:text-16 text-14 font-500 text-dark-gray">Unit 4, No. 24, South Sarsabz St, Marzdaran Blvd, District 2, Tehran, Iran</h5>
          </div>
          <div className="flex gap-4">
            <IconTime className='md:h-[28px] h-[24] md:w-[28px] w-[24px] text-greenweb' />
            <h5 className="md:text-16 text-14 font-500 text-dark-gray">Business/Working hours are 8:30 am to 12:00 pm Saturday to Wednesday</h5>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
import React from 'react'

const IcnVisaLogo = (props) => {
    return (
        <svg {...props} viewBox="0 0 72 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.3379 0.781985L17.9131 23.2716H11.7616L7.12687 5.32544C6.84155 4.21872 6.5977 3.81597 5.74214 3.346C4.34067 2.58831 2.03541 1.87759 0 1.43491L0.142453 0.782125H10.0395C11.3018 0.782125 12.4366 1.62405 12.7219 3.07544L15.1726 16.092L21.2257 0.782125L27.3379 0.781985ZM51.4335 15.9256C51.4607 9.9932 43.2248 9.67033 43.284 7.01631C43.3 6.20617 44.0678 5.34892 45.7494 5.1325C46.5844 5.02141 48.8824 4.93998 51.4869 6.13572L52.5077 1.36881C51.1064 0.860171 49.3051 0.373047 47.0663 0.373047C41.3163 0.373047 37.2684 3.4315 37.2343 7.80761C37.1966 11.0437 40.1223 12.8496 42.3291 13.9273C44.5951 15.0301 45.3528 15.7342 45.3448 16.7232C45.33 18.2327 43.5368 18.8924 41.8642 18.922C38.9385 18.9676 37.2416 18.1315 35.8864 17.5003L34.8331 22.4311C36.1905 23.0543 38.7038 23.5954 41.3048 23.6274C47.4162 23.6271 51.4142 20.6013 51.4335 15.9256ZM66.618 23.2717H72L67.3038 0.782125H62.3354C61.219 0.782125 60.2778 1.43322 59.8584 2.43067L51.1338 23.2717H57.2404L58.4526 19.913H65.9173L66.618 23.2717ZM60.1262 15.3035L63.1898 6.85895L64.9523 15.3035H60.1262ZM35.652 0.781985L30.8384 23.2716H25.0194L29.8342 0.781985H35.652Z" fill="white" />
        </svg>
    )
}

export default IcnVisaLogo
import React from "react";
import { useTranslation } from "react-i18next";

const FeatureStep = () => {
  const {t} = useTranslation();

  return (
    <section className="container sm:pt-14 !pt-[40px]">
      <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 gap-10">
        <div className="text-center" data-aos="fade-right" data-aos-duration="1500">
          <h6 className="flex justify-center items-center mx-auto w-[60px] h-[60px] border border-greenweb text-greenweb rounded-full">
            1
          </h6>
          <h6 className="my-4 font-600 max-w-[210px] mx-auto">{t('One_of_the_most_secured_wallet')} </h6>
          <p className="text-14 font-400">{t('Always_use_our_cloudbase_backup_facility_which_is_incrypted_in_order_to_increase_your_wallet_security')}</p>
        </div>
        <div className="text-center" data-aos="fade-down" data-aos-duration="1500">
          <h6 className="flex justify-center items-center mx-auto w-[60px] h-[60px] border border-greenweb text-greenweb rounded-full">
            2
          </h6>
          <h6 className="my-4 font-600 max-w-[210px] mx-auto">{t('Hasslefree_solution_without_tracking')}</h6>
          <p className="text-14 font-400">{t('We_are_not_tracking_any_personal_information_of_user_including_address_IP_or_wallet_balances')}</p>
        </div>
        <div className="text-center" data-aos="fade-left" data-aos-duration="1500">
          <h6 className="flex justify-center items-center mx-auto w-[60px] h-[60px] border border-greenweb text-greenweb rounded-full">
            3
          </h6>
          <h6 className="my-4 font-600 max-w-[210px] mx-auto">{t('One_stop_solution_for_all_assets')}</h6>
          <p className="text-14 font-400">{t('Buy_sell_and_swap_crypto_earn_rewards_manage_NFTs_and_discover_DApps_all_in_one_place')}</p>
        </div>
      </div>
    </section>
  );
};

export default FeatureStep;

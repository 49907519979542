import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import IconSendWallet from "../../assets/svg/IconSendWallet"
import IconReceiveWallet from "../../assets/svg/IconReceiveWallet"
import IconClose from '../../assets/svg/IconClose'
import { Button } from '../ui/Button'
import { useNavigate } from 'react-router-dom'
import ReceiveModal from './ReceiveModal'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import { files, fixDecimal } from '../../Helper'
import { useTranslation } from 'react-i18next'
import LineChart from '../common/LineChartDemo'
import LineChartDemo from '../common/LineChartDemo'
import axios from 'axios'
import { authStore } from '../../context/AuthProvider';

const BitcoinDetailModal = ({ open, handleClose, token }) => {

  const navigate = useNavigate();
  const [assetAddress, setAssetAddress] = useState("");
  const [openReceiveModal, setOpenReceiveModal] = useState(false);
  const axiosInstance = useAxiosPrivate();
  const [loading, setloading] = useState(false)
  const [decimalId, setDecimalId] = useState();
  const { t } = useTranslation();
  const [graphData , setGraphData]= useState();

  const [address,setAddress] = useState();



  const handleSend = (token) => {
    navigate('/dashboard/send', { state: { token: token } })
  }
  const handleReceive = async (token) => {
    setOpenReceiveModal(true);
    setDecimalId(token?.symbol)
    setloading(false);
  }

  const hadleCloseReceiveModal = () => {
    setOpenReceiveModal(false)
  }

  async function getGraphDetail() {
    try {
      const symbol = token?.symbol?.toUpperCase();
      const response = await axios.get("https://api.binance.com/api/v3/klines", {
        params: {
          symbol: symbol + "USDT",
          interval: '1h'
        }
      });

      // console.log(response.data); // This will log the response data from the Binance API
    } catch (error) {
      console.error("Error fetching data:", error);
    }

  }


  useEffect(() => {
    if (open == true) {
      getGraphDetail();
    }

  }, [open, token])

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all">
                <div className="flex justify-center p-8 bg-card border-b-light-100 border-b">
                  <p className='text-white ms-auto'>{token?.name}</p>
                  <IconClose onClick={handleClose} className="text-white w-[18px] ms-auto cursor-pointer" />
                </div>
                <div className="p-12 text-center text-white">
                  <img className='w-[80px] h-[80px] mx-auto mb-4 rounded-full object-cover' src={files(token?.icon, 'image')} alt="" />

                  <p className='text-18 flex justify-center gap-2 items-center mb-2'>
                    {token?.ratePrice ? `$` + parseFloat(token?.USDRateForUserBalance?.toFixed(2)) : "$0.00"}
                  </p>
                  <p className='text-18 flex justify-center gap-2 items-center mb-5'>
                    {token?.balance ? parseFloat(fixDecimal(token?.balance)) : "0.00"}  {token?.code ? token?.code : token?.symbol}
                  </p>

                  <div className="flex justify-center gap-8 mb-12">
                    <Button className="p-2 w-[40%] flex gap-4 items-center border border-light-100 !text-14 text-white" variant="gray" onClick={() => handleSend(token)}><div className="rounded-full bg-linear-horizontal w-[36px] h-[36px] flex justify-center items-center" >
                      <IconSendWallet className="text-white" />
                    </div> {t('Send')}</Button>
                    <Button className="p-2 w-[40%] flex gap-4 items-center border border-light-100 !text-14 text-white" variant="gray" onClick={() => handleReceive(token)}><div className="rounded-full bg-linear-horizontal w-[36px] h-[36px] flex justify-center items-center">
                      <IconReceiveWallet className="text-white" />
                    </div> {t('Receive')}</Button>
                  </div>
                  {/* <LineChartDemo /> */}
                </div>
                <ReceiveModal open={openReceiveModal} handleClose={hadleCloseReceiveModal} setAssetAddress={setAssetAddress} assetAddress={assetAddress} loading={loading} token={token} decimalId={decimalId} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default BitcoinDetailModal

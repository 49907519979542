import React from 'react';

const IcnStep1 = (props) => {
    return (
        <svg {...props} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.93671 9.28605C6.7434 8.65005 6.57162 8.00769 6.42167 7.36009C5.9573 7.08958 5.57172 6.70237 5.30318 6.23686C5.03463 5.77135 4.89244 5.24373 4.89073 4.70631C4.89073 3.00078 6.27763 1.61496 7.98208 1.61496C9.68652 1.61496 11.0734 3.00187 11.0734 4.70631C11.0734 4.94092 11.0418 5.1668 10.9927 5.38613C11.2884 6.03757 11.5274 6.77085 11.7369 7.51613C12.3261 6.73047 12.6884 5.76477 12.6884 4.70631C12.6884 2.106 10.5824 0 7.98208 0C5.38177 0 3.27686 2.106 3.27686 4.70631C3.27746 5.77149 3.63997 6.80485 4.30495 7.63696C4.96992 8.46908 5.8979 9.05055 6.93671 9.28605Z" fill="currentColor" />
            <path d="M3.68602 20.4282C4.84596 19.1744 6.14775 19.6654 7.89039 19.9306C9.38751 20.1608 10.8617 19.7407 10.7635 18.9321C10.6086 17.6238 10.3903 17.0389 9.89382 15.3443C9.49772 13.9967 8.74588 11.5677 8.06171 9.24563C7.1451 6.13901 6.87994 4.69645 8.0999 4.33636C9.41478 3.95335 10.1688 5.82147 10.8519 8.41196C11.6299 11.3604 12.0391 12.6622 12.2683 12.5934C12.672 12.4777 12.1199 11.2163 13.1761 10.9053C14.4965 10.5212 14.7518 11.5535 15.1217 11.4498C15.4916 11.3385 15.3661 10.2932 16.4191 9.98546C17.4776 9.67993 18.009 10.9828 18.4455 10.853C18.8776 10.7253 18.8678 10.255 19.5203 10.0684C20.1739 9.87416 22.6335 10.9752 24.0411 15.7841C25.8078 21.8304 23.8174 22.9543 24.4209 25.0014L16.5348 28C15.8965 26.4646 13.9192 26.3512 12.17 25.3724C10.4078 24.3805 9.21073 22.448 4.6179 22.5418C2.89054 22.5767 2.97238 21.1996 3.68602 20.4282Z" fill="currentColor" />
        </svg>

    );
}

export default IcnStep1;

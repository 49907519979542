import { useEffect, useState } from 'react'
import { Combobox } from '@headlessui/react'
import IconDown from '../../assets/svg/IconDown'
import axios from 'axios'
import { cn } from '../../lib/utils'

const CountryList = ({ errorMsg, onChildData }) => {
  const [query, setQuery] = useState('');
  const [countries, setCountries] = useState([]);
  const [countryName, setCountryName] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();

  // const handleSetCountry = (selectedOption) => {
  //   setSelectedCountry(selectedOption);
  //   setQuery(selectedOption);
  //   onChildData(selectedOption); // Pass the selected country back to the parent
  // };

  // get all countries api call
  const getCountries = () => {
    axios.get('https://restcountries.com/v3.1/all?fields=name')
      .then((response) => {
        if (response.status === 200) {
          setCountries(response?.data)
        }
      }).catch((err) => {
        console.log(err, 'err');
      })
  }

  useEffect(() => {
    setTimeout(() => getCountries(), 1000);
  }, []);

  useEffect(() => {
    if (countries && countries.length > 0) {
      const newCountryNames = countries.map((country) => country?.name?.common);
      setCountryName(newCountryNames);
    }
  }, [countries]);

  const filteredCountry =
    query === ''
      ? countryName
      : countryName.filter((country) => {
        return country.toLowerCase().includes(query.toLowerCase())
      });

  return (
    <Combobox value={selectedCountry} onChange={(value) => { setSelectedCountry(value); onChildData(value); }}>
      <div className="relative">
        <span className='toogle-pass-btn absolute right-[20px] top-[18px] cursor-pointer' ><IconDown className="w-[24px] h-[24px] text-black" /></span>
        <Combobox.Input placeholder="Type country name here..." className={`w-full text-light-600 text-14 font-400 bg-light-300 rounded-full border border-light-400 py-[14px] ps-[28px] outline-0 mt-2`} onChange={(event) => setQuery(event.target.value)} />
        {
          filteredCountry?.length ?
            <Combobox.Options className="mt-1 bg-white p-2 rounded-[6px] absolute w-full max-h-[140px] overflow-auto theme-scrollbar">
              {filteredCountry?.map((country) => (
                <Combobox.Option key={country} value={country} className='px-8 py-3 text-[12px] rounded-[6px] hover:bg-light-300 cursor-pointer'>
                  {country}
                </Combobox.Option>
              ))}
            </Combobox.Options> : ''
        }
        {errorMsg && <p className={cn('text-12 text-red-500 font-normal')} style={{ color:"red" , fontSize : '15px', margin:'8px' }}>{errorMsg}</p>}
      </div>
    </Combobox>
  )
}

export default CountryList;

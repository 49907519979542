import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'
import { cn } from '../../lib/utils'

const buttonVariance = cva(
  "button transition-all duration-500",
  {
    variants: {
      variant: {
        fill: 'bg-linear text-16 font-600 rounded-full text-white',
        gray: 'bg-card text-16 font-600 rounded-full',
        outline: 'text-[14px] font-500 rounded-full border border-greenweb text-greenweb hover:bg-linear hover:text-white',
        filled: 'text-[14px] font-600 rounded-full bg-linear border border-greenweb text-white hover:bg-transparent'
      },
      size: {
        default: 'py-[14px] px-[28px]',
        sm: 'py-[12px] px-[24px]',
      }
    },
    defaultVariants: {
      size: "default",
      variant: 'fill'
    }
  },
)
 
const Button = forwardRef(({ children, variant, size, className, ...props }, ref) => {
  return (
    <button className={cn(buttonVariance({ variant, size, className }))} {...props} ref={ref}>
      {children}
    </button>
  )
})

export { Button, buttonVariance }
import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import SideBar from '../../components/dash/SideBar';
import DashHeader from '../../components/dash/DashHeader';

const PhraseMaster = () => {
  const [tooglesidebar, setTooglesidebar] = useState(false);
  const createPhrase = true;

  return (
    <>
      <DashHeader setTooglesidebar={setTooglesidebar} tooglesidebar={tooglesidebar} />
      <main className='mt-[80px]'>
        <div className="main-layout-wrapper">
          <div className="flex min-h-[calc(100vh-80px)] ">
            <SideBar setTooglesidebar={setTooglesidebar} tooglesidebar={tooglesidebar} createPhrase={createPhrase} />
            <div className="main-container flex-grow xxl:h-[calc(100vh-80px)] xxl:max-h-[calc(100vh-80px)] overflow-y-auto theme-scrollbar">
              <Outlet />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default PhraseMaster
import React from 'react'

const IconReceive = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none">
      <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C12.41 1.25 12.75 1.59 12.75 2C12.75 2.41 12.41 2.75 12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 11.59 21.59 11.25 22 11.25C22.41 11.25 22.75 11.59 22.75 12C22.75 17.93 17.93 22.75 12 22.75Z" fill="currentcolor" />
      <path d="M13.8 10.95C13.61 10.95 13.42 10.88 13.27 10.73C12.98 10.44 12.98 9.96 13.27 9.67L21.47 1.47C21.76 1.18 22.24 1.18 22.53 1.47C22.82 1.76 22.82 2.24 22.53 2.53L14.33 10.73C14.19 10.87 14 10.95 13.8 10.95Z" fill="currentcolor" />
      <path d="M17.83 11.75H13C12.59 11.75 12.25 11.41 12.25 11V6.17C12.25 5.76 12.59 5.42 13 5.42C13.41 5.42 13.75 5.76 13.75 6.17V10.25H17.83C18.24 10.25 18.58 10.59 18.58 11C18.58 11.41 18.24 11.75 17.83 11.75Z" fill="currentcolor" />
    </svg>
  )
}

export default IconReceive



import React, { useEffect, useState } from "react";
import { Button } from "../../../components/ui/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty, shuffle } from "lodash";
import { useMutation } from "react-query";
import { USER_API_URL } from "../../../api/api";
import { useSnackbar } from "notistack";
import { handleEncrypt } from "../../../Helper";
import useAxiosPrivate from "../../../api/useAxiosPrivate";
import { authStore } from "../../../context/AuthProvider";

const BackupManually = () => {
  const [phrases, setPhrases] = useState();
  const [verifyPhrase, setVerifyPhrase] = useState(false);
  const [shuffledWords, setShuffledWords] = useState([]);
  const [arrangedWords, setArrangedWords] = useState([]);
  const setHasEncyptionWordKey = authStore((state) => state.setHasEncyptionWordKey);
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const location = useLocation();
  const axiosInstance = useAxiosPrivate(
    
  )

  useEffect(() => {
    if (location?.state?.words) {
      setPhrases(location?.state?.words);
    }

  }, [location])
  

  useEffect(() => {
    if (verifyPhrase && isEmpty(shuffledWords)) {
      const shuffledWords = shuffle(phrases);
      setShuffledWords(shuffledWords);
    }

  }, [verifyPhrase])

  const handleCopy = () => {
    const wordsToCopy = phrases?.join(', ');
    navigator?.clipboard?.writeText(wordsToCopy).then(() => {
      console.log('Words copied to clipboard:', wordsToCopy);
    });
  }


  
  const arrangeWordsByOrder = (clickedWord) => {
    if (arrangedWords.includes(clickedWord)) {
      // Remove from arrangedWords and append to shuffledWords
      const updatedArrangedWords = arrangedWords.filter((word) => word !== clickedWord);
      setArrangedWords(updatedArrangedWords);
      setShuffledWords([...shuffledWords, clickedWord]);
    } else {
      // Remove from shuffledWords and append to arrangedWords
      const updatedShuffledWords = shuffledWords.filter((word) => word !== clickedWord);
      setShuffledWords(updatedShuffledWords);
      setArrangedWords([...arrangedWords, clickedWord]);
    }
  }
  

  
  const handleWords = async () => {
    const arrayMatched = await arrayMatch();
    if (arrayMatched) {
      setShuffledWords([])
      const getSecretKey = handleEncrypt(arrangedWords);
      const data = {
        encyption_word_key: getSecretKey,
        isBackup: 1
      }
      await storeSecreat(data)
    }
  }
  const arrayMatch = () => {

    // Check if the lengths are not equal
    if (arrangedWords.length !== phrases.length) {
      enqueueSnackbar("Select all words!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });

      return false;
    }

    // Check if words are in correct order as phrases
    for (let i = 0; i < phrases.length; i++) {
      if (phrases[i] !== arrangedWords[i]) {
        enqueueSnackbar("Selected Words are not matched! Please select words in order!", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
        return false;
      }
    }

    return true;
  };


  
  const { mutateAsync: storeSecreat } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(USER_API_URL.storeKey, data)
        if(response){
          setHasEncyptionWordKey(1)
          enqueueSnackbar(response?.data.message, {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
          navigate("/dashboard/setting");
        }
      } catch (error) {
        if (error?.response?.data.errors) {
          const errors = error?.response?.data.errors;
          Object.keys(errors).map(function (key) {
            enqueueSnackbar(errors[key], {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        }
      }
    },
    {
      onSuccess: (data) => {
        
      },
      onError: (error) => {
      },
    }
  );

  return (
    <div className="bg-card min-h-full">
      {!verifyPhrase ? (
        <div className="py-12 px-8 flex flex-col min-h-[100vh]">
          <div className="flex-grow h-full">
            <p className="text-gray font-400 mb-8">
              Write down your recovery phrase in the order you see below.
            </p>
            <div className="flex sm:gap-x-10 gap-x-6 gap-y-8 flex-wrap">
              {phrases?.map((phrase, i) => {
                return (
                  <div className="phrase flex gap-2 border border-light-100 p-2 pr-8 rounded-full">
                    <span className="w-[28px] h-[28px] bg-white rounded-full flex justify-center items-center text-14 font-500">
                      {i + 1}
                    </span>
                    <span className="text-white text-20 font-400">
                      {phrase}
                    </span>
                  </div>
                );
              })}
            </div>
            <Button
              size="sm"
              className="w-[50%] mx-auto block mt-20 mb-12 py-[9px]"
              onClick={handleCopy}
            >
              Copy
            </Button>
            <p className="text-gray font-400">
              Copy them to a password manager or write them down. Make sure to
              keep it handy, the next step is to verify your phrase
            </p>
          </div>
          <Button
            className="w-full text-14 font-600"
            size="sm"
            onClick={() => setVerifyPhrase(true)}
          >
            Next
          </Button>
        </div>
      ) : (
        <div className="py-12 px-8 flex flex-col min-h-[100vh]">
          <div className="flex-grow h-full">
            <p className="text-gray font-400 mb-8">
              tap the words to put them next to each other in the correct order.
            </p>
            <div className="flex gap-6 flex-wrap bg-[#000] py-12 px-6 rounded-3xl mb-8">
              {arrangedWords?.map((phrase, i) => {
                return (
                  <div className="phrase flex gap-2 bg-card p-[4px] pr-[20px] rounded-full border border-light-100">
                    <span className="w-[28px] h-[28px] bg-theme rounded-full flex justify-center items-center text-14 font-500">
                      {i + 1}
                    </span>
                    <span className="text-white text-20 font-400"  onClick={() => arrangeWordsByOrder(phrase)}>
                      {phrase}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="flex gap-x-6 gap-y-6 flex-wrap mb-12">
              {shuffledWords?.map((phrase, i) => {
                return (
                  <div className="phrase flex gap-2">
                    <span className="text-white text-20 font-400 border border-light-100 p-2 px-8 rounded-[20px]"  onClick={() => arrangeWordsByOrder(phrase)}>
                      {phrase}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <Button className="w-full text-14 font-600" size="sm" onClick={() => handleWords()}>
            Verify
          </Button>
        </div>
      )}
    </div>
  );
};

export default BackupManually;

import React from 'react'

const IconAddress = (props) => {
  return (
    <svg {...props} viewBox="0 0 20 20" fill="none">
      <path d="M16.9894 1.80199C16.5839 1.39566 16.0447 1.17188 15.471 1.17188H14.502V0.976562C14.502 0.438086 14.0639 0 13.5254 0H6.49414C5.95567 0 5.51758 0.438086 5.51758 0.976562V1.17188H4.54504C3.36297 1.17188 2.4002 2.13461 2.39887 3.31793L2.38281 17.8492C2.38219 18.4235 2.60512 18.9636 3.01055 19.3699C3.41602 19.7762 3.95527 20 4.52899 20H15.4549C16.637 20 17.5998 19.0373 17.6011 17.8539L17.6172 3.3227C17.6179 2.74836 17.3949 2.20828 16.9894 1.80199ZM6.68945 1.17188H13.3301V2.34375H6.68945V1.17188ZM16.4305 17.8526C16.4298 18.3905 15.9922 18.8281 15.4549 18.8281H4.52899C4.2682 18.8281 4.02309 18.7264 3.83879 18.5417C3.65449 18.357 3.55317 18.1115 3.55344 17.8505L3.56949 3.31926C3.57008 2.78133 4.0077 2.34375 4.54504 2.34375H5.51758V2.53906C5.51758 3.07754 5.95567 3.51562 6.49414 3.51562H13.5254C14.0639 3.51562 14.502 3.07754 14.502 2.53906V2.34375H15.471C15.7318 2.34375 15.9769 2.44547 16.1612 2.63016C16.3455 2.81484 16.4469 3.06035 16.4466 3.32137L16.4305 17.8526Z" fill="currentcolor" />
      <path d="M10.1991 7.81246H14.3621C14.6856 7.81246 14.948 7.55011 14.948 7.22652C14.948 6.90292 14.6856 6.64058 14.3621 6.64058H10.1991C9.87553 6.64058 9.61318 6.90292 9.61318 7.22652C9.61318 7.55011 9.87553 7.81246 10.1991 7.81246ZM10.1991 11.7187H14.3621C14.6856 11.7187 14.948 11.4564 14.948 11.1328C14.948 10.8092 14.6856 10.5468 14.3621 10.5468H10.1991C9.87553 10.5468 9.61318 10.8092 9.61318 11.1328C9.61318 11.4564 9.87553 11.7187 10.1991 11.7187ZM14.3788 14.4531H10.1991C9.87553 14.4531 9.61318 14.7154 9.61318 15.039C9.61318 15.3626 9.87553 15.625 10.1991 15.625H14.3788C14.7024 15.625 14.9647 15.3626 14.9647 15.039C14.9647 14.7154 14.7024 14.4531 14.3788 14.4531ZM7.70525 5.63109L6.354 6.98234L6.03541 6.66371C5.80658 6.43488 5.43561 6.43488 5.20678 6.66371C4.97795 6.89249 4.97795 7.26351 5.20678 7.49234L5.93971 8.22531C6.04959 8.33519 6.19863 8.39692 6.35402 8.39692C6.50942 8.39692 6.65846 8.33519 6.76834 8.22531L8.53393 6.45976C8.76275 6.23097 8.76275 5.85996 8.53393 5.63113C8.3051 5.40226 7.93412 5.40226 7.70525 5.63109ZM7.70525 9.83566L6.354 11.1869L6.03541 10.8683C5.80658 10.6395 5.43561 10.6395 5.20678 10.8683C4.97795 11.0971 4.97795 11.4681 5.20678 11.6969L5.93971 12.4299C6.04959 12.5398 6.19863 12.6015 6.35402 12.6015C6.50942 12.6015 6.65846 12.5398 6.76834 12.4299L8.53393 10.6643C8.76275 10.4355 8.76275 10.0645 8.53393 9.8357C8.3051 9.60687 7.93408 9.60687 7.70525 9.83566ZM7.70525 13.7419L6.354 15.0932L6.03541 14.7746C5.80658 14.5457 5.43561 14.5457 5.20678 14.7746C4.97795 15.0034 4.97795 15.3743 5.20678 15.6032L5.93971 16.3362C6.04959 16.446 6.19863 16.5078 6.35402 16.5078C6.50942 16.5078 6.65846 16.446 6.76834 16.3362L8.53393 14.5706C8.76275 14.3418 8.76275 13.9708 8.53393 13.7419C8.3051 13.5131 7.93408 13.5131 7.70525 13.7419Z" fill="currentcolor" />
    </svg>
  )
}

export default IconAddress
import React from "react";

const IconTrusted = (props) => {
  return (
    <svg {...props} viewBox="0 0 29 28" fill="none">
      <path
        d="M24.9526 12.9733C24.9526 18.6783 20.8109 24.0216 15.1526 25.585C14.7676 25.69 14.3476 25.69 13.9626 25.585C8.30426 24.0216 4.1626 18.6783 4.1626 12.9733V7.85162C4.1626 6.89496 4.88593 5.80996 5.78426 5.44829L12.2826 2.78829C13.7409 2.19329 15.3859 2.19329 16.8443 2.78829L23.3426 5.44829C24.2293 5.80996 24.9643 6.89496 24.9643 7.85162L24.9526 12.9733Z"
        stroke="currentcolor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.5576 14.5833C15.1764 14.5833 15.7699 14.3375 16.2075 13.8999C16.6451 13.4623 16.8909 12.8688 16.8909 12.25C16.8909 11.6311 16.6451 11.0376 16.2075 10.6C15.7699 10.1625 15.1764 9.91663 14.5576 9.91663C13.9387 9.91663 13.3452 10.1625 12.9077 10.6C12.4701 11.0376 12.2242 11.6311 12.2242 12.25C12.2242 12.8688 12.4701 13.4623 12.9077 13.8999C13.3452 14.3375 13.9387 14.5833 14.5576 14.5833ZM14.5576 14.5833V18.0833"
        stroke="currentcolor"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconTrusted;

import { Listbox, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import IconDown from "../../assets/svg/IconDown";
import IconUs from "../../assets/svg/IconUs";
import IconTurkey from "../../assets/svg/IconTurkey";
import { authStore } from "../../context/AuthProvider";
import { useTranslation } from "react-i18next";

const languagesData = [
  { lang: 'en', Icon: <IconUs className="w-8" /> },
  { lang: 'tr', Icon: <IconTurkey className="w-8" /> },
]


const LanguageDropdown = () => {
  const [selected, setSelected] = useState(languagesData && languagesData[0]);
  const { i18n } = useTranslation();
  const { setLanguage } = authStore();
  // const { language } = authStore();

  // useEffect(() => {
  //   if (language) {
  //     setSelected(language);
  //   }
  // }, [language])

  useEffect(() => {
    if (selected) {
      i18n.changeLanguage(selected.lang);
      setLanguage(selected.lang)
    }

  }, [selected])

  return (
    <>
      <div className="relative">
        <Listbox value={selected} onChange={setSelected}>
          <div className="relative">
            <Listbox.Button className="flex items-center justify-center gap-sm">
              <div className="flex items-center gap-sm">
                <span>{selected.Icon}</span>
                <span className="truncate">{selected.lang}</span>
              </div>
              <IconDown className="text-white w-[18px]" />
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-[3] mt-[8px] bg-white !rounded-2xl !rounded-tr-none right-0">
                {languagesData?.map((value, index) => (
                  <Listbox.Option
                    key={index}
                    className={"px-[12px] py-3 cursor-pointer"}
                    value={value}
                  >
                    {({ selected }) => (
                      <div className="flex w-full gap-2 items-center">
                        {value.Icon}
                        <h6 className={`text-14 font-500 text-black`}>
                          {value.lang}
                        </h6>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </>
  );
};

export default LanguageDropdown;

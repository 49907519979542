import React from 'react';

const IconHide = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 9.17204C11.6286 9.17204 11.2609 9.24519 10.9178 9.38731C10.5747 9.52943 10.2629 9.73773 10.0003 10.0003C9.73775 10.2629 9.52944 10.5747 9.38732 10.9178C9.24521 11.2609 9.17206 11.6286 9.17206 12C9.17206 12.4944 9.3015 12.9754 9.54162 13.3977L13.3978 9.5416C12.9754 9.30149 12.4944 9.17204 12 9.17204ZM10.3438 8.00149C10.8689 7.78399 11.4317 7.67204 12 7.67204C13.1479 7.67204 14.2487 8.12802 15.0603 8.93967C15.3532 9.23257 15.3532 9.70744 15.0603 10.0003L10.0003 15.0603C9.70746 15.3532 9.23258 15.3532 8.93969 15.0603C8.12804 14.2487 7.67206 13.1478 7.67206 12C7.67206 11.4316 7.784 10.8689 8.0015 10.3438C8.219 9.81867 8.5378 9.34156 8.93969 8.93967C9.34158 8.53778 9.81869 8.21899 10.3438 8.00149Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.25752 9.00696C4.64844 5.2486 8.15205 2.98 12 2.98C14.2497 2.98 16.4055 3.7636 18.2717 5.17123C18.6024 5.42066 18.6682 5.89095 18.4188 6.22164C18.1694 6.55233 17.6991 6.6182 17.3684 6.36876C15.7346 5.13639 13.8904 4.48 12 4.48C8.78817 4.48 5.71192 6.3712 3.52285 9.81254L3.52222 9.81352C3.16763 10.369 2.96503 11.1642 2.96503 12.005C2.96503 12.8458 3.16763 13.641 3.52222 14.1965L3.52256 14.197C4.27633 15.3801 5.14914 16.393 6.08906 17.2014C6.40311 17.4714 6.43875 17.945 6.16866 18.259C5.89858 18.5731 5.42505 18.6087 5.111 18.3386C4.05092 17.427 3.08372 16.2998 2.25749 15.003C1.71232 14.1486 1.46503 13.054 1.46503 12.005C1.46503 10.956 1.71233 9.86137 2.25752 9.00696ZM19.58 7.34559C19.9027 7.08597 20.3748 7.13717 20.6344 7.45993C21.0182 7.93699 21.3951 8.4496 21.7428 8.99741C22.2878 9.85176 22.535 10.9461 22.535 11.995C22.535 13.044 22.2877 14.1386 21.7425 14.993C19.3516 18.7514 15.848 21.02 12 21.02C10.6635 21.02 9.35485 20.7374 8.12898 20.2212C7.74723 20.0605 7.56806 19.6207 7.7288 19.239C7.88954 18.8572 8.32932 18.678 8.71107 18.8388C9.7652 19.2826 10.8766 19.52 12 19.52C15.2119 19.52 18.2881 17.6288 20.4772 14.1875L20.4778 14.1865C20.8324 13.631 21.035 12.8358 21.035 11.995C21.035 11.1542 20.8324 10.359 20.4778 9.80352L20.4768 9.80186C20.1646 9.30994 19.8217 8.8428 19.4656 8.40006C19.206 8.0773 19.2572 7.6052 19.58 7.34559Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M22.5303 1.46967C22.8232 1.76256 22.8232 2.23744 22.5303 2.53033L15.0603 10.0003C14.7674 10.2932 14.2926 10.2932 13.9997 10.0003C13.7068 9.70744 13.7068 9.23256 13.9997 8.93967L21.4697 1.46967C21.7626 1.17678 22.2374 1.17678 22.5303 1.46967ZM15.653 11.9638C16.0596 12.0427 16.3252 12.4364 16.2462 12.843C16.0823 13.6873 15.6697 14.4634 15.0615 15.0715C14.4534 15.6797 13.6773 16.0923 12.833 16.2562C12.4264 16.3352 12.0327 16.0696 11.9538 15.663C11.8748 15.2564 12.1404 14.8627 12.547 14.7838C13.0978 14.6768 13.6041 14.4076 14.0009 14.0109C14.3976 13.6141 14.6668 13.1078 14.7738 12.557C14.8527 12.1504 15.2464 11.8848 15.653 11.9638ZM10.0003 13.9997C10.2932 14.2926 10.2932 14.7674 10.0003 15.0603L2.53033 22.5303C2.23744 22.8232 1.76256 22.8232 1.46967 22.5303C1.17678 22.2374 1.17678 21.7626 1.46967 21.4697L8.93967 13.9997C9.23256 13.7068 9.70744 13.7068 10.0003 13.9997Z" fill="currentColor" />
        </svg>
    );
}

export default IconHide;

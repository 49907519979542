import React, { forwardRef } from 'react'
import { cn } from '../../lib/utils';
import IconCamera from '../../assets/svg/IconCamera';

const BasicFileUpload = forwardRef(({ className, avatar, PreImg, userData, ...props }, ref) => {

  
    return (
        <div className={cn('h-[100px] w-[100px] inline-block relative', className)}>
            <div className="avatar-preview w-full h-full rounded-full overflow-hidden">
                {!PreImg?.includes('null') ?
                    <img src={PreImg} alt="" width={"120px"} height={"120px"} className='object-cover object-center w-full h-full' />
                    :
                    userData?.profile ?
                        <img src={avatar} alt="" width={"120px"} height={"120px"} className='object-cover object-center w-full h-full' />
                        :
                        <div style={{ width: '100px', height: '105px', display: 'flex', alignItems: 'center', justifyContent: 'center',  fontWeight: 600, borderRadius: '50%', fontSize: '32px', }} className='bg-linear-horizontal'>{userData?.name ? userData?.name?.split(' ').slice(0, 2).map(name => name.slice(0, 1).toUpperCase()) : userData?.email?.slice(0, 1).toUpperCase()}</div>
                }
            </div>
            <input type="file" {...props} className='hidden' id='file-upload' ref={ref} />
            <label htmlFor="file-upload" className='w-[36px] h-[36px] rounded-full bg-[#7684ED] flex items-center justify-center cursor-pointer absolute -bottom-[3px] -right-[1px]'>
                <IconCamera className="w-[20px] h-[20px] text-white" />
            </label>
        </div>
    )
})

export default BasicFileUpload
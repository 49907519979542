import React, { useTransition } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { iconButtonVariants } from '../ui/IconButton';
import IconClose from "../../assets/svg/IconClose"
import IconMenu from "../../assets/svg/IconMenu"
import { cn } from '../../lib/utils';
import { Menu } from '@headlessui/react';
import { authStore } from '../../context/AuthProvider';
import { files } from '../../Helper';
import { useTranslation } from 'react-i18next';

const DashHeader = ({ setTooglesidebar, tooglesidebar }) => {
  const { user } = authStore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const pathname = location.pathname;
  const segments = pathname.split('/');
  const lastSegment = segments[segments.length - 1];
  const capitalizedSegment = (lastSegment == "api") ? lastSegment.toUpperCase() : lastSegment.charAt(0).toUpperCase() + lastSegment.slice(1);

  return (
    <>
      <header className='dash-header bg-card fixed top-0 left-0 w-full h-[80px] z-[9]'>
        <div className="header-wrapper h-[100%] flex items-center xl:gap-0 sm:px-0 px-[24px]">
          <Link /* to={"/"} */ className='flex items-center h-full w-full max-w-[20%] flex-shrink-0 sm:border-e-2 border-[#b3b3b31a]'>
            {/* <img src={require('../../assets/images/new_logo.svg').default} alt="" className='sm:w-[180px] w-[160px] mx-auto' /> */}
            <img
              src={require("../../assets/images/WebLogo.webp")}
              className="md:w-[175px] w-[120px] md:h-[40px] h-[30px] mx-auto"
              alt=""
            />
          </Link>
          <div className="flex justify-between items-center h-full flex-grow border-b-2 border-[#b3b3b31a]">
            <div className="flex items-center lg:justify-between justify-end lg:gap-0 gap-5 w-full">
              <div className="flex sm:pr-8 items-center gap-4 lg:order-1 order-2 sm:ps-8">
                {
                  tooglesidebar ? (<button className='bg-none border-none outline-none lg:hidden block' onClick={() => setTooglesidebar(false)}>
                    <IconClose className="w-[20px] h-[20px] text-white" />
                  </button>) : (<button className='bg-none border-none outline-none lg:hidden block' onClick={() => setTooglesidebar(!tooglesidebar)}>
                    <IconMenu className="w-[20px] h-[20px] text-white" />
                  </button>)
                }
                <h5 className='text-white font-600 lg:block hidden text-[22px]'>{capitalizedSegment == 'Dashboard' ? t('Wallet') : capitalizedSegment == 'Select-address' ? "Select Address" : capitalizedSegment == 'Switch-account' ? t('Change_Wallet') :
                  capitalizedSegment == 'Google-auth' ? "Google Authentication" : capitalizedSegment == 'Support-detail' ? "Support Detail" : capitalizedSegment == 'Active-coins' ? "Active coins" : capitalizedSegment == 'Payment' ? t('Payment_Link') : capitalizedSegment == 'Create-api' ? "Create Api" :
                    t(capitalizedSegment)}</h5>
              </div>
              <div className="flex items-center md:gap-8 gap-4 lg:order-2 order-1 lg:pr-[100px]">
                <div className={cn(iconButtonVariants({ variant: "outline", className: "rounded-full cursor-pointer" }))} onClick={() => navigate("/dashboard/notification")}>
                  <img src={require('../../assets/images/Notification.svg').default} alt="" />
                </div>
                <Menu as="div" className="relative border border-white rounded-full md:p-[2px_12px] p-0 overflow-hidden">
                  <Menu.Button aria-label="button" role="button" className="md:flex items-center justify-center gap-4 hidden">
                    <div className='sm:w-[32px] sm:h-[32px] w-[32px] h-[32px] rounded-full overflow-hidden flex-shrink-0'>
                      {!user?.profile == null ? (
                        <img src={files(user?.profile, 'image')} alt="" className='w-[100%] h-[100%] object-cover object-center' />
                      ) : (
                        <div className="w-[100%] h-[100%] flex items-center justify-center bg-linear-horizontal text-white font-bold text-2xl">
                          {user?.name ? user?.name?.split(' ').slice(0, 2).map(name => name.slice(0, 1).toUpperCase()) : user?.email?.slice(0, 1)?.toUpperCase()}
                        </div>
                      )}
                    </div>
                    <div className="md:block hidden text-start">
                      <p className='text-14 text-white font-500 max-w-[175px] truncate'>
                        {user?.name}
                      </p>
                      <p className='text-12 text-gray'>{user?.email}</p>
                    </div>
                  </Menu.Button>
                  <Menu.Button aria-label="button" role="button" className="flex items-center justify-center gap-4 md:hidden h-[40px] w-[40px] bg-theme text-white font-bold text-2xl">
                    {user?.name?.split(' ').slice(0, 2).map(name => name.slice(0, 1).toUpperCase())}
                  </Menu.Button>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </header >
    </>
  )
}

export default DashHeader
import React from "react";

const IconDocument = (props) => {
  return (
    <svg {...props} viewBox="0 0 34 34" fill="none">
      <path
        d="M12.7497 25.1454C12.1689 25.1454 11.6872 24.6637 11.6872 24.0829V18.147L10.6672 19.167C10.2564 19.5779 9.57638 19.5779 9.16555 19.167C8.75471 18.7562 8.75471 18.0762 9.16555 17.6654L11.9989 14.832C12.2964 14.5345 12.7639 14.4354 13.1605 14.6054C13.5572 14.7612 13.8122 15.1579 13.8122 15.5829V24.0829C13.8122 24.6637 13.3305 25.1454 12.7497 25.1454Z"
        fill="currentcolor"
      />
      <path
        d="M15.5837 19.4792C15.3145 19.4792 15.0454 19.38 14.8329 19.1675L11.9995 16.3342C11.5887 15.9234 11.5887 15.2434 11.9995 14.8325C12.4104 14.4217 13.0904 14.4217 13.5012 14.8325L16.3345 17.6659C16.7454 18.0767 16.7454 18.7567 16.3345 19.1675C16.122 19.38 15.8529 19.4792 15.5837 19.4792Z"
        fill="currentcolor"
      />
      <path
        d="M21.2507 32.2288H12.7507C5.05815 32.2288 1.77148 28.9422 1.77148 21.2497V12.7497C1.77148 5.05717 5.05815 1.77051 12.7507 1.77051H19.834C20.4148 1.77051 20.8965 2.25217 20.8965 2.83301C20.8965 3.41384 20.4148 3.89551 19.834 3.89551H12.7507C6.21982 3.89551 3.89648 6.21884 3.89648 12.7497V21.2497C3.89648 27.7805 6.21982 30.1038 12.7507 30.1038H21.2507C27.7815 30.1038 30.1048 27.7805 30.1048 21.2497V14.1663C30.1048 13.5855 30.5865 13.1038 31.1673 13.1038C31.7482 13.1038 32.2298 13.5855 32.2298 14.1663V21.2497C32.2298 28.9422 28.9432 32.2288 21.2507 32.2288Z"
        fill="currentcolor"
      />
      <path
        d="M31.1673 15.2287H25.5007C20.6557 15.2287 18.7715 13.3445 18.7715 8.49954V2.83287C18.7715 2.40787 19.0265 2.0112 19.4232 1.85537C19.8198 1.68537 20.2732 1.78454 20.5848 2.08204L31.9182 13.4154C32.2157 13.7129 32.3148 14.1804 32.1448 14.577C31.9748 14.9737 31.5923 15.2287 31.1673 15.2287ZM20.8965 5.39704V8.49954C20.8965 12.1545 21.8457 13.1037 25.5007 13.1037H28.6032L20.8965 5.39704Z"
        fill="currentcolor"
      />
    </svg>
  );
};

export default IconDocument;

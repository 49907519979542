import React from 'react';

const IconMsg = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <g clip-path="url(#clip0_3206_537)">
                <path d="M23.3429 24.5072C22.3477 25.1707 21.1918 25.5214 20 25.5214C18.8083 25.5214 17.6523 25.1707 16.6572 24.5072L0.266328 13.5797C0.175844 13.5192 0.0870347 13.4562 0 13.3908L0 31.2968C0 33.3498 1.66602 34.9791 3.68227 34.9791H36.3177C38.3706 34.9791 39.9999 33.3131 39.9999 31.2968V13.3907C39.9127 13.4563 39.8237 13.5194 39.733 13.58L23.3429 24.5072Z" fill="currentColor" />
                <path d="M1.56641 11.6295L17.9573 22.5572C18.5777 22.9708 19.2888 23.1776 19.9999 23.1776C20.7111 23.1776 21.4223 22.9708 22.0427 22.5572L38.4336 11.6295C39.4145 10.976 40 9.88224 40 8.70177C40 6.672 38.3487 5.02075 36.319 5.02075H3.68102C1.65133 5.02083 1.93758e-06 6.67208 1.93758e-06 8.70372C-0.000606547 9.28279 0.142112 9.85301 0.415427 10.3635C0.688743 10.874 1.08416 11.3089 1.56641 11.6295Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_3206_537">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}

export default IconMsg;

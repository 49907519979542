import { RadioGroup } from "@headlessui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import IconDone from "../../assets/svg/IconDone";
import { Button } from "../../components/ui/Button";
import SuccessMessageModal from "../../components/model/SuccessMessageModal";
import AdvanceFileUpload from "../../components/ui/AdvanceFileUpload";
import { useLocation, useNavigate } from "react-router-dom";
import { authStore } from "../../context/AuthProvider";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { AUTH_API_URL, USER_API_URL } from "../../api/api";
import { useSnackbar } from "notistack";
import SumsubWebSdk from '@sumsub/websdk-react'
import Loader from "../../components/ui/Loader";

const Kyc = () => {
  const [sessionId, setSessionId] = useState("");
  const [plan, setPlan] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const { pathname } = useLocation();
  const { user, set } = authStore();
  const axiosInstance = useAxiosPrivate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate();



  const handleOpenSuccessModal = () => {
    setOpenSuccessModal(true)
  }
  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false)
  }



  const [accessToken, setAccessToken] = useState("");
  async function fetchNewAccessToken() {
    try {
      const res = await axiosInstance.get(USER_API_URL.generateKycToken);
      if (res) {
        setIsLoading(false);
        const newAccessToken = JSON.parse(res?.data)
        setAccessToken(newAccessToken?.token)
      }
    } catch (error) {
      console.log("error", error);
    }

  }

  const memoizedFetchNewAccessToken = useMemo(() => fetchNewAccessToken, []);

  useEffect(() => {
    memoizedFetchNewAccessToken();
  }, [memoizedFetchNewAccessToken]);

  const updateAccessToken = useCallback(async () => {
    await memoizedFetchNewAccessToken();
  }, []);

  const errorHandler = useCallback(async (error) => {
    if (error?.code === "initialization-error") {
      await memoizedFetchNewAccessToken();
    }
  }, [!accessToken]);


  const onApplicantStatusChanged = useCallback((data) => {
  })

  return (
    <div className="p-12 px-20 bg-dark h-full">
      <div className="bg-card h-full flex flex-col rounded-[20px] border border-light-100">
        <p className="text-white p-8 border-b border-light-100">
          KYC Verification
        </p>
        <div className="md:p-12 p-10 flex-grow">
          <p className="text-white mb-4">Proof Of Identity</p>
          {
            isLoading ? <Loader /> : (

              <SumsubWebSdk
                accessToken={accessToken}
                expirationHandler={updateAccessToken}
                onError={errorHandler}
                onApplicantStatusChanged={onApplicantStatusChanged}
              />
            )
          }
        </div>

      </div>
      <SuccessMessageModal
        open={openSuccessModal}
        handleClose={handleCloseSuccessModal}
        message={"Submitted Successfully"}
        message2={"Thank you for submitting your KYC documents, we are review your documents with in 2 business days."}
        messageClass="w-[80%] mb-8"
      />
    </div>
  );
};

export default Kyc;

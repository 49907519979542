import React from "react";

const BuyCrypto = () => {
  return (
    <section className="container relative bg-[#7684ED] lg:rounded-full sm:rounded-[80px] lg:mt-[200px] md:mt-[130px] sm:mt-[90px] mt-[30px] lg:mb-[100px] mb-[60px] sm:px-[25px] w-full h-full" data-aos="zoom-in" data-aos-duration="1500">
      <div className="relative z-[1]">
        <div className="grid sm:grid-cols-2 justify-items-center lg:gap-8">
          <div className="max-w-[420px] md:py-[40px] py-[40px]">
            <h3 className="lg:text-32 text-24 font-700 mb-6 text-white">
              Buy crypto with your local currency
            </h3>
            <p className="text-14 font-600 text-white">
              Start your crypto journey using your credit/debit card, bank
              account, Apple Pay, or Google Pay
            </p>
          </div>
          <div className="relative w-full">
            <img
              src={require("../../../assets/images/BuyCrypto.webp")}
              className="sm:absolute bottom-0 sm:w-auto w-full sm:mx-0 mx-auto"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BuyCrypto;

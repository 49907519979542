import React from 'react';

const IconLess = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <path d="M32.4207 18.0469H7.58008C6.50117 18.0469 5.62695 18.9211 5.62695 20C5.62695 21.0789 6.50117 21.9531 7.58008 21.9531H32.4207C33.4996 21.9531 34.3738 21.0789 34.3738 20C34.3738 18.9211 33.4996 18.0469 32.4207 18.0469Z" fill="currentColor" />
        </svg>

    );
}

export default IconLess;

import React from "react";
import IconSafe from "../../../assets/svg/IconSafe";
import IconTrusted from "../../../assets/svg/IconTrusted";
import IconDiamond from "../../../assets/svg/IconDiamond";
import { t } from "i18next";

const AboutUsSecureSection = () => {
  return (
    <section className="container md:py-24 py-14">
      <div className="grid md:grid-cols-2 grid-cols-1 items-center md:gap-10 gap-20">
        <div className="text-center">
          <div className="text-start lg:max-w-[70%] w-full lg:mx-auto">
            <h6 className="text-32 font-600 text-card md:pb-[30px] pb-[16px]"><span className="text-greenweb">{t('Secure_Manage')}</span>{t('and')}<span className="text-greenweb">{t('swap')}</span> {t('crypto_currency')}</h6>
            <p className="text-16 font-400 text-light-500">{t('At_Oppi_we_pour_our_hearts_into_every_detail_from_pixel_perfect_icons_to_subtle_sounds_creating_a_cryptocurrency_experience_that_works_for_everyone')}</p>
      
            <div className="flex items-center gap-10 pt-[16px]">
              <div className="flex flex-col justify-center items-center gap-5">
                <div className="flex items-center justify-center h-[44px] w-[44px] border border-dark rounded-full group hover:bg-linear-horizontal hover:border-greenweb" data-aos="fade-down" data-aos-duration="1500"><IconSafe className="w-[24px] text-dark group-hover:text-white" /></div>
                <p className="text-16 font-500 text-card">{t('Safe')}</p>
              </div>
              <div className="flex flex-col justify-center items-center gap-5">
                <div className="flex items-center justify-center h-[44px] w-[44px] border border-dark rounded-full group hover:bg-linear-horizontal hover:border-greenweb" data-aos="fade-up" data-aos-duration="1500"><IconTrusted className="w-[24px] text-dark group-hover:text-white" /></div>
                <p className="text-16 font-500 text-card">{t('Trusted')}</p>
              </div>
              <div className="flex flex-col justify-center items-center gap-5">
                <div className="flex items-center justify-center h-[44px] w-[44px] border border-dark rounded-full group hover:bg-linear-horizontal hover:border-greenweb" data-aos="fade-down" data-aos-duration="1500"><IconDiamond className="w-[24px] text-dark group-hover:text-white" /></div>
                <p className="text-16 font-500 text-card">{t('Reliable')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <img
            src={require("../../../assets/images/CryptoExchange.webp")}
            className="xs:w-[60%] w-[80%] lg:max-w-[480px] md:max-w-[380px] mx-auto"
            alt=""
            data-aos="zoom-in" data-aos-duration="1500"
          />
        </div>
      </div>
    </section>
  );
};

export default AboutUsSecureSection;

import React, { useEffect } from 'react';
import IconSecurity from "../../../assets/svg/IconSecurity";
import IconBackup from "../../../assets/svg/IconBackup";
import IconGoogle from "../../../assets/svg/IconGoogle";
import IconEmail from "../../../assets/svg/IconEmail";
import IconFingerPrint from "../../../assets/svg/IconFingerPrint";
import IconBackupCloud from "../../../assets/svg/IconBackupCloud";
import { Button } from '../../ui/Button';
import { t } from 'i18next';
// import Aos from 'aos';
// import 'aos/dist/aos.css';

const PrivateAndSecure = () => {
    // useEffect(() => {
    //     setTimeout(() => {
    //         Aos.init();
    //     }, 1000);
    //   }, [])
    return (
        <section className='container'>
            <div className="text-center md:max-w-[60%] max-w-[80%] mx-auto" data-aos="fade-down" data-aos-duration="1500">
                <h6 className='text-card text-40 font-600 mb-2'>{t('Stay_private_and_secure')}</h6>
                <p className='text-16 font-400 text-light-500'>{t('Rest_easy_knowing_that_our_privacy_and_security_measures_keep_you_in_control_of_your_data_and_digital_assets_while_also_keeping_them_safe')}</p>
            </div>
            <div className="grid md:grid-cols-2 items-center" >
                <div className="ms-auto">
                    <img src={require("../../../assets/images/secure_pic.webp")} alt="" className="" data-aos="zoom-in" data-aos-duration="1500" />
                </div>
                <div className="text-center" >
                    <div className="md:text-start text-center lg:max-w-[400px] w-full lg:mx-auto">
                        <h6 className='text-32 font-600'>{t('True_ownership_of_your')} <span className='text-greenweb'>{t('crypto_assets')}</span></h6>
                        <div className="flex md:justify-start justify-center gap-6 my-8">
                            <div className="flex items-center justify-center h-[44px] w-[44px] border border-dark rounded-full group hover:bg-linear-horizontal hover:border-greenweb transition-all duration-500" data-aos="fade-down" data-aos-duration="1500">
                                <IconSecurity className="w-[24px] aspect-square group-hover:text-white transition-all duration-500" />
                            </div>
                            <div className="flex items-center justify-center h-[44px] w-[44px] border border-dark rounded-full group hover:bg-linear-horizontal hover:border-greenweb transition-all duration-500" data-aos="fade-up" data-aos-duration="1500">
                                <IconBackup className="w-[24px] aspect-square group-hover:text-white transition-all duration-500" />
                            </div>
                            <div className="flex items-center justify-center h-[44px] w-[44px] border border-dark rounded-full group hover:bg-linear-horizontal hover:border-greenweb transition-all duration-500" data-aos="fade-down" data-aos-duration="1500">
                                <IconGoogle className="w-[24px] aspect-square group-hover:text-white transition-all duration-500" />
                            </div>
                            <div className="flex items-center justify-center h-[44px] w-[44px] border border-dark rounded-full group hover:bg-linear-horizontal hover:border-greenweb transition-all duration-500" data-aos="fade-up" data-aos-duration="1500">
                                <IconEmail className="w-[24px] aspect-square group-hover:text-white transition-all duration-500" />
                            </div>
                            <div className="flex items-center justify-center h-[44px] w-[44px] border border-dark rounded-full group hover:bg-linear-horizontal hover:border-greenweb transition-all duration-500" data-aos="fade-down" data-aos-duration="1500">
                                <IconFingerPrint className="w-[24px] aspect-square group-hover:text-white transition-all duration-500" />
                            </div>
                            <div className="flex items-center justify-center h-[44px] w-[44px] border border-dark rounded-full group hover:bg-linear-horizontal hover:border-greenweb transition-all duration-500" data-aos="fade-up" data-aos-duration="1500">
                                <IconBackupCloud className="w-[24px] aspect-square group-hover:text-white transition-all duration-500" />
                            </div>
                        </div>
                        <p className='text-14 font-400 mb-8'>{t('We_secure_your_wallet_but_dont_control_or_have_access_to_your_private_keys_or_secret_phrase_only_you_do')}</p>
                        <Button variant='outline'>{t('Get_Started')} </Button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PrivateAndSecure;

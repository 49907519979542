import React from 'react';

const IconShow = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 9.17C10.4342 9.17 9.16998 10.4342 9.16998 12C9.16998 13.5658 10.4342 14.83 12 14.83C13.5658 14.83 14.83 13.5658 14.83 12C14.83 10.4342 13.5658 9.17 12 9.17ZM7.66998 12C7.66998 9.60578 9.60577 7.67 12 7.67C14.3942 7.67 16.33 9.60578 16.33 12C16.33 14.3942 14.3942 16.33 12 16.33C9.60577 16.33 7.66998 14.3942 7.66998 12Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.25752 8.99697C4.64844 5.2386 8.15205 2.97 12 2.97C15.848 2.97 19.3516 5.23859 21.7425 8.99693C22.2877 9.85135 22.535 10.9459 22.535 11.995C22.535 13.044 22.2877 14.1386 21.7425 14.993C19.3516 18.7514 15.848 21.02 12 21.02C8.15188 21.02 4.64813 18.7512 2.25721 14.9925C1.71223 14.1382 1.46503 13.0438 1.46503 11.995C1.46503 10.946 1.71233 9.85138 2.25752 8.99697ZM12 4.47C8.78817 4.47 5.71192 6.3612 3.52285 9.80254L3.52222 9.80353C3.16763 10.359 2.96503 11.1542 2.96503 11.995C2.96503 12.8358 3.16763 13.631 3.52222 14.1865L3.52285 14.1875C5.71192 17.6288 8.78817 19.52 12 19.52C15.2119 19.52 18.2881 17.6288 20.4772 14.1875L20.4778 14.1865C20.8324 13.631 21.035 12.8358 21.035 11.995C21.035 11.1542 20.8324 10.359 20.4778 9.80353L20.4772 9.80254C18.2881 6.3612 15.2119 4.47 12 4.47Z" fill="currentColor" />
        </svg>
    );
}

export default IconShow;

import { createContext, useEffect, useState } from "react";
import socketio from "socket.io-client";
import { authStore } from "./AuthProvider";


export const SocketContext = createContext();

const SocketUrl = process.env.REACT_APP_SOCKET_URL

const SocketContextProvider = ({ children }) => {
    const [tempUser, setTempUser] = useState();
    const [userEmail, setUserEmail] = useState();
    const [connected, setConnected] = useState(false);
    const [socketClient, setSocketClient] = useState();
    const [pathName, setPathName] = useState({});
    const setAccessToken = authStore((state) => state.setAccessToken)
    const setUser = authStore((state) => state.setUser)
    const setHasEncyptionWordKey = authStore((state) => state.setHasEncyptionWordKey)
    const setIsAuthenticated = authStore((state) => state.setIsAuthenticated)
    const [reSendOtp, setReSendOtp] = useState(0);

    useEffect(() => {
        if (tempUser) {
            const initSocket = () => {
                const client = socketio(SocketUrl, {
                    query: {
                        userId: tempUser,
                    },
                    transports: ["websocket"],
                    upgrade: true,
                    reconnection: false,
                    autoConnect: false,
                    forceNew: true,
                });

                client.on('connect', function () {
                    console.log('client connected');
                    setConnected(true);
                });


                setSocketClient(client);

                if (!client.connected) {
                    client.connect();
                }
            };
            initSocket();

            return () => {
                socketClient?.disconnect();
            };
        }
    }, [tempUser]);

    useEffect(() => {
        if (tempUser && pathName || reSendOtp) {
            const data = {
                userId: tempUser,
            };
            // Emit 'verifyUser' event
            socketClient.emit('verifyUser', data);  
            

            // Event listener for 'verifyUser' response
            const handleVerifyUser = (response) => {
                if (response) {
                    setReSendOtp(0)
                    setAccessToken(response?.accessToken)
                    setUser({
                        userId: response.userId,
                        isVerify: response.isVerify,
                        email: response?.email
                    })

                    setHasEncyptionWordKey(response?.hasEncyptionWordKey)
                    if (response?.accessToken) {
                        setIsAuthenticated(true)
                    }
                }
            };

            // Attach the event listener
            socketClient.on('verifyUser', handleVerifyUser);

            // Cleanup the event listener when the component is unmounted
            return () => {
                socketClient.off('verifyUser', handleVerifyUser);
                // setReSendOtp(0)
            };
        }
    }, [connected, reSendOtp]);


    return (
        <SocketContext.Provider value={{ connected, tempUser, setTempUser, socketClient, setPathName, setUserEmail, userEmail, setReSendOtp, reSendOtp }}>
            {children}
        </SocketContext.Provider>
    )
}

export default SocketContextProvider;
import React from "react";

const SlideView = () => {
  return (
    <section className="md:pt-[100px] pt-[40px]">
      <div className="container">
        <div className="grid md:grid-cols-2 md:gap-0 gap-6">
          <div className="max-w-[560px] ms-auto w-full flex justify-start items-center">
            <h5
              className="lg:leading-[45px] leading-[32px] xl:text-18 md:text-16 text-18 font-500 md:w-[80%] md:max-w-[460px] capitalize"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              Oppi Wallet got its start as an early pioneer of key
              infrastructure for the crypto community. We provides an API that
              enabled companies to build on cryptocurrencies. We also have
              provided the most popular and widely used crypto wallet that
              enables anyone anywhere to control their own money.
            </h5>
          </div>
          <div className="w-full max-h-[560px] max-w-[560px]">
            <img
              src={require("../../../assets/images/AboutSlide1.png")}
              className="w-full max-h-[560px] max-w-[560px]"
              alt=""
              data-aos="zoom-in-left"
              data-aos-duration="1500"
            />
          </div>
          <div className="w-full max-h-[560px] max-w-[560px] ms-auto">
            <img
              src={require("../../../assets/images/AboutSlide2.png")}
              className="w-full max-h-[560px] max-w-[560px]"
              alt=""
              data-aos="zoom-in-right"
              data-aos-duration="1500"
            />
          </div>
          <div className="max-w-[560px] flex justify-end items-center">
            <h5
              className="lg:leading-[45px] leading-[32px] xl:text-18 md:text-16 text-18 font-500 md:w-[80%] md:max-w-[460px] capitalize"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              Our team spans the globe and has worked tirelessly to accelerate
              the adoption of crypto and usher in the future of finance. We are
              passionate, relentless, and stoked -and believe that a lean team
              of world-class visionaries can ship products that empower our
              customers and drive the business to new heights.
            </h5>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SlideView;

import React, { forwardRef } from 'react'
import IconDocument from '../../assets/svg/IconDocument'
import { cn } from '../../lib/utils'
import { t } from 'i18next'

const FileUpload = forwardRef(({ errorMsg ,errorClass,handleFileUpload,accept = '.pdf,.png,.jpg,.jpeg'}, ref) => {
    return (
        <div className='mt-4'>
            <label htmlFor="dropzone-file" className='bg-card flex items-center gap-[16px] cursor-pointer p-5 border border-light-400 rounded-[14px]' >
                <div className="icon">
                    <IconDocument className="h-[34px] w-[34px] text-white" />
                </div>
                <div className="">
                    <h5 className='text-white text-14 font-600 mb-1'>{t('Upload_File')}</h5>
                    <p className=' text-dark-gray text-12 font-500'>{t('Upload_PNG_or_JPG_format_only')}</p>
                </div>
            </label>
            <input
                type='file'
                id='dropzone-file'
                className='hidden'
                ref={ref}
                accept={accept}
                onChange={handleFileUpload}
            />
            {errorMsg && <p className={cn('text-12 text-red-500 font-normal', errorClass)} style={{ color: "red", fontSize: '13px', margin: '8px' }}>{errorMsg}</p>}
        </div>
    )
})

export default FileUpload

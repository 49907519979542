import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import IconClose from '../../assets/svg/IconClose'
import IcnCopy from '../../assets/svg/IcnCopy'
import { useSnackbar } from 'notistack'

const TranDetailModal = ({ open, handleClose, tranDetail, cardCurrency,cardStatus }) => {

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all">
                <div className="flex justify-center p-8 bg-card border-b-light-100 border-b">
                  <p className='text-white ms-auto'>Transaction Details</p>
                  <IconClose onClick={handleClose} className="text-white w-[18px] ms-auto cursor-pointer" />
                </div>

                <div className="p-8">
                  <h6 className='text-gray text-14 mb-[8px] flex justify-between'>
                    <span className='text-white text-[14px]'>Recharge</span>
                    <span className='flex items-end text-white text-[14px]'>
                      {parseFloat(tranDetail?.tx_amount).toFixed(2)} {tranDetail?.tx_currency.toUpperCase()}
                    </span>
                  </h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4 flex justify-between'>
                    <span className='text-gray text-[12px]'>{tranDetail?.formattedDate}</span>
                    <span className={`text-[12px] ${tranDetail?.status === 0 ? 'text-[#cfba54]' : tranDetail?.status === 1 ? 'text-[#7684ED]' : 'text-red'} flex items-end`}>
                      {cardStatus[tranDetail?.status]}
                    </span>
                  </p>

                  {/* <div className="flex items-end flex-col"> */}
                  {/* <h5 className='text-white sm:text-14 text-12 uppercase'>{parseFloat(tranDetail?.tx_amount).toFixed(2)} {tranDetail?.tx_currency}</h5> */}
                  {/* <p className={`text-[12px] ${tranDetail?.status == 0 ? 'text-[#cfba54]' : tranDetail?.status == 'success' ? 'text-green' : 'text-red'}`}>{parseFloat(tranDetail?.tx_amount).toFixed(2)} {tranDetail.tx_currency.toUpperCase()}</p> */}
                  {/* </div> */}
                  { parseFloat(tranDetail?.fee).toFixed(2) > 0 &&
                    <>
                      <h6 className='text-white text-[14px] mb-[8px]'>Fee</h6>
                      <p className='text-white text-[12px] pb-4 border-b border-b-light-200 mb-4'>{ parseFloat(tranDetail?.fee).toFixed(2)} {cardCurrency?.toUpperCase()}</p>
                    </>}
                  {tranDetail?.description &&
                    <>
                      <h6 className='text-white text-[14px] mb-[8px]'>Description</h6>
                      <p className='text-white text-[12px] pb-4 border-b border-b-light-200 mb-4'>{tranDetail?.description}</p>
                    </>}
                  {tranDetail?.credit > 0 &&
                    <>
                      <h6 className='text-white text-[14px] mb-[8px]'>Credit</h6>
                      <p className='text-white text-[12px] pb-4 border-b border-b-light-200 mb-4'>{parseFloat(tranDetail?.credit).toFixed(2)} {cardCurrency?.toUpperCase()}</p>
                    </>}
                  {tranDetail?.debit > 0 &&
                    <>
                      <h6 className='text-white text-[14px] mb-[8px]'>Debit</h6>
                      <p className='text-white text-[12px] pb-4 border-b border-b-light-200 mb-4'>{parseFloat(tranDetail?.debit).toFixed(2)} {cardCurrency?.toUpperCase()}</p>
                    </>}

                  <h6 className='text-white text-[14px] mb-[8px]'>Tx Amount</h6>
                  <p className='text-white text-[12px] pb-4 border-b border-b-light-200 mb-4'>{parseFloat(tranDetail?.tx_amount).toFixed(2)} {cardCurrency?.toUpperCase()}</p>

                  <h6 className='text-white text-[14px] mb-[8px]'>Tx Id</h6>
                  <p className='text-white text-[12px] pb-4 border-b border-b-light-200 mb-4'>{tranDetail?.tx_id}</p>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default TranDetailModal

import React from "react";
import { Button } from "../../ui/Button";
import IconArrowRight from "../../../assets/svg/IconArrowRight";

const MoneySendReceive = () => {
  return (
    <section className="lg:pt-[100px] pt-[40px]">
      <div className="container">
        <div className="grid md:grid-cols-2 items-center md:gap-0 gap-8">
          <img
            src={require("../../../assets/images/StoreMoneyTransaction.webp")}
            className="w-[75%] mx-auto md:max-w-full max-w-[400px]"
            alt=""
            data-aos="zoom-in" data-aos-duration="2500"
          />
          <div className="sm:max-w-[80%] mx-auto" data-aos="fade-left" data-aos-duration="2000">
            <h5 className="sm:text-32 md:text-left text-center capitalize font-700 mb-8 sm:max-w-[60%]">
              Money send and Receive made easy
            </h5>
            <p className="text-14 font-500 md:text-left text-center capitalize md:mb-20 mb-12">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </p>
            <Button variant="filled" className="flex gap-4 text-14 md:mx-0 mx-auto font-700 items-center w-max text-white border-0">
              Download Now <IconArrowRight className="w-[24px] text-white" />
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MoneySendReceive;

import React from 'react'

const IcnBell = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.61001 8.66C4.61001 4.56579 7.9258 1.25 12.02 1.25C16.0946 1.25 19.4289 4.58574 19.44 8.65795L19.44 8.66L19.44 10.76C19.44 11.0064 19.4942 11.3614 19.5969 11.7349C19.6997 12.1089 19.8345 12.441 19.9622 12.6525L19.9634 12.6546L21.2327 14.7735C21.2328 14.7736 21.2329 14.7738 21.233 14.774C21.4622 15.1551 21.6028 15.5829 21.6444 16.0257C21.6861 16.4687 21.6278 16.9154 21.4737 17.3328C21.3195 17.7502 21.0736 18.1276 20.754 18.4372C20.4345 18.7468 20.0494 18.9807 19.6273 19.1215L19.6267 19.1217C14.6918 20.7628 9.35827 20.7628 4.42335 19.1217L4.42284 19.1215C3.50673 18.8161 2.82732 18.1773 2.52398 17.3561C2.22076 16.5354 2.32084 15.6072 2.81559 14.7763L2.81662 14.7746L4.08662 12.6546L4.08787 12.6525C4.21604 12.4401 4.35075 12.1098 4.45325 11.7381C4.55563 11.3669 4.61001 11.0118 4.61001 10.76V8.66ZM12.02 2.75C8.75422 2.75 6.11001 5.39421 6.11001 8.66V10.76C6.11001 11.1882 6.02439 11.6831 5.89927 12.1369C5.77439 12.5897 5.59432 13.059 5.37279 13.4264C5.37258 13.4268 5.37236 13.4271 5.37215 13.4275L4.10443 15.5437C4.10425 15.544 4.10408 15.5443 4.1039 15.5446C3.81915 16.0233 3.79934 16.4799 3.93104 16.8363C4.0627 17.1927 4.37329 17.5239 4.89718 17.6985C9.52446 19.2371 14.5254 19.2372 19.1527 17.6985C19.3616 17.6289 19.5522 17.5131 19.7103 17.3599C19.8685 17.2067 19.9902 17.0198 20.0665 16.8132C20.1428 16.6067 20.1717 16.3856 20.151 16.1663C20.1304 15.9471 20.0608 15.7352 19.9473 15.5465L19.9466 15.5454L18.6779 13.4275C18.6777 13.4272 18.6775 13.4269 18.6773 13.4266C18.4552 13.0582 18.2752 12.5857 18.1506 12.1326C18.0258 11.6786 17.94 11.1836 17.94 10.76V8.66103C17.9306 5.41371 15.2651 2.75 12.02 2.75ZM12 5.69C12.4142 5.69 12.75 6.02579 12.75 6.44V9.77C12.75 10.1842 12.4142 10.52 12 10.52C11.5858 10.52 11.25 10.1842 11.25 9.77V6.44C11.25 6.02579 11.5858 5.69 12 5.69Z" fill="currentColor" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.42001 18.82C9.42001 19.5201 9.71301 20.1723 10.1803 20.6397C10.6477 21.107 11.2999 21.4 12 21.4C13.4158 21.4 14.58 20.2358 14.58 18.82H16.08C16.08 21.0642 14.2442 22.9 12 22.9C10.8801 22.9 9.85235 22.433 9.11968 21.7003C8.38701 20.9677 7.92001 19.9399 7.92001 18.82H9.42001Z" fill="currentColor" />
        </svg>

    )
}

export default IcnBell

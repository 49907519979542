import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import IconClose from '../../assets/svg/IconClose'
import { Button } from '../ui/Button'
import Input from '../ui/Input'
import * as Yup from "yup";
import { useFormik, FormikProvider, ErrorMessage, Form } from 'formik';
import { cn } from '../../lib/utils'
import { QueryClient } from 'react-query'
import { axiosInstance } from '../../api/axiosInstance'
import { USER_API_URL } from '../../api/api'
import { enqueueSnackbar } from 'notistack'
import { authStore } from '../../context/AuthProvider'

const SetCardLimitModal = ({ userCardListApi, open, handleClose, detail, setDetail }) => {
  const [Loading, setLoading] = useState(false);


  const addCardLimitValidation = Yup.object().shape({
    max_amount_daily: Yup.number()
      .typeError("Daily amount must be a number") // Validates that the input is a number
      .required("Daily amount is required") // Ensures the field is not empty
      .positive("Daily amount must be a positive number") // Ensures it's a positive number
      .moreThan(0, "Daily amount must be greater than 0") // Ensures it's greater than 0
      .test('is-greater-than-single', 'Daily amount must be greater than single amount', function (value) {
        const { max_amount_single } = this.parent;
        return value > Number(max_amount_single); // Daily must be greater than single
      }),

    max_amount_monthly: Yup.number()
      .typeError("Monthly amount must be a number")
      .required("Monthly amount is required")
      .positive("Monthly amount must be a positive number")
      .moreThan(0, "Monthly amount must be greater than 0")
      .test('is-greater-than-daily-and-single', 'Monthly amount must be greater than daily and single amounts', function (value) {
        const { max_amount_daily, max_amount_single } = this.parent;
        return value > Number(max_amount_daily) && value > Number(max_amount_single); // Monthly must be greater than daily and single
      }),

    max_amount_single: Yup.number()
      .typeError("Single amount must be a number")
      .required("Single amount is required")
      .positive("Single amount must be a positive number")
      .moreThan(0, "Single amount must be greater than 0")
      .test('is-less-than-daily', 'Single amount must be less than daily amount', function (value) {
        const { max_amount_daily } = this.parent;
        return value < Number(max_amount_daily); // Single must be less than daily
      }),
  });

  const formik = useFormik({
    initialValues: {
      // card_id: detail?.cardId ?? "",
      max_amount_daily: detail?.maxAmountDaily ?? '',
      max_amount_monthly:detail?.maxAmountMonthly ?? "",
      max_amount_single: detail?.maxAmountSingle ?? "",

    },
    validationSchema: addCardLimitValidation,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        values.card_id = detail?.cardId
        const response = await axiosInstance.post(USER_API_URL.setCardLimit, values);
        if (response) {
          setLoading(false);
          // userCardListApi();
          enqueueSnackbar('Your card limit set successfully.', {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 1000,
          });
          const updatedDetail = {
            ...detail,
            maxAmountDaily: values.max_amount_daily,
            maxAmountMonthly: values.max_amount_monthly,
            maxAmountSingle: values.max_amount_single,
          };
          setDetail(updatedDetail);
          resetForm();
          handleClose();

        }
      } catch (error) {
        setLoading(false);
        if (error?.response?.data.errors) {
          const errors = error?.response?.data.errors;
          Object.keys(errors).map(function (key) {
            enqueueSnackbar(errors[key], {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        }
      }
    },
  });

  useEffect(() => {
    if (detail) {
      formik.setValues({
        max_amount_daily: detail.maxAmountDaily ?? '',
        max_amount_monthly: detail.maxAmountMonthly ?? '',
        max_amount_single: detail.maxAmountSingle ?? '',
      });
    }
  }, [detail, formik.setValues]);

 

  const {
    handleSubmit,
    getFieldProps,
    errors,
    touched,
    resetForm,
    setValues,
  } = formik;

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all">
                <div className="flex justify-center p-8 bg-card border-b-light-100 border-b">
                  <p className='text-white ms-auto'>Set Card Limit</p>
                  <IconClose onClick={handleClose} className="text-white w-[18px] ms-auto cursor-pointer" />
                </div>
                <div className="p-12">
                  <FormikProvider value={formik}>
                    <Form onSubmit={handleSubmit} className="theme-form" autoComplete="off">
                      <div className="pb-[16px]">
                        <Input type="number" lable="Single Limit" {...getFieldProps('max_amount_single')} placeholder="Enter Single Limit" autoComplete="off" errorMsg={errors.max_amount_single && touched.max_amount_single ? errors.max_amount_single : null} />
                      </div>
                      <div className="pb-[16px]">
                        <Input type="number" lable="Daily Limit" {...getFieldProps('max_amount_daily')} placeholder="Enter Daily Limit" autoComplete="off" errorMsg={errors.max_amount_daily && touched.max_amount_daily ? errors.max_amount_daily : null} />
                      </div>
                      <div className="pb-[16px]">
                        <Input type="number" lable="Monthly Limit" {...getFieldProps('max_amount_monthly')} placeholder="Enter Monthly Limit" autoComplete="off" errorMsg={errors.max_amount_monthly && touched.max_amount_monthly ? errors.max_amount_monthly : null} />
                      </div>
                      {/* <Button type="submit" className="w-full text-[14px] text-white mt-12">Set Limit</Button> */}
                      <Button type="submit" className={`w-full text-[14px] text-white mt-12 ${Loading ? 'opacity-50 cursor-not-allowed' : ''}`}>{Loading ? "loading" : "Set Limit"}</Button>
                    </Form></FormikProvider>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default SetCardLimitModal

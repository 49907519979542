import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../ui/Button";
import { useTranslation } from "react-i18next";
import Logo from "../../../assets/images/WebLogo.webp"

const LetsTalk = () => {
  const navigate = useNavigate();
  const {t} =useTranslation();

  const handleNavigation = () => {
    navigate('/support')
  }
  return (
    <section className="bg-dark py-20">
      <div className="container text-center max-w-[80%]" data-aos="zoom-out-up" data-aos-duration="1500">
        <Link to={"/"}>
          <img
            src={Logo}
            className="sm:w-[175px] w-[150px] h-[40px] mx-auto"
            alt=""
          />
        </Link>
        <h3 className="text-white mt-12 mb-3">{t('Lets_Talk')}</h3>
        <p className="text-white font-400 mb-12">{t('curious_to_see_how_adjust_can_help_grow_your_app_Exponentially')}</p>
        <Button className="bg-linear" variant="filled" onClick={()=>handleNavigation()} >{t('Request_Demo')}</Button>
      </div>
    </section>
  );
};

export default LetsTalk;


import * as Yup from "yup";
import { t } from 'i18next'

export const addAddressSchema = (t) => Yup.object().shape({
    // coin: Yup.string()
    //     .required(t('Please_select_a_coin')),
    address: Yup.string().required(t('Address_is_required')),
    addressOrigin: Yup.string()
        .required(t('Please_select_a_address_origin')),
    walletLable: Yup.string()
        .trim()
        .required(t('Wallet_Lable_is_required'))
        .max(20, t('Wallet_Lable_must_be_at_most_20_characters')),
});


export const sendTransactionSchema = (t, selectedCoin) => Yup.object().shape({
    coin: Yup.string().required(t('Please_select_currency')),
    address: Yup.string().required(t('Please_enter_or_select_address_from_the_address_book')),
    amount: Yup.number().required(t('Please_enter_amount_to_transfer')),
    // tag: Yup.string().when('selectedCoin', {
    //     is: (selectedCoin) => {
    //         // Check if selectedCoin exists and its name is "XRP"
    //         return selectedCoin && selectedCoin.name === "XRP";
    //     },
    //     then: Yup.string().required('Meta tag is required').nullable(),
    //     otherwise: Yup.string().nullable(),
    // }),
});


export const supportTicketSchema = (t) => Yup.object().shape({
    title: Yup.string().required(t('Title_is_required')).max(100, t('Title_must_be_less_then_100_characters')),
    message: Yup.string().required(t("Message_is_required")).max(500, t('Message_must_be_less_then_500_characters')),
    // file : Yup.string().required('File is required'),
})

export const paymentLinkchema = (t) => Yup.object().shape({
    coin: Yup.string().required(t('Please_select_currency')),
    amount: Yup.number().moreThan(0, 'Amount should be greater than 0').required(t('Please_enter_amount')),
    expireTime: Yup.string().required(t('Select_expire_time')),

})

export const contactUsSchema = Yup.object().shape({
    name: Yup.string().required(t("Name_is_required"))
        .trim()
        .matches(/^[a-zA-Z][a-zA-Z\s]*$/, 'Name must contain only alphabetic characters and spaces')
        .min(1, 'Name must be at least 1 character')
        .max(30, 'Name must be at most 30 characters'),
    email: Yup.string().email(t('Invalid_email_address')).required(t('Email is required')),
    message: Yup.string()
        .trim()
        .required(t('Message_is_required'))
        .min(1, t('Message_grater_be_less_then_1_characters'))
        .max(500, t('Message_must_be_less_then_500_characters')),
    // email: Yup.string().email(t('Invalid_email_address')).required('Please enter email'),
})

export const profileDetailValidation = Yup.object().shape({
    name: Yup.string().required(t("Name_is_required"))
        .trim()
        .matches(/^[a-zA-Z][a-zA-Z\s]*$/, 'Name must contain only alphabetic characters and spaces'),
    user_name: Yup.string()
        .min(3, t('Username_must_be_at_least_3_characters'))
        .max(15, t('Username cannot exceed 15 characters'))
        .matches(/^[a-zA-Z0-9_]*$/, 'Only alphanumeric characters and underscores are allowed')
        .required(t('UserName_is_required')),


    // email: Yup.string().email(t('Invalid_email_address')).required('Please enter email'),
})

const ipv4Pattern = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
const ipv6Pattern = /^([a-f0-9]{1,4}:){7}[a-f0-9]{1,4}$/i;
export const createAppSchema = (t) => Yup.object().shape({
    title: Yup.string().required(t('Title_is_required')).min(1, t('Title_must_be_at_least_1_character'))
        .max(100, t('Title_must_be_less_then_100_characters')),
    whitelistedIPAddress: Yup.string()
        // .required(t('Invalid_IP_address_format'))
        .test('valid-ips', t('One_or_more_IP_addresses_are_invalid'), value => {
            if (!value) return true;
            const ipAddresses = value?.split(',').map(ip => ip?.trim());
            return ipAddresses?.every(ip => {
                return ipv4Pattern.test(ip) || ipv6Pattern.test(ip);
            });
        }),
    status: Yup.string().required(t('Please_select_status')),
    withdrawType: Yup.string().required('Please select withdraw type'),
    description: Yup.string().required(t('Url_description_is_required')).min(1, t('Description_must_be_at_least_1_character'))
        .max(500, t('Description_must_be_less_than_500_character')),
    currencyIds: Yup.array().min(1, t('Please_select_currency'))
})
export const customTokenSchema = (t) => Yup.object().shape({
    tokenId: Yup.string().required(t('Please_select_a_token')),
    address: Yup.string().required(t('Please_select_a_address')),
})


export const customTokenSchemaTwo = (t) => Yup.object().shape({
    name: Yup.string().required(t('Please_enter_name')),
    symbol: Yup.string().required(t('Please_enter_symbol')),
    decimal: Yup.string().required(t('Please_enter_decimal'))
        .matches(/^\d+(\.\d+)?$/, t('Please_enter_valid_decimal_number')),
    icon: Yup.string().required("Icon is required")
})
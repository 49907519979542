import React from 'react';

const IcnStep4 = (props) => {
    return (
        <svg {...props} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2180_11523)">
                <path d="M24.5815 10.2899C24.3712 10.1331 24.2071 9.92245 24.1065 9.68017C24.0059 9.43788 23.9725 9.17294 24.0099 8.91327C24.4999 5.83327 23.9399 4.88827 23.5199 4.46827C23.0999 4.04827 22.1665 3.49994 19.0865 3.98994C18.827 4.0248 18.5629 3.99026 18.3211 3.88984C18.0793 3.78942 17.8684 3.62671 17.7099 3.41827C15.6799 0.711607 14.6182 0.396606 13.9999 0.396606C13.3815 0.396606 12.3199 0.711607 10.2899 3.41827C10.1314 3.62671 9.92049 3.78942 9.67866 3.88984C9.43684 3.99026 9.17272 4.0248 8.9132 3.98994C5.8332 3.49994 4.89987 4.05994 4.47987 4.46827C4.05987 4.87661 3.49987 5.83327 3.98987 8.91327C4.02722 9.17294 3.99383 9.43788 3.89322 9.68017C3.79261 9.92245 3.62851 10.1331 3.4182 10.2899C0.711537 12.3199 0.408203 13.3816 0.408203 13.9999C0.408203 14.6183 0.711537 15.6799 3.4182 17.7099C3.62851 17.8668 3.79261 18.0774 3.89322 18.3197C3.99383 18.562 4.02722 18.8269 3.98987 19.0866C3.54654 22.1899 4.05987 23.1116 4.47987 23.5316C4.89987 23.9516 5.80987 24.4533 8.9132 24.0099C9.17287 23.9726 9.43781 24.006 9.6801 24.1066C9.92238 24.2072 10.133 24.3713 10.2899 24.5816C12.3199 27.2883 13.3815 27.6033 13.9999 27.6033C14.6182 27.6033 15.6799 27.2883 17.7099 24.5816C17.8667 24.3713 18.0774 24.2072 18.3196 24.1066C18.5619 24.006 18.8269 23.9726 19.0865 24.0099C22.2015 24.4533 23.0999 23.9399 23.5199 23.5316C23.9399 23.1233 24.4532 22.1899 24.0099 19.0866C23.9725 18.8269 24.0059 18.562 24.1065 18.3197C24.2071 18.0774 24.3712 17.8668 24.5815 17.7099C27.2882 15.6799 27.5915 14.6183 27.5915 13.9999C27.5915 13.3816 27.2882 12.3199 24.5815 10.2899Z" fill="white" />
                <path d="M13.4164 18.0833C13.2629 18.0842 13.1106 18.0548 12.9685 17.9967C12.8264 17.9386 12.6971 17.8531 12.5881 17.745L10.2547 15.4116C10.035 15.1919 9.91162 14.894 9.91162 14.5833C9.91162 14.2726 10.035 13.9747 10.2547 13.755C10.4744 13.5353 10.7724 13.4119 11.0831 13.4119C11.3937 13.4119 11.6917 13.5353 11.9114 13.755L13.4164 15.2716L17.2547 11.4216C17.3632 11.3123 17.4922 11.2255 17.6344 11.1663C17.7766 11.107 17.929 11.0765 18.0831 11.0765C18.2371 11.0765 18.3896 11.107 18.5317 11.1663C18.6739 11.2255 18.8029 11.3123 18.9114 11.4216C19.0207 11.5301 19.1075 11.6591 19.1668 11.8013C19.226 11.9435 19.2565 12.096 19.2565 12.25C19.2565 12.404 19.226 12.5565 19.1668 12.6986C19.1075 12.8408 19.0207 12.9698 18.9114 13.0783L14.2447 17.745C14.1357 17.8531 14.0064 17.9386 13.8643 17.9967C13.7221 18.0548 13.5699 18.0842 13.4164 18.0833Z" fill="#4AB500" />
            </g>
            <defs>
                <clipPath id="clip0_2180_11523">
                    <rect width="28" height="28" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}

export default IcnStep4;

import React, { useEffect, useState } from 'react'
import BinanceIcon from '../../assets/images/BinanceIcon.png'
import ReceiveModal from '../../components/model/ReceiveModal';
import { RECEIVE_API_URL, WALLET_API_URL } from '../../api/api';
import { useQuery } from 'react-query';
import { files } from '../../Helper';
import useAxiosPrivate from '../../api/useAxiosPrivate';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/ui/Loader';

const Receive = () => {
  const { t } = useTranslation();
  const [openReceiveModal, setOpenReceiveModal] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [assetAddress, setAssetAddress] = useState([])
  const [loading, setloading] = useState(false)
  const [icon, setIcon] = useState()
  const axiosInstance = useAxiosPrivate();


  const hadleCloseReceiveModal = () => {
    setOpenReceiveModal(false)
  }

  async function getTokens() {
    const response = await axiosInstance.get(WALLET_API_URL.getToken);
    return response.data;
  }
  const { isLoading, data: tokenList, refetch } = useQuery(['tokenList'], () => getTokens(), { keepPreviousData: true, })

  useEffect(() => {
    if (tokenList) {
      setTokens(tokenList);
    }

  }, [tokenList])


  const [token, setToken] = useState()
  const [decimalId, setDecimalId] = useState()

  const handleOpenReceiveModal = async (decimalId, icon) => {
    setOpenReceiveModal(true);
    setDecimalId(decimalId)
    // const assetAddress = await getAssetAddress(decimalId)
    // setAssetAddress(assetAddress);
    setToken(icon)
    setIcon(icon);
    setloading(false);
  }

  useEffect(() => {
    document.title = 'Receive | Oppi Wallet'
  }, [])

  return (
    <>
      {
        isLoading ? <Loader /> :
          <div className="p-12 bg-dark h-full">
            <div className='sm:w-[80%] bg-card min-h-full mx-auto rounded-[20px] border border-light-100 flex flex-col p-12'>
              <p className='text-white text-20 font-500 mb-12'>{t('Receive')}</p>
              <div className="flex flex-col gap-6">
                {
                  tokens?.map((token, index) =>
                  (
                    <div className="p-3 w-full bg-dark flex justify-between items-center rounded-3xl cursor-pointer" onClick=
                      {() => handleOpenReceiveModal(token?.symbol, token)}>
                      <div className="token-detail flex items-center">
                        <div className="rounded-full bg-light-200 mr-4 w-[48px] h-[48px] flex justify-center items-center">
                          {token?.icon ?
                            <img className='rounded-full h-full w-full object-cover' src={files(token?.icon, 'image')} alt="" />
                            :
                            <div className='xxl:w-[44px] w-[44px] xxl:h-[44px] h-[44px] rounded-full bg-theme' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 500, borderRadius: '50%', fontSize: '22px', }}>{token?.name?.slice(0, 1).toUpperCase()}</div>
                          }
                        </div>
                        <div className="text">
                          <div className="flex items-center gap-[8px]">
                            <h5 className='text-white xxl:text-18 sm:text-16 text-14'>{token?.name}</h5>
                            {/* <p className='text-green sm:text-14 text-12'>+5.00%</p> */}
                          </div>
                          <div className="flex items-center gap-[8px]">
                            <p className='text-gray sm:text-14 text-12'>{token?.code ? token?.code : token?.symbol ?? '-'}</p>
                            {token?.tokenId && <button className='text-gray sm:text-14 text-12 bg-card p-[2px_6px] rounded-[6px]'>{token?.tokenName}</button>}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                  )}
              </div>
            </div>
            <ReceiveModal open={openReceiveModal} handleClose={hadleCloseReceiveModal} decimalId={decimalId} setAssetAddress={setAssetAddress} loading={loading} token={token} icon={icon} />
          </div>
      }
    </>
  )
}

export default Receive
import React from "react";

const IconStore = (props) => {
  return (
    <svg {...props} viewBox="0 0 25 24" fill="none">
      <g clip-path="url(#clip0_643_8371)">
        <path
          d="M20.2382 13.6552C20.2063 13.6532 12.9909 17.9292 12.8963 17.9658C12.875 17.9534 5.59616 13.6455 5.56152 13.6498C5.5792 13.6757 12.8914 24 12.8963 24C12.9013 24 20.2382 13.6552 20.2382 13.6552Z"
          fill="currentcolor"
        />
        <path
          d="M12.8998 0L5.58521 12.2334L12.8998 16.5661L20.2143 12.2334L12.8998 0Z"
          fill="currentcolor"
        />
      </g>
      <defs>
        <clipPath id="clip0_643_8371">
          <rect
            width="24"
            height="24"
            fill="currentcolor"
            transform="translate(0.561523)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconStore;

import React from "react";

const SwapWithConfidence = () => {
  return (
    <section className="lg:pt-[100px] pt-[40px]">
      <div className="container">
        <h2 className="lg:text-40 text-32 font-600 text-center lg:mb-20 mb-12" data-aos="fade-down" data-aos-duration="1500">Swap with Confidence</h2>
        <div className="grid md:grid-cols-3 lg:gap-[40px] gap-8">
          <div className="lg:py-20 py-12 border-2 border-gray/50 rounded-[20px]" data-aos="zoom-in-right" data-aos-duration="1500">
            <div className="grid place-items-center xl:w-[120px] w-[90px] aspect-square bg-linear-horizontal rounded-full mx-auto lg:mb-12 mb-8">
              <img src={require("../../../assets/images/SwapMessage.svg").default} className="xl:w-[46px] w-[36px]" alt="" />
            </div>
            <p className="text-center text-18 w-[90%] capitalize font-600 md:max-w-[250px] mx-auto">The only exchange with 24/7 Live Chat support for all customers.</p>
          </div>
          <div className="lg:py-20 py-12 border-2 border-gray/50 rounded-[20px]" data-aos="zoom-in" data-aos-duration="1500">
            <div className="grid place-items-center xl:w-[120px] w-[90px] aspect-square bg-linear-horizontal rounded-full mx-auto lg:mb-12 mb-8">
              <img src={require("../../../assets/images/SwapSecure.svg").default} className="xl:w-[46px] w-[36px]" alt="" />
            </div>
            <p className="text-center text-18 w-[90%] capitalize font-600 md:max-w-[250px] mx-auto">Multi-layered Security to ensure your funds are secure.</p>
          </div>
          <div className="lg:py-20 py-12 border-2 border-gray/50 rounded-[20px]" data-aos="zoom-in-left" data-aos-duration="1500">
            <div className="grid place-items-center xl:w-[120px] w-[90px] aspect-square bg-linear-horizontal rounded-full mx-auto lg:mb-12 mb-8">
              <img src={require("../../../assets/images/SwapSolution.svg").default} className="xl:w-[46px] w-[36px]" alt="" />
            </div>
            <p className="text-center text-18 w-[90%] capitalize font-600 md:max-w-[250px] mx-auto">Data driven solution to provide you with insights to inform your strategy.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SwapWithConfidence;

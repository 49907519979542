import React from 'react';

const IconCheck = (props) => {
    return (
        <svg {...props} viewBox="0 0 18 18" fill="none">
            <g clipPath="url(#clip0_226_14482)">
                <circle cx="9" cy="9" r="9" fill="#7684ED" />
                <path d="M13.5617 7.09296L8.68666 11.9679C8.54041 12.1141 8.34843 12.1877 8.15644 12.1877C7.96445 12.1877 7.77246 12.1141 7.62621 11.9679L5.18876 9.53041C4.89541 9.23721 4.89541 8.76316 5.18876 8.46996C5.48196 8.17662 5.95587 8.17662 6.24921 8.46996L8.15644 10.3772L12.5013 6.03251C12.7945 5.73916 13.2684 5.73916 13.5617 6.03251C13.8549 6.32571 13.8549 6.79962 13.5617 7.09296Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_226_14482">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default IconCheck

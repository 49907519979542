import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const WebFooter = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="lg:pt-[100px] sm:pt-[50px] pt-[30px] pb-[60px] border-t border-light-gray">
        <div className="container">
          <div className="grid xl:grid-cols-4 md:grid-cols-4 grid-cols-2 gap-6">
            <div className="xl:col-span-1 md:col-span-4 col-span-2">
              <img
                src={require("../../assets/images/Logo.svg").default}
                className="sm:max-w-[130px] max-w-[180px]"
                alt=""

              />
            </div>
            <div className="">
              <h6 className="text-18 font-600 mb-4">{t('Company')}</h6>
              <ul className="flex flex-col gap-[9px]">
                <li className="text-light-600 text-16 font-400 cursor-pointer hover:text-greenweb"><Link to="/about-us">{t('about_us')}</Link></li>
                {/* <li className="text-light-600 text-16 font-400 cursor-pointer hover:text-greenweb">Blog</li> */}
                <li className="text-light-600 text-16 font-400 cursor-pointer hover:text-greenweb"><Link to="/privacy-policy">{t('Privacy_Policy')}</Link></li>
                <li className="text-light-600 text-16 font-400 cursor-pointer hover:text-greenweb"><Link to="/aml-policy">{t('AML_Policy')}</Link></li>
                <li className="text-light-600 text-16 font-400 cursor-pointer hover:text-greenweb"><Link to="/cookie-policy">{t('Cookies_Policy')}</Link></li>
                <li className="text-light-600 text-16 font-400 cursor-pointer hover:text-greenweb"> <Link to="/term-condition">{t('Terms_of_use')}</Link></li>
              </ul>
            </div>
            <div className="">
              <h6 className="text-18 font-600 mb-4">{t('Support')}</h6>
              <ul className="flex flex-col gap-[9px]">
                {/* <li className="text-light-600 text-16 font-400 cursor-pointer hover:text-greenweb"><Link to="/support">Contact Us</Link></li> */}
                <li className="text-light-600 text-16 font-400 cursor-pointer hover:text-greenweb"> <Link to="/support">{t('Support_Center')}</Link></li>
                <li className="text-light-600 text-16 font-400 cursor-pointer hover:text-greenweb"> <Link to="/card-activation">{t('Card_Activation')}</Link></li>
                <li className="text-light-600 text-16 font-400 cursor-pointer hover:text-greenweb"><Link to="/v1/api-documentation">{t('API_Integration_Kit')}</Link></li>
                <li className="text-light-600 text-16 font-400 cursor-pointer hover:text-greenweb"><Link to="/faq">{t('FAQ')}</Link></li>
              </ul>
            </div>
            <div className="">
              <h6 className="text-18 font-600 mb-4">{t('Why_Us')}</h6>
              <ul className="flex flex-col gap-[9px]">
                <li className="text-light-600 text-16 font-400 cursor-pointer hover:text-greenweb"><Link to='/business'>{t('Oppi_for_business')}</Link></li>
                <li className="text-light-600 text-16 font-400 cursor-pointer hover:text-greenweb"><Link to='/swap'>{t('Swap_your_assets')}</Link></li>
                <li className="text-light-600 text-16 font-400 cursor-pointer hover:text-greenweb"><Link to='/store-crypto'>{t('Store_your_crypto')}</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WebFooter;

import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import IconClose from "../../assets/svg/IconClose";
import { Button } from "../ui/Button";
import "react-phone-input-2/lib/style.css";
import PinInput from "react-pin-input";
import { authStore } from "../../context/AuthProvider";
import { maskEmail } from "../../Helper";
import { AUTH_API_URL } from "../../api/api";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { t } from 'i18next'

const EmailAuthentication = ({ open, handleClose, setClose }) => {
  const { user, set } = authStore();
  const [otp, setOtp] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const axiosInstance = useAxiosPrivate();
  const [seconds, setSeconds] = useState(90);
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const queryClient = useQueryClient()
  const [isDisable,setIsDisable] = useState(true);



  const handleOtpVerification = async () => {
    const data = {
      email: user?.email,
      otp: otp
    }

    await emailVerify(data)
  }

  const { mutateAsync: emailVerify } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(AUTH_API_URL.verifyEmail, data).then((res) => res.data)
        if (response) {
          const authData = {
            authenticationType: 2
          }
          set((state) => ({
            user: {
              ...state.user,
              isEmailEnable: user?.isEmailEnable == 1 ? 0 : 1
            },
          }));
          await enableAuth(authData)
        }
      } catch (error) {
        enqueueSnackbar(error?.response?.data.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      }
    },
  );
  const { mutateAsync: enableAuth } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(AUTH_API_URL.enableVerifications, data).then((res) => res.data)
        if (response) {

          queryClient.invalidateQueries('userProfile')
          enqueueSnackbar(response?.message, {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
        handleClose()
      } catch (error) {
        if (error?.response?.data.message) {
          enqueueSnackbar(error?.response?.data.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
      }
    },
  );

  useEffect(() => {
    if (open || isResendEnabled) {
      const timer = setInterval(() => {
        if (seconds > 0) {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }
      }, 1000);

      return () => clearInterval(timer);

    }
  }, [open, seconds, isResendEnabled]);

  const startTimer = () => {
    setSeconds(90);
    setIsResendEnabled(false);
  };


  useEffect(() => {
    // Reset the timer and enable Resend button when it reaches 0
    if (seconds === 0) {
      setSeconds(30);
      setIsResendEnabled(true);
    }
  }, [seconds]);

  const handleResendSendEmail = async () => {
    startTimer()
    const data = {
      email: user?.email
    }
    await sendEmail(data)
  }

  const { mutateAsync: sendEmail } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(AUTH_API_URL.sendEmailAuth, data).then((res) => res.data)
        if (response) {
          enqueueSnackbar(response?.message, {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });

        }
      } catch (error) {
        console.log(error);
      }
    }
  );

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  useEffect(() => {

    if (otp?.length === 4) {
      handleOtpVerification();
    }
  }, [otp])

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[99]" onClose={() => { return; }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                {/* <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all"> */}
                <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark border border-light-100 shadow-xl transition-all">
                  <div className="flex justify-center p-8 bg-card border-b-light-100 border-b rounded-t-[30px]">
                    <p className="text-white ms-auto">{t("Email_Authentication")}</p>
                    <IconClose
                      onClick={() => {
                        handleClose()
                        setIsResendEnabled(false)
                        setSeconds(90)
                      }}
                      className="text-white w-[18px] ms-auto cursor-pointer"
                    />
                  </div>
                  <div className="p-12">
                    <p className="text-gray text-16 font-400">{t("OTP_has_been_sent_on")}</p>
                    <p className="text-white text-16 mb-8 font-500">
                      {maskEmail(user?.email)}
                    </p>
                    <p className="text-gray text-[14px] font-400 mb-12">
                      {t("check_4_digit_otp_email")}
                    </p>
                    <PinInput
                      length={4}
                      onChange={(value, index) => { setOtp(value); setIsDisable(value?.length > 0 ? false : true) }}
                      type="numeric"
                      inputMode="number"
                      style={{
                        padding: "0 10px",
                        margin: "20px auto",
                        width: "max-content",
                        display: "flex",
                        gap: "10px",
                      }}
                      inputStyle={{
                        borderColor: "#b3b3b31a",
                        backgroundColor: "#1D1D22",
                        width: "65px",
                        height: "50px",
                        borderRadius: "24px",
                        color: "white",
                        fontSize: "18px",
                      }}
                      onComplete={(value, index) => { }}
                      autoSelect={true}
                      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    />
                    {
                      isResendEnabled ? (
                        <p className="text-gray text-16 font-400 mb-16 text-center  ms-auto cursor-pointer">
                          {t("didnt_get_code")} <span className="text-theme" onClick={handleResendSendEmail}>Resend</span>
                        </p>
                      ) :
                        <p className="text-gray text-16 font-400 mb-16 text-center">
                          {t("didnt_get_code")} <span className="text-theme"> {`0${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`}</span>
                        </p>
                    }

                    {/* <Button className="w-full text-[14px] text-white" disabled={isLoading} onClick={handleOtpVerification}>Submit</Button> */}
                    <Button className={`w-full text-[14px] text-white ${isDisable ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={isDisable} >{t("Submit")}</Button>
                  </div>
            
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default EmailAuthentication;

import React, { useState } from "react";
import { Button } from "../../../components/ui/Button";
import IconCopy from "../../../assets/svg/IconCopy";
import EnableGoogleAuthModal from "../../../components/model/EnableGoogleAuthModal";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const BackupKey = ({ setStep, secretKey }) => {
  const [enableGoogleAuth, setEnableGoogleAuth] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {t} = useTranslation();

  const handleOpenEnableGoogleAuthModal = () => {
    setEnableGoogleAuth(true);
  };
  const handleCloseEnableGoogleAuthModal = () => {
    setEnableGoogleAuth(false);
  };
  const truncateString = (str, maxLength) => {
    if (str?.length > maxLength) {
      return str.substring(0, maxLength / 2) + '...';
    }
    return str;
  };


  const handleCopy = () => {
    const wordsToCopy = secretKey || null;
    navigator?.clipboard?.writeText(wordsToCopy).then(() => {
      console.log('Key copied to clipboard:', wordsToCopy);
      enqueueSnackbar(t('Key_copied_to_clipboard'), {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
    });
  }

  return (
    <>
      <div className="flex flex-col justify-center items-center h-full py-20 my-10">
        <p className="text-gray max-w-[440px] text-center mb-8">
          {t('save_this_key_on_paper')}
        </p>
        <img
          src={require("../../../assets/images/BackupKey.png")}
          className="w-[150px]"
          alt=""
        />
        <p className="text-gray max-w-[440px] text-center mt-8">
          {t('Resetting_your_Google_Authentication_requires_opening_a_support_ticket')}
        </p>
        <div className="flex justify-between items-center w-full max-w-[340px] bg-dark p-2 rounded-full mt-10 border border-light-100">
          <p className="text-white text-14 font-400 ps-6 truncate">
            {secretKey}
          </p>
          <div className="p-4 bg-linear-horizontal rounded-full cursor-pointer">
            <IconCopy className="w-[20px] h-[20px] text-white" onClick={handleCopy} />
          </div>
        </div>
      </div>
      <div className="py-8 flex gap-8">
        <Button
          className="w-full border border-white !text-white text-14 bg-transparent"
          size="sm"
          onClick={() => setStep(2)}
        >
          {t('Previous')}
        </Button>
        <Button className="w-full text-[14px] text-white" size="sm" onClick={handleOpenEnableGoogleAuthModal}>
        {t('Next')}
        </Button>
      </div>
      <EnableGoogleAuthModal
        open={enableGoogleAuth}
        handleClose={handleCloseEnableGoogleAuthModal}
        setStep={setStep}
        isForDisable ={0}
      />
    </>
  );
};

export default BackupKey;

import React from 'react'
import IcnGoldChip from '../../../assets/svg/IcnGoldChip'
import IcnWifi from '../../../assets/svg/IcnWifi'
import IcnMasterLogo from '../../../assets/svg/IcnMasterLogo'
import { enqueueSnackbar } from 'notistack'
import IcnVisaLogo from '../../../assets/svg/IcnVisaLogo'
import { t } from 'i18next'

const CardFront = ({ detail, setDetail }) => {
    return (
        <div className={`flip-card-front flex flex-col justify-between rounded-[24px] xss:p-[16px_28px] p-[10px_18px] overflow-hidden xss:h-[210px] h-[180px] xss:w-[325px] w-full mx-auto cursor-pointer`} onClick={() => detail?.cardIdStatus == 1 ? setDetail(detail) : detail?.paymentStatus == 0 ?
            enqueueSnackbar(
                <div className='border-s-2 border-green1 ps-[4px]'>
                    <h4 className='text-[16px] text-green1'>Payment Pending</h4>
                    <p className='text-[14px] text-green1'>Card fees and charges is pending. You can pay from mobile app.</p>
                </div>,
                {
                    variant: "info",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 3000,
                    hideIconVariant: true,
                    style: { background: "#fff" }
                }) : detail?.cardIdStatus == 0 ? enqueueSnackbar(
                    <div className='border-s-2 border-green1 ps-[4px]'>
                        <h4 className='text-[16px] text-green1'>Processing</h4>
                        <p className='text-[14px] text-green1'>Your card still under verification you can see details after verification.it's takes appromately 5 to 30 minutes.</p>
                    </div>,
                    {
                        variant: "info",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 3000,
                        hideIconVariant: true,
                        style: { background: "#fff" }
                    }) : ''}>
            <div className="flex flex-col">
                <div className="flex items-center justify-between">
                    <img
                        src={require('../../../assets/images/card/card-logo.svg').default}
                        className=""
                        alt=""
                    />
                    <p className='text-[10px] font-500 tracking-wider text-[#B3B3B3]'>{t('Virtual_Card')}</p>
                </div>
                <div className="flex">
                    <span className=''>
                        <IcnGoldChip className='h-[43px] w-[45px]' />
                    </span>
                    <span className='pt-[5px]'> 
                        <IcnWifi className="h-[27px] w-[27px] text-white" />
                    </span>
                </div>
            </div>
            
            <div className="flex items-end justify-between">
                <div className="pb-[8px]">
                    <p className='text-white xss:text-[12px] text-[10px] font-400'>{detail?.paymentStatus == 0 ? 'Payment Pending' : detail?.cardIdStatus == 2 ? 'Rejected' : detail?.cardIdStatus == 0 ? 'Processing' : ''}</p>
                    <p className='example'>{detail?.firstName} {detail?.lastName}</p>
                </div>
                <span>
                    {detail?.hypercardtype?.cardAssoc?.toLowerCase() == 'mastercard' ?
                        <IcnMasterLogo className='h-[50px] w-[73px]' /> :
                        <IcnVisaLogo className='h-[50px] w-[73px]' />
                    }

                </span>
            </div>
        </div>
    )
}

export default CardFront
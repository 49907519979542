import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import SettingSideBar from '../../components/dash/SettingSideBar';
import SettingHeader from '../../components/dash/SettingHeader';

const SettingMaster = () => {
  const [tooglesidebar, setTooglesidebar] = useState(false);

  return (
    <>
      <main className='bg-dark p-12 sm:px-20 h-[calc(100vh-80px)]'>
        <div className="flex h-full rounded-[20px] border border-[#b3b3b31a] relative overflow-hidden">
          <SettingSideBar setTooglesidebar={setTooglesidebar} tooglesidebar={tooglesidebar} />
          <div className='w-full flex flex-col lg:max-w-[calc(100%-300px)]'>
            <SettingHeader setTooglesidebar={setTooglesidebar} tooglesidebar={tooglesidebar} />
            <div className="main-container overflow-y-auto overflow-x-hidden theme-scrollbar h-[calc(100%-65px)] bg-card">
              <Outlet />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default SettingMaster
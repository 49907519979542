import React from "react";

const WeProvideService = () => {
  return (
    <section className="lg:pt-[100px] pt-[40px]">
      <div className="container">
        <div className="text-center" data-aos="fade-down" data-aos-duration="1500">
          <h5 className="text-32 font-600 text-center mb-4">We Provide The Best Service For You</h5>
          <p className="text-16 capitalize lg:mb-20 mb-12">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium</p>
        </div>
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 lg:gap-6 gap-8">
          <div className="lg:py-20 py-12 bg-light-200 border border-light-gray rounded-[20px]" data-aos="fade-down" data-aos-duration="2500">
            <div className="grid place-items-center xl:w-[100px] w-[90px] aspect-square bg-linear-horizontal rounded-full mx-auto lg:mb-12 mb-8">
              <img src={require("../../../assets/images/StoreCard.svg").default} className="xl:w-[46px] w-[36px]" alt="" />
            </div>
            <p className="text-center text-18 w-[90%] capitalize font-600 md:max-w-[220px] mx-auto">Super easy to use</p>
          </div>
          <div className="lg:py-20 py-12 bg-light-200 border border-light-gray rounded-[20px]" data-aos="fade-up" data-aos-duration="2500">
            <div className="grid place-items-center xl:w-[100px] w-[90px] aspect-square bg-linear-horizontal rounded-full mx-auto lg:mb-12 mb-8">
              <img src={require("../../../assets/images/StoreProfile.svg").default} className="xl:w-[46px] w-[36px]" alt="" />
            </div>
            <p className="text-center text-18 w-[90%] capitalize font-600 md:max-w-[220px] mx-auto">Send and receive money effeciently</p>
          </div>
          <div className="lg:py-20 py-12 bg-light-200 border border-light-gray rounded-[20px]" data-aos="fade-down" data-aos-duration="2500">
            <div className="grid place-items-center xl:w-[100px] w-[90px] aspect-square bg-linear-horizontal rounded-full mx-auto lg:mb-12 mb-8">
              <img src={require("../../../assets/images/SwapSecure.svg").default} className="xl:w-[46px] w-[36px]" alt="" />
            </div>
            <p className="text-center text-18 w-[90%] capitalize font-600 md:max-w-[220px] mx-auto">Safe and protected</p>
          </div>
          <div className="lg:py-20 py-12 bg-light-200 border border-light-gray rounded-[20px]" data-aos="fade-up" data-aos-duration="2500">
            <div className="grid place-items-center xl:w-[100px] w-[90px] aspect-square bg-linear-horizontal rounded-full mx-auto lg:mb-12 mb-8">
              <img src={require("../../../assets/images/StoreTransaction.svg").default} className="xl:w-[46px] w-[36px]" alt="" />
            </div>
            <p className="text-center text-18 w-[90%] capitalize font-600 md:max-w-[220px] mx-auto">More efficient transaction fees</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeProvideService;

import React from 'react';
import IconMsg from '../../../assets/svg/IconMsg';
import IconCall from '../../../assets/svg/IconCall';
import IconLocation from '../../../assets/svg/IconLocation';
import ContactCard from '../../common/ContactCard';

const data = [
    {
        logo: <IconMsg className='md:h-[34px] h-[24] md:w-[34px] w-[24px] text-white' />,
        title: 'For General ',
        text: 'help@oppipay.com'
    }, {
        logo: <IconCall className='md:h-[34px] h-[24] md:w-[34px] w-[24px] text-white' />,
        title: 'For Wallet Support ',
        text: 'support@oppiwallet.com'
    },
    {
        logo: <IconLocation className='md:h-[34px] h-[24] md:w-[34px] w-[24px] text-white' />,
        title: 'For API Help',
        text: 'api@oppiwallet.com'
    }
]

const TalkToExperts = () => {
    return (
        <section className='md:pt-[50px] pt-[36px]'>
            <h2 className='md:text-[42px] sm:text-[36px] text-[28px] font-700 text-center md:pb-[45px] pb-[20px]'>Face Other Issue Talk To Experts</h2>
            <div className="grid sm:grid-cols-3 md:gap-[30px] gap-[12px]">
                {
                    data.map((obj, index) =>

                        <ContactCard data={obj} />
                    )
                }
            </div>
        </section>
    );
}

export default TalkToExperts;

import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect, useContext } from 'react'
import IconClose from '../../assets/svg/IconClose'
import { Button } from '../ui/Button'
import Input from '../ui/Input'
import { Form, FormikProvider, useFormik } from 'formik'
import CustomSelect from '../ui/CustomSelect'
import { authStore } from '../../context/AuthProvider'
import { useSnackbar } from 'notistack'
import { WALLET_API_URL } from '../../api/api'
import * as Yup from "yup";
import { useMutation, useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { axiosInstance } from '../../api/axiosInstance'
import { AuthSocketContext } from '../../context/AuthSocketProvider'
import SendInformation from '../../components/ui/SendInformation'
import { RadioGroup } from '@headlessui/react'
import IconDone from '../../assets/svg/IconDone'
import Swal from 'sweetalert2'
import { _randomString, fixDecimal, generateJwt, seperateToken } from '../../Helper'
import SendConfirmationDialog from '../ui/SendConfirmationDialog'
import ReachargeCurrencySelect from '../ui/ReachargeCurrencySelect'

const SetCardPinModal = ({ open, setOpenCardPinModal, detail, getAllTransaction, formattedDate, balance }) => {
  const [coins, setCoins] = useState([]);
  const [selectedCoin, setSelectedCoin] = useState(undefined);
  const [address, setAddress] = useState();
  const { user, userVaultAccount, encKey, set, userCurrencyBalance } = authStore();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [openModal, setOpenMoal] = useState(false);
  const [level, setLevel] = useState();
  const [selectedFeeLevel, setSelectedFeeLevel] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCurrencyUserAmount, setSelectedCurrencyUserAmount] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openSendInfo, setOpenSendInfo] = useState(false)
  const { setConfirmSend, approveReject, setApproveReject, setConfirmCardRecharge } = useContext(AuthSocketContext)
  const [currencyBalance, setCurrencyBalance] = useState([]);
  const [amountValue, setAmountValue] = useState(0);

  const handleSelectedCoin = (value) => {
    const matchedBalance = userCurrencyBalance?.filter(item => item.id == value?.id);
    if (detail?.hypercardtype?.minSingleRechargeAmount < matchedBalance[0]?.newBalance) {
      setCurrencyBalance(matchedBalance);
      setSelectedCoin(value)
      formik.setFieldValue('coin', value?.decimal);
      setOpenMoal(false)
      resetForm();
    } else {
      setCurrencyBalance([]);
      setSelectedCoin();
      setAddress();
    }

  }


  //  step 1  : fetch Coins
  async function getCoin() {
    const response = await axiosInstance.get(WALLET_API_URL.getToken);
    return response.data;
  }
  const { data: coinList } = useQuery(['coinList'], () => getCoin(), { keepPreviousData: true, })

  // set send from validation 
  const sendTransactionSchema = (t, selectedCoin, currencyBalance) => Yup.object().shape({
    coin: Yup.string().required(t('Please_select_currency')),
    amount: Yup.number().required(t('Please_enter_amount_to_transfer')).moreThan(0, 'Amount should be greater than 0').test(
      'no-signs',
      t('Please enter valid amount'),
      (value) => /^[0-9]*\.?[0-9]+$/.test(value) // Regex to ensure no '+' or '-' signs

    ).test(
      'is-less-than-balance',
      t(`Amount should be less than maximum recharge amount ${detail?.hypercardtype?.maxSingleRechargeAmount} ${detail?.hypercardtype?.cardCurrency} `), // Custom message
      (value) => (parseFloat(currencyBalance[0]?.usdPrice || 0) * parseFloat(amountValue)) < detail?.hypercardtype?.maxSingleRechargeAmount // Ensure the amount is strictly less than the balance
    )
      .test(
        'is-max-than-singleTimeBalance',
        t(`Amount should be more than minimum reacharge amount ${detail?.hypercardtype?.minSingleRechargeAmount.toFixed(1)} ${detail?.hypercardtype?.cardCurrency}`), // Custom message
        (value) => (parseFloat(currencyBalance[0]?.usdPrice || 0) * parseFloat(amountValue)) >= detail?.hypercardtype?.minSingleRechargeAmount // Ensure the amount is strictly less than the balance
      )
      .test(
        'is-max-than-trx-amount',
        t(`Amount should be less than amount ${parseFloat(fixDecimal(currencyBalance[0]?.newBalance || '0.0'))} ${currencyBalance[0]?.symbol}`), // Custom message
        (value) => (parseFloat(amountValue)) <= parseFloat(fixDecimal(currencyBalance[0]?.newBalance || '0.0'))
      )
      .test(

        'is-under-max-recharge-limit',
        t(`Your new recharge amount ${parseFloat((parseFloat(balance) + (parseFloat(currencyBalance[0]?.usdPrice || 0) * parseFloat(amountValue))).toFixed(2))} (Available balance : ${parseFloat(balance)} + ${(parseFloat(currencyBalance[0]?.usdPrice || 0) * parseFloat(amountValue)).toFixed(2)}) should not more than max card recharge amount ${detail?.hypercardtype?.maxRechargeAmount.toFixed(2)} ${detail?.hypercardtype?.cardCurrency}`),
        function (value) {
          const usdPrice = currencyBalance[0]?.usdPrice || 0;
          const totalBalanceWithInput = (parseFloat(balance) + (usdPrice * value));
          const maxRechargeAmountString = detail?.hypercardtype?.maxRechargeAmount;

          // Convert the string to a number
          const maxRechargeAmountNumber = parseFloat(maxRechargeAmountString);

          return parseFloat(totalBalanceWithInput).toFixed(2) > maxRechargeAmountNumber?.toFixed(2);
        }
      )

  });


  useEffect(() => {
    if (coinList) {
      const activeCoins = coinList.filter(coin => coin.status === 1);
      setCoins(activeCoins);
    }
  }, [coinList])

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    if (values?.amount > selectedCurrencyUserAmount?.balance) {
      enqueueSnackbar("Your transaction amount must be less than your available balance.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
      return;
    }
    setIsLoading(true);
    values.selectedCoin = selectedCoin;
    handleSubmitConfirm(values);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      coin: selectedCoin ? selectedCoin?.decimal : "",
      amount: "",
    },
    validationSchema: sendTransactionSchema(t, selectedCoin, currencyBalance),
    onSubmit, // No issues here now
  });


  const handleSubmitConfirm = () => {
    setIsLoading(false)
    const payabelAmount = parseFloat(values?.amount) + parseFloat(selectedFeeLevel?.networkFee);
    if (parseFloat(payabelAmount) > parseFloat(selectedCurrencyUserAmount?.balance)) {
      enqueueSnackbar(`Your current balance is ${selectedCurrencyUserAmount?.balance} and total transfer amount including estimateFee is ${payabelAmount} !`, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
      return
    }

    const data = {
      userId: user?._id,
      currencyName: selectedCoin?.symbol,
      currencyCode: selectedCoin?.symbol,
      receiverAddress: values?.address,
      amount: (values?.amount).toString(),
      encryptedKey: encKey,
      tokenId: selectedCoin?.id ?? '',
      tag: values?.tag ?? "",
      cardId: detail?.cardId,
      rechargeUsdBalance: values?.amount ? calculateUsdValue(currencyBalance[0]?.usdPrice) : ''
    }

    setOpenConfirmation(false)
    setOpenSendInfo(true)
    setConfirmCardRecharge(data)
    resetForm()
  }




  const handleModalClose = () => {
    resetForm();
  }

  const handleCloseConfirmation = () => {
    setIsLoading(false)
    setOpenConfirmation(false)

  }
  const handleCloseInfoDialog = () => {
    setOpenSendInfo(false)
  }

  const handleClose = () => {
    setOpenCardPinModal(false);
    setSelectedCoin(undefined);
    resetForm();
  }


  useEffect(() => {

    if (approveReject !== null) {

      if (approveReject?.status === 1) {
        Swal.fire({
          icon: "success",
          title: "Your transaction request has been approved.",
          timer: null,
          showConfirmButton: true,
          allowOutsideClick: false
        });
        getAllTransaction(formattedDate)
      } else if (approveReject?.status === 2) {
        Swal.fire({
          icon: "error",
          title: "Send Transaction?",
          text: "Your transaction request has been rejected.",
          timer: null,
          showConfirmButton: true,
          allowOutsideClick: false
        });
      }
      setApproveReject(null)
      setSelectedFeeLevel(null)
      setSelectedCoin(undefined)
      setAddress(null)

      setConfirmCardRecharge(null)
      setOpenSendInfo(false)
      setOpenCardPinModal(false);
      set({ isCardRecharge: false })

    }
  }, [approveReject])

  const {
    handleSubmit,
    getFieldProps,
    values,
    errors,
    touched,
    setErrors,
    resetForm
  } = formik;

  const calculateUsdValue = (trxAmount) => {
    const result = trxAmount * values?.amount;
    return result;
  };

  useEffect(() => {
    if (values?.amount !== 0) {
      setAmountValue(values?.amount)
    }
  }, [values])

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => setOpenCardPinModal(true)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform rounded-[30px] bg-dark border border-light-100 shadow-xl transition-all">
                  <div className="flex justify-center p-8 bg-card border-b-light-100 border-b rounded-t-[30px]">
                    <p className='text-white ms-auto'>Card Recharge</p>
                    <IconClose onClick={handleClose} className="text-white w-[18px] ms-auto cursor-pointer"
                    />
                  </div>
                  <div className="p-12">
                    <FormikProvider value={formik}>
                      <Form autoComplete="off" onSubmit={handleSubmit}>
                        <label htmlFor="" className='!text-white text-14 font-500 mb-4 block mt-8\'>
                          {t('Select_Currency')}
                        </label>

                        {/* <CustomSelect listButtonRootClass="w-full justify-between rounded-full bg-dark p-[14px] ps-[16px]" data={coins}
                          getChildData={handleSelectedCoin} selectedCoin={selectedCoin} errorMsg={errors.coin && touched.coin ? errors.coin : null} type={'send'} /> */}
                        <ReachargeCurrencySelect listButtonRootClass="w-full justify-between rounded-full bg-dark p-[14px] ps-[16px]" data={coins}
                          getChildData={handleSelectedCoin} selectedCoin={selectedCoin} userCurrencyBalance={userCurrencyBalance} errorMsg={errors.coin && touched.coin ? errors.coin : null} type={'send'} minAmountValue={detail?.hypercardtype?.minSingleRechargeAmount} />
                        {
                          selectedCoin || !address?.length == 0 ? (
                            <>

                              <div className='mt-8'>
                                <div className="flex justify-between py-[10px] pb-[16px] mb-[12px]">
                                  <p className='text-white text-[16px]'>Available Balance</p>
                                  <p className='text-white'>{balance ? balance : ''} {detail?.hypercardtype?.cardCurrency}</p>
                                </div>
                                <div className="flex justify-between py-[10px] pb-[16px] mb-[12px]">
                                  <p className='text-white text-[16px]'>One time min recharge</p>
                                  <p className='text-white'>{detail?.hypercardtype?.minSingleRechargeAmount ? detail?.hypercardtype?.minSingleRechargeAmount?.toFixed(2) : ''} {detail?.hypercardtype?.cardCurrency}</p>
                                </div>
                                <div className="flex justify-between py-[10px] pb-[16px] mb-[12px]">
                                  <p className='text-white text-[16px]'>One time max recharge</p>
                                  <p className='text-white'>{detail?.hypercardtype?.maxSingleRechargeAmount ? detail?.hypercardtype?.maxSingleRechargeAmount?.toFixed(2) : ''} {detail?.hypercardtype?.cardCurrency}</p>
                                </div>
                                <div className="flex justify-between py-[10px] pb-[16px] mb-[12px]">
                                  <p className='text-white text-[16px]'>Max recharge</p>
                                  <p className='text-white'>{detail?.hypercardtype?.maxRechargeAmount ? detail?.hypercardtype?.maxRechargeAmount?.toFixed(2) : ''} {detail?.hypercardtype?.cardCurrency}</p>
                                </div>
                                <Input type='number' lable={t('Amount')} lableClass="text-14 mt-8" className="bg-dark" placeholder={t('Enter_Amount_in_INR')}  {...getFieldProps('amount')}  errorMsg={errors.amount && touched.amount ? errors.amount : null} />
                                <div>
                                  <p className='text-white text-14 m-2 flex items-center gap-[4px] '>{t('Currency_Balance')} :  <div className='flex items-center gap-[5px]'><span>{parseFloat(fixDecimal(currencyBalance[0]?.newBalance)) || '0.0'}</span><span>{currencyBalance[0]?.symbol ?? ""}</span> </div></p>
                                </div>
                                <div>
                                  {(values?.amount !== 0 && values.amount) ? <p className='text-white text-14 m-2 flex items-center gap-[4px] '>USD Price :  <div className='flex items-center gap-[5px]'><span>{calculateUsdValue(currencyBalance[0]?.usdPrice).toFixed(2)}</span><span>{'USD'}</span> </div></p> : ''}
                                </div>
                              </div>
                            </>
                          ) : (<></>)
                        }
                        <div className="mt-auto">
                          {
                            openModal ?
                              <div className="grid sm:grid-cols-2 items-center gap-[40px] pt-[20px]">
                                <Button type='button' className="w-full !text-14 border text-black mt-2" color="error" onClick={handleModalClose}>Cancel</Button>

                              </div> :
                              <Button
                                className={`w-full text-[14px] mt-20 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                size="sm"
                                disabled={isLoading}
                              >
                                {isLoading
                                  ? 'Loading...'
                                  : `${t('Recharge')}${values?.amount
                                    ? ` (${calculateUsdValue(currencyBalance[0]?.usdPrice).toFixed(2)}) ${detail?.hypercardtype?.cardCurrency}`
                                    : ''
                                  }`}
                              </Button>
                          }
                        </div>
                        .....................................
                      </Form></FormikProvider>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <SendInformation open={openSendInfo} setOpen={setOpenSendInfo} handleClose={handleCloseInfoDialog} />
    </>
  )
}

export default SetCardPinModal

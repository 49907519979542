import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import IconClose from '../../assets/svg/IconClose'
import { Button } from '../ui/Button'
import IconCopy from '../../assets/svg/IconCopy'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { APP_API_URL } from '../../api/api'
import { useMutation, useQueryClient } from 'react-query'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import { useSnackbar } from 'notistack'
import Swal from 'sweetalert2'

const ApiDetailModal = ({ open, handleClose, appDetails,onlineStatus }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const axiosInstance = useAxiosPrivate()
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const truncateString = (str, maxLength) => {
    if (str?.length > maxLength) {
      return str?.substring(0, maxLength / 2) + '...';
    }
    return str;
  };

  const handleEditApi = () => {
    navigate("/dashboard/create-api", { state: { appDetails: appDetails } });
  }

  const handleDeleteApi = async (appDetails) => {
    const deleteAppData = {
      appId: appDetails.id
    }
    Swal.fire({
      title: 'Are you sure ',
      text: "Are you sure want to delete",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Okay',
      cancelButtonText: 'Cancel',
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'confirm-button-class',
        cancelButton: 'confirm-button-class'
      }
    }).then(async (result) => {
      if (result['isConfirmed']) {
        await deleteApp(deleteAppData)
        // Put your function here
      }
    })
  }

  const { mutateAsync: deleteApp } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(APP_API_URL.deleteApp, data);
        if (response) {
          enqueueSnackbar('App deleted!', {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 1000,
          });
          queryClient.invalidateQueries('appList')
          handleClose()
          navigate("/dashboard/api");
        }
      } catch (error) {
        if (error?.response?.data.errors) {
          const errors = error?.response?.data.errors;
          Object.keys(errors).map(function (key) {
            enqueueSnackbar(errors[key], {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        }
      }
    }
  );


  const handleCopy = (key) => {
    if (!key) return; // Ensure key exists
    navigator?.clipboard?.writeText(key).then(() => {
      enqueueSnackbar("Key copied!", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
    });
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => { return }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all">
                <div className="flex justify-center p-8 bg-card border-b-light-100 border-b">
                  <p className='text-white ms-auto'>{t('Api_Details')}</p>
                  <IconClose onClick={handleClose} className="text-white w-[18px] ms-auto cursor-pointer" />
                </div>
                <div className="p-12">
                  <h6 className='text-gray text-14 mb-4 '>{t('Title')}</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4 truncate'>{appDetails?.title ?? ''}</p>
                  <h6 className='text-gray text-14 mb-4 '>Callback URL</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4 truncate'>{ appDetails?.callbackUrl ? appDetails?.callbackUrl :  '-'}</p>
                  <h6 className='text-gray text-14 mb-4'>Withdraw Type</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4 truncate'>{appDetails?.withdraeType == 0 ? 'Manul' : 'Automatic'}</p>
                  <h6 className='text-gray text-14 mb-4'>{t('App_ID')}</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4 truncate'>{appDetails?.id}</p>
                  <h6 className='text-gray text-14 mb-4'>{t('Api_White_Listed_IP')}</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4  break-all'>{appDetails?.whitelistedIPAddress ? appDetails?.whitelistedIPAddress : '-'}</p>
                  <h6 className='text-gray text-14 PB-4 mb-4'>Selected Currency</h6>
                  <div className="border-b border-b-light-200 mb-4 text-white">
                    {appDetails?.currencies && appDetails.currencies.length > 0 ?
                      appDetails.currencies.map((data) => (
                        <p className='pb-2'>
                          <span key={data.id}>
                            {data.name} ({data.symbol})
                          </span>
                        </p>
                      ))
                      : 'No currencies available'}
                  </div>
                  <h6 className='text-gray text-14 mb-4'>{t('Integration_URL_Description')}</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4 truncate'>{appDetails?.description}</p>
                  <div className='border-b border-b-light-200 mb-4'>
                    <h6 className='text-gray text-14 mb-4'>{t('Auth_Key')}</h6>
                    <div className="flex item-center gap-[12px] pb-4">
                      <p className='text-white truncate flex-grow'>{appDetails?.authKey}</p>
                      <span className=' flex-shrink-0 flex items-center justify-center cursor-pointer'>
                        <IconCopy className="h-[20px] w-[20px] text-white" onClick={() => handleCopy(appDetails?.authKey)} />
                      </span>
                    </div>
                  </div>
                  <div className='border-b border-b-light-200 mb-4'>
                    <h6 className='text-gray text-14 mb-4'>Enc Key</h6>
                    <div className="flex item-center gap-[12px] pb-4">
                      <p className='text-white truncate flex-grow'>{appDetails?.encKey}</p>
                      <span className=' flex-shrink-0 flex items-center justify-center cursor-pointer'>
                        <IconCopy className="h-[20px] w-[20px] text-white" onClick={() => handleCopy(appDetails?.encKey)} />
                      </span>
                    </div> 
                    
                  </div>
                  <h6 className='text-gray text-14 mb-4'>Send Paid Notification</h6>
                  <p className= {`text-theme ${ onlineStatus ? 'pb-4 border-b border-b-light-200 mb-4 truncate' : ''}`}>{appDetails?.enablePaidNotification == 1 ? "Enabled" : "Disabled"}</p>
                  {onlineStatus && <>
                    <h6 className='text-gray text-14 mb-4'>External Server Status</h6>
                    <p className='text-theme'>{onlineStatus}</p>
                  </>
                  }
                  <div className="flex mt-12 gap-8">
                    <Button size="sm" className="w-full bg-transparent !text-white text-14 border" onClick={handleEditApi}>Edit</Button>
                    <Button size="sm" className="w-full bg-red !text-white text-14" onClick={() => handleDeleteApi(appDetails)} >Delete</Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ApiDetailModal

import React from "react";

const IconArrowRight = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.8997 5.3996C13.6068 5.69249 13.6068 6.16737 13.8997 6.46026L18.6893 11.2499H3.5C3.08579 11.2499 2.75 11.5857 2.75 11.9999C2.75 12.4141 3.08579 12.7499 3.5 12.7499H18.6893L13.8997 17.5396C13.6068 17.8325 13.6068 18.3074 13.8997 18.6003C14.1926 18.8932 14.6674 18.8932 14.9603 18.6003L21.0303 12.5303C21.171 12.3896 21.25 12.1988 21.25 11.9999C21.25 11.801 21.171 11.6103 21.0303 11.4696L14.9603 5.3996C14.6674 5.10671 14.1926 5.10671 13.8997 5.3996Z"
        fill="currentcolor"
      />
    </svg>
  );
};

export default IconArrowRight;

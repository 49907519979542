import React from 'react'

const IcnMasterLogo = (props) => {
    return (
        <svg {...props} viewBox="0 0 73 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_6908_12763)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.1046 38.0042C53.9425 38.0042 60.2963 31.8467 60.2963 24.2511C60.2963 16.6555 53.9425 10.498 46.1046 10.498C42.5919 10.498 39.3772 11.7349 36.8991 13.7837C34.4209 11.735 31.2064 10.4983 27.6937 10.4983C19.8559 10.4983 13.5021 16.6557 13.5021 24.2513C13.5021 31.847 19.8559 38.0044 27.6937 38.0044C31.2065 38.0044 34.4212 36.7676 36.8993 34.7188C39.3775 36.7674 42.592 38.0042 46.1046 38.0042Z" fill="#FF0007" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M36.899 34.7189C39.9505 32.1963 41.8854 28.4427 41.8854 24.2511C41.8854 20.0595 39.9505 16.3059 36.899 13.7833C39.3771 11.7347 42.5916 10.498 46.1042 10.498C53.942 10.498 60.2958 16.6555 60.2958 24.2511C60.2958 31.8467 53.942 38.0042 46.1042 38.0042C42.5916 38.0042 39.3771 36.7675 36.899 34.7189Z" fill="#F9A000" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M36.8992 34.7183C39.9505 32.1957 41.8853 28.4422 41.8853 24.2507C41.8853 20.0593 39.9505 16.3058 36.8992 13.7832C33.8479 16.3058 31.9131 20.0593 31.9131 24.2507C31.9131 28.4422 33.8479 32.1957 36.8992 34.7183Z" fill="#FF5E00" />
            </g>
            <defs>
                <filter id="filter0_d_6908_12763" x="-3.43463" y="0.172852" width="79.9183" height="58.0228" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4.37909" />
                    <feGaussianBlur stdDeviation="2.18954" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6908_12763" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6908_12763" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export default IcnMasterLogo
import React from "react";

const IconSafe = (props) => {
  return (
    <svg {...props} viewBox="0 0 29 28" fill="none">
      <path
        d="M7.55762 11.6667V9.33337C7.55762 5.47171 8.72428 2.33337 14.5576 2.33337C20.391 2.33337 21.5576 5.47171 21.5576 9.33337V11.6667M14.5576 21.5834C15.3312 21.5834 16.073 21.2761 16.62 20.7291C17.167 20.1821 17.4743 19.4403 17.4743 18.6667C17.4743 17.8932 17.167 17.1513 16.62 16.6043C16.073 16.0573 15.3312 15.75 14.5576 15.75C13.7841 15.75 13.0422 16.0573 12.4952 16.6043C11.9482 17.1513 11.641 17.8932 11.641 18.6667C11.641 19.4403 11.9482 20.1821 12.4952 20.7291C13.0422 21.2761 13.7841 21.5834 14.5576 21.5834Z"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.391 25.6666H8.72432C4.05766 25.6666 2.89099 24.5 2.89099 19.8333V17.5C2.89099 12.8333 4.05766 11.6666 8.72432 11.6666H20.391C25.0577 11.6666 26.2243 12.8333 26.2243 17.5V19.8333C26.2243 24.5 25.0577 25.6666 20.391 25.6666Z"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconSafe;

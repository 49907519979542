import React, { useState } from "react";
import { Button } from "../../../components/ui/Button";
import BasicRadio from "../../../components/ui/BasicRadio";
import { useTranslation } from "react-i18next";
import BlockUser from "../../../components/common/BlockUser";

// const datas = ["English", "turkish"];
const datas = [{
  id: "en",
  lang: "English",
},
{
  id: "tr",
  lang: "Turkish",
},
{
  id: "en",
  lang: "Portugal",
},
];

const Language = () => {
  // const [plan, setPlan] = useState(datas[0]);
  const { t } = useTranslation();
  return (
    <div className="bg-card py-12 px-8 flex flex-col h-full">
      <div className="flex-grow h-full">

        <BasicRadio data={datas} />
      </div>
      {/* <Button className="w-full text-14 font-600" size="sm">
       {t('save')}
      </Button> */}
    </div>
  );
};

export default Language;

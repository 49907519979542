import React, { useContext, useEffect, useState } from 'react'
import BinanceIcon from "../../assets/images/BinanceIcon.png"
import BitcoinDetailModal from '../model/BitoinDetailModal'
import { WALLET_API_URL } from '../../api/api';
import { useQuery } from 'react-query';
import { files, fixDecimal, handleDecrypt, seperateToken } from '../../Helper';
import useAxiosPrivate from '../../api/useAxiosPrivate';
import Loader from '../ui/Loader';
import { AuthSocketContext } from '../../context/AuthSocketProvider';
import { authStore } from '../../context/AuthProvider';
import { match } from 'assert';

const TopCoins = ({ setSortedCoinList }) => {
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(true);
  const [tokens, setTokens] = useState([]);
  const [userVaultAccount, setUserVaultAccount] = useState([])
  const [matchedToken, setMatchedToken] = useState([]);
  const axiosInstance = useAxiosPrivate();
  const [selectedToken, setSelectedToken] = useState();
  const [tokenLists, setTokenLists] = useState();
  const [updatedTokens, setUpdatedTokens] = useState();
  const { user, walletName, userTotalBalance, set, tokenListLocal, userCurrencyRate, userCurrencyBalance } = authStore();
  const { userWalletAddress } = authStore();
  const setUserWalletAmount = authStore((state) => state.setUserWalletAmount)
  const setUserCurrencyBalance = authStore((state) => state.setUserCurrencyBalance)
  const handleOpen = (token) => {
    setSelectedToken(token)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const { currencyRate, socketClient } = useContext(AuthSocketContext);

  const [previousUser, setPreviousUser] = useState(null);

  async function getCoin(key) {
   
    const response = await axiosInstance.get(WALLET_API_URL.getCoinNew, {
      headers: {
        'encryptedkey': user?.encKey,
      }
    });
  
    if (socketClient?.connected) {
      socketClient.emit("requestCurrencyRate", { userId: user?._id }, () => {
        console.log("Currency rate requested via socket");
      });
    }
    setPreviousUser(response?.data);
    return response?.data;
   
  }

  
  async function test(response) {
    if (userCurrencyRate) {
        let filteredTokens = Array.isArray(response)
            ? response.filter(token => token.isDisplayOnHome === 1)
            : [];

        for (const token of filteredTokens) {
            let matchingWallet = userWalletAddress.find(wallet => wallet.chainId === token.tokenId);
            if (token.tokenId === null) {
                matchingWallet = userWalletAddress.find(wallet => wallet.chainId === token.id);
            }
            token.walletAddress = matchingWallet ? matchingWallet.address : null;

            // Fetch balance
            const getBalance = token.tokenId === null
                ? await callMainTokenApi(token)
                : await callSubTokenApi(token);
            token.balance = getBalance?.balance;

            // Calculate rate prices
            const tokenName = token?.symbol;
            const compareWithCurrencyRate = userCurrencyRate?.find(rate => rate?.symbol === `${tokenName}USDT`);
            if (compareWithCurrencyRate) token.ratePrice = compareWithCurrencyRate.price;

            // Calculate USD balance
            token.USDRateForUserBalance = (token?.balance * token?.ratePrice) || 0;
        }

        return filteredTokens;
    }
    return [];
}
  const { isLoading, data: getCoinList, refetch } = useQuery(
    ['getCoinList', localStorage.getItem('auth-storage')],
    async () => {
        const authStorage = JSON.parse(localStorage.getItem('auth-storage'));
        const response = await getCoin(authStorage?.user?.encKey);
        return test(response); // Pass the response explicitly
    },
    {
        enabled: userCurrencyBalance == undefined ,
        staleTime: 1000 * 60 * 5, // 5 minutes
  cacheTime: 1000 * 60 * 10, // 10 minutes
        keepPreviousData: true,
    }
);


// useEffect(() => {
//   if (userCurrencyRate) {
//       refetch(); // Ensure `test` is triggered via `useQuery`
//   }
// }, [userCurrencyRate]);
  // console.log({ userCurrencyBalance })
  // const { isLoading, data: getCoinList, refetch } = useQuery(['getCoinList'],localStorage.getItem('auth-storage'), () => getCoin(), { 

  //   enabled: userCurrencyBalance == undefined,
  //   keepPreviousData: true,
  //   // staleTime: 1000 * 60 * 5, // 5 minutes
  //   // cacheTime: 1000 * 60 * 10, // 10 minutes
  //   refetchOnWindowFocus: false, // Disable refetch on tab focus
  // })
  // const [localStorageData, setLocalStorageData] = useState(localStorage.getItem('auth-storage')); 
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const newData = localStorage.getItem('auth-storage');
  //     if (newData !== localStorageData) {
  //       setLocalStorageData(newData);
  //       refetch()
  //     }
  //   }, 1000); // Poll every second or adjust based on your needs

  //   return () => clearInterval(interval); 
  // }, [localStorageData]);


  const callMainTokenApi = async (token) => {
    try {
      const data = {
        'chain': token?.tokenSymbol ?? token?.symbol,
        'address': token?.walletAddress,
      }
      const response = await axiosInstance.post(WALLET_API_URL.getTokenBalance, data);
      return response.data;

    } catch (error) {
      console.log({ error })
    }
  }

  const callSubTokenApi = async (token) => {
    try {
      const data = {
        'chain': token?.tokenSymbol ?? token?.symbol,
        'address': token?.walletAddress,
        'contractAddress': token?.contractAddress,
      }
      const response = await axiosInstance.post(WALLET_API_URL.getSubTokenBalance, data);
      return response.data;
    } catch (error) {
      console.log({ error })
    }

  }

  // async function getVaultAccount() {
  //   setLoad(true);
  //   try {
  //     const response = await axiosInstance.get(WALLET_API_URL.getVaultAccount);
  //     response && setLoad(false)
  //     return response.data;
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoad(false);
  //   }
  // }
  // const { isLoadingData, data: vaultAccountList, refetch: vaultAccount } = useQuery(['vaultAccount'], () => getVaultAccount(), {
  //   enabled: userCurrencyBalance == undefined,
  //   keepPreviousData: true
  // })

  useEffect(() => {
    setTokens(tokenListLocal);

  }, [tokenListLocal])

  useEffect(() => {
    if (userCurrencyBalance == undefined) {

      if (getCoinList && Object.keys(userCurrencyRate).length > 0) {
        getCoinList?.map((token) => {
          const tokenName = token?.symbol;
          const addUSDT = tokenName + "USDT";
          const comapreWithCurrencyRate = userCurrencyRate?.find((rate) => rate?.symbol == addUSDT)
          if (comapreWithCurrencyRate) {
            token.ratePrice = comapreWithCurrencyRate.price;
            return token;
          }
        })
      }
      if (getCoinList) {
        getCoinList?.map((token) => {
          var newDecimal = ((token.tokenSymbol ?? "") === "TRX" && (token.symbol ?? "") === "USDT") || ((token.symbol ?? "") === "TRX")
            ? 1
            : Math.pow(10, token.decimal ?? 6);

          var newBalance = "";

          // Check if tokenSymbol is not empty
          if ((token.tokenSymbol ?? "").trim() !== "") {
            newBalance = (parseFloat(token?.balance) / newDecimal).toString();
          } else {
            newBalance = token?.balance;
          }

          // Set newBalance and calculate USD price
          token.newBalance = newBalance || 0;
          const USDPrice = parseFloat(newBalance) * parseFloat(token?.ratePrice);
          token.USDRateForUserBalance = USDPrice || 0;

          return token;

        })
      }
    }

  }, [getCoinList])


  const sortedCoinList = getCoinList?.sort((a, b) => {
    return parseFloat(b.USDRateForUserBalance) - parseFloat(a.USDRateForUserBalance);
  });
  const totalNewBalance = getCoinList?.reduce((total, token) => {
    return total + parseFloat(token.USDRateForUserBalance);
  }, 0);
  useEffect(() => {
    // if(userCurrencyBalance == undefined){
    setSortedCoinList(sortedCoinList);
    setUserWalletAmount(totalNewBalance);
    const newVariable = sortedCoinList?.map(token => ({
      id: token.id,
      newBalance: token.newBalance,
      tokenId: token?.tokenId,
      USDRateForUserBalance: token.USDRateForUserBalance,
      icon: token?.icon,
      symbol: token?.symbol,
      changellyAsset: token?.changellyAsset,
      name: token?.tokenId != null ? token?.tokenName : token?.name,
      address: token?.walletAddress,
      usdPrice: token?.usdPrice
    }));
    // if(userCurrencyBalance == undefined){
    setUserCurrencyBalance(newVariable);
    // }
    if (sortedCoinList?.length > 0 && userCurrencyBalance == undefined) {
      setLoad(false)
    }
    // }
  }, [totalNewBalance])


  return (
    <>
      <ul className='bg-card rounded-[20px] sm:px-[24px] px-[20px] sm:py-[10px] py-[8px] border border-light-400'>
        {
          sortedCoinList?.some(
            (token) => 'newBalance' in token && 'walletAddress' in token && token.newBalance && token.walletAddress
          ) ?
            sortedCoinList?.length ?
              sortedCoinList?.slice(0, 6).map((token, index) => {
              
                return (<li key={index} onClick={() => handleOpen(token)} className='p-[14px_0_14px] border-b border-b-light-300 flex justify-between last:border-0 cursor-pointer'>
                  <div className="flex gap-[14px] items-center">
                    {token?.icon ?
                      <img className='xxl:w-[48px] w-[36px] xxl:h-[48px] h-[36px] rounded-full object-cover' src={files(token?.icon, 'image')} alt="" />
                      :
                      <div className='xxl:w-[48px] w-[36px] xxl:h-[48px] h-[36px] rounded-full bg-theme' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 600, borderRadius: '50%', fontSize: '26px', }}>{token?.name?.split(' ').map(name => name.slice(0, 1).toUpperCase())}</div>
                    }
                    <div className="text">
                      <div className="flex items-center gap-[8px]">
                        <h5 className='text-white xxl:text-18 sm:text-16 text-14'>{token?.name}</h5>
                        {/* <p className='text-green sm:text-14 text-12'>+5.00%</p> */}
                      </div>
                      <div className="flex items-center gap-[8px]">
                        <p className='text-gray sm:text-14 text-12'>{token?.code ? token?.code : token?.symbol ?? '-'}</p>
                        <button className='text-gray sm:text-14 text-12 bg-dark p-[2px_6px] rounded-[6px]'>{token?.tokenChain ?? token?.chain}</button>
                      </div>
                    </div>
                  </div>
                  <div className="text-end">
                    <h5 className='text-white xxl:text-18 sm:text-16 text-14'>{parseFloat(token?.newBalance) > 0 ? parseFloat(fixDecimal(token?.newBalance)) : parseFloat(0).toFixed(1)}</h5>
                    {/* <p className='text-gray sm:text-14 text-12'>$ {(token?.minPaymentLinkUSD ?? 0)}</p> */}
                    <p className='text-gray sm:text-14 text-12'>$ {(token?.USDRateForUserBalance?.toFixed(2)) > 0 ? token?.USDRateForUserBalance?.toFixed(2) : parseFloat(0).toFixed(1)}</p>
                  </div>
                </li>)
              }) : <li className='p-[14px_0_14px] border-b border-b-light-300 flex justify-between last:border-0'>
                <div className="flex items-center gap-3"><h5 className='text-white text-18'>Record Not Found!</h5></div>
              </li>
            : <Loader innarClass='mt-0 py-[16px]' />
        }
      </ul>
      {/* } */}
      <BitcoinDetailModal open={open} handleClose={handleClose} token={selectedToken} />
    </>
  )
}

export default TopCoins
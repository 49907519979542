const IconWallet = (props) => {
  return (
    <svg {...props} viewBox="0 0 23 22" fill="none">
      <path d="M16.74 21.75H5.26C2.77 21.75 0.75 19.73 0.75 17.24V10.51C0.75 8.02002 2.77 6.00002 5.26 6.00002H16.74C19.23 6.00002 21.25 8.02002 21.25 10.51V11.95C21.25 12.36 20.91 12.7 20.5 12.7H18.48C18.13 12.7 17.81 12.83 17.58 13.07L17.57 13.08C17.29 13.35 17.16 13.72 17.19 14.1C17.25 14.76 17.88 15.29 18.6 15.29H20.5C20.91 15.29 21.25 15.63 21.25 16.04V17.23C21.25 19.73 19.23 21.75 16.74 21.75ZM5.26 7.50002C3.6 7.50002 2.25 8.85002 2.25 10.51V17.24C2.25 18.9 3.6 20.25 5.26 20.25H16.74C18.4 20.25 19.75 18.9 19.75 17.24V16.8H18.6C17.09 16.8 15.81 15.68 15.69 14.24C15.61 13.42 15.91 12.61 16.51 12.02C17.03 11.49 17.73 11.2 18.48 11.2H19.75V10.51C19.75 8.85002 18.4 7.50002 16.74 7.50002H5.26Z" fill="currentcolor" />
      <path d="M1.5 12.16C1.09 12.16 0.75 11.82 0.75 11.41V6.84002C0.75 5.35002 1.69 4.00002 3.08 3.47002L11.02 0.470021C11.84 0.160021 12.75 0.270021 13.46 0.770021C14.18 1.27002 14.6 2.08002 14.6 2.95002V6.75002C14.6 7.16002 14.26 7.50002 13.85 7.50002C13.44 7.50002 13.1 7.16002 13.1 6.75002V2.95002C13.1 2.57002 12.92 2.22002 12.6 2.00002C12.28 1.78002 11.9 1.73002 11.54 1.87002L3.6 4.87002C2.79 5.18002 2.24 5.97002 2.24 6.84002V11.41C2.25 11.83 1.91 12.16 1.5 12.16ZM18.6 16.8C17.09 16.8 15.809 15.68 15.689 14.24C15.609 13.41 15.909 12.6 16.509 12.01C17.019 11.49 17.719 11.2 18.469 11.2H20.549C21.539 11.23 22.299 12.01 22.299 12.97V15.03C22.299 15.99 21.539 16.77 20.579 16.8H18.6ZM20.529 12.7L18.48 12.7C18.13 12.7 17.81 12.83 17.58 13.07C17.29 13.35 17.149 13.73 17.189 14.11C17.249 14.77 17.879 15.3 18.599 15.3H20.559C20.689 15.3 20.809 15.18 20.809 15.03V12.97C20.809 12.82 20.689 12.71 20.529 12.7Z" fill="currentcolor" />
      <path d="M13 11.75H6C5.59 11.75 5.25 11.41 5.25 11C5.25 10.59 5.59 10.25 6 10.25H13C13.41 10.25 13.75 10.59 13.75 11C13.75 11.41 13.41 11.75 13 11.75Z" fill="currentcolor" />
    </svg>
  )
}

export default IconWallet
import React from 'react'

const IconSendWallet = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" >
      <path fillRule="evenodd" clipRule="evenodd" d="M18.5407 5.45926C18.6814 5.59992 18.7604 5.79068 18.7604 5.98959L18.7604 14.5739C18.7604 14.9881 18.4246 15.3239 18.0104 15.3239C17.5962 15.3239 17.2604 14.9881 17.2604 14.5739L17.2604 7.80025L6.51991 18.5407C6.22702 18.8336 5.75215 18.8336 5.45925 18.5407C5.16636 18.2478 5.16636 17.773 5.45925 17.4801L16.1997 6.73959L9.42612 6.73959C9.01191 6.73959 8.67612 6.40381 8.67612 5.98959C8.67612 5.57538 9.01191 5.23959 9.42612 5.23959L18.0104 5.23959C18.2093 5.23959 18.4001 5.31861 18.5407 5.45926Z" fill="currentcolor" />
    </svg>
  )
}

export default IconSendWallet
import React from 'react';

const IconCall = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none">
            <path d="M36.1739 28.0194L31.3893 23.2347C29.6805 21.5259 26.7755 22.2095 26.092 24.4309C25.5793 25.9689 23.8705 26.8233 22.3326 26.4815C18.9149 25.6271 14.3012 21.1842 13.4468 17.5957C12.9341 16.0577 13.9594 14.3489 15.4973 13.8363C17.7188 13.1528 18.4023 10.2478 16.6935 8.53897L11.9088 3.7543C10.5418 2.55814 8.49121 2.55814 7.29504 3.7543L4.0483 7.00104C0.801565 10.4187 4.39007 19.4754 12.4215 27.5068C20.4529 35.5382 29.5096 39.2976 32.9272 35.8799L36.1739 32.6332C37.3702 31.2661 37.3702 29.2156 36.1739 28.0194Z" fill="currentColor" />
        </svg>

    );
}

export default IconCall;

import React from "react";

const ContactCard = ({ data }) => {
  return (
    <div className="flex flex-col md:items-start items-center bg-light-200 border border-light-gray md:rounded-[20px] rounded-[12px] md:p-[34px_30px] p-[12px]">
      <div className="md:h-[80px] h-[55px] md:w-[80px] w-[55px] bg-linear rounded-full flex items-center justify-center">
        {data.logo}
      </div>
      <h4 className="md:text-22 text-18 font-600 text-black md:mt-[30px] mt-[16px] md:mb-[8px] mb-[4px]">
        {data.title}
      </h4>
      <h5 className="md:text-18 text-14 font-500 text-black text-center">
        {data.text}
      </h5>
    </div>
  );
};

export default ContactCard;

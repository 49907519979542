import React, { useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import SideBar from '../../components/dash/SideBar';
import DashHeader from '../../components/dash/DashHeader';

const DashMaster = () => {
  const location = useLocation();
  const [tooglesidebar, setTooglesidebar] = useState(false);


  return (
    <>
      <DashHeader setTooglesidebar={setTooglesidebar} tooglesidebar={tooglesidebar} />
      <main className='mt-[80px]'>
        <div className="main-layout-wrapper">
          <div className="flex min-h-[calc(100vh-80px)] ">
            <SideBar setTooglesidebar={setTooglesidebar} tooglesidebar={tooglesidebar} />
            <div className="main-container flex-grow min-h-[calc(100vh-80px)] overflow-y-auto theme-scrollbar">
              <Outlet />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default DashMaster
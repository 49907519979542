import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../../components/ui/Button";
import Input from "../../../components/ui/Input";
import { useLocation } from "react-router-dom";
import useAxiosPrivate from "../../../api/useAxiosPrivate";
import { SUPPORT_TICKET_API_URL, USER_API_URL } from "../../../api/api";
import { useMutation, useQuery } from "react-query";
import { checkFileType, files, fileTypeMismatchMsg } from "../../../Helper";
import IconClose from "../../../assets/svg/IconClose";
import ImagePreviewModal from "../../../components/model/ImagePreviewModal";
import Loader from "../../../components/ui/Loader";
import moment from "moment";
import { useSnackbar } from "notistack";
import { checkFileSize, fileSizeExceedMsg } from "../../../Helper";
import SendMessageImage from "../../../assets/images/SendMessage.png";

const SupportDetail = () => {
    const location = useLocation();
    const [ticket, setTicket] = useState();
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [uploadFile, setUploadFile] = useState('');
    const [previewFile, setPreviewFile] = useState('');
    const [isFilePreview, setIsFilePreview] = useState('');
    const [imagePreview, setImagePreview] = useState(false);
    const [image, setImage] = useState();
    const axiosInstance = useAxiosPrivate();
    const messageAreaRef = useRef(null);
    const customFileInputRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar()
    const [isMessageSending, setIsMessageSending] = useState(false);

    useEffect(() => {
        // Scroll to the bottom when messages are updated
        if (messageAreaRef.current) {
            messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
        }
    }, [messages]);

    async function getTicketMessages() {
        if (ticket !== undefined && ticket?._id !== undefined) {
            const response = await axiosInstance.get(SUPPORT_TICKET_API_URL.getMessages + '?ticketId=' + ticket?._id);
            return response.data;
        }
    }

    const {
        isLoading,
        data: messageList,
        refetch,
    } = useQuery(['messageList'], () => getTicketMessages(), { keepPreviousData: true, })

    useEffect(() => {
        if (messageList) {
            setMessages(messageList)
        }
    }, [messageList])


    useEffect(() => {
        setTicket(location?.state?.ticketDetail);
    }, [location]);

    const handleMessageOnChange = (event) => {
        setInputMessage(event.target.value);
    }

    const handleSendMessage = async () => {
        let messageData = {
            ticketId: ticket?._id,
            senderId: 2,
        }
        if (isMessageSending || inputMessage.trim() === '') {
            return false;
        }
        setIsMessageSending(true);
        try {
            if (inputMessage.trim() !== '') {
                messageData.message = inputMessage;
                messageData.type = 1 // type 1 == text
                await sendMessage(messageData)
                setMessages(prevMessages => [...prevMessages, messageData]);
            }
        } catch (e) {
            enqueueSnackbar("Something went wrong!", {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
            });
        }

        setIsMessageSending(false);
    }

    const handleKeyDown = async (event) => {

        if (event.key === 'Enter') {
            event.preventDefault();
            setIsMessageSending(true);

            if (inputMessage.trim() !== '') {
                const messageData = {
                    type: 1,
                    message: inputMessage,
                    ticketId: ticket?._id,
                    senderId: 2,
                }
                sendMessage(messageData);
                setMessages(prevMessages => [...prevMessages, messageData]);
                setInputMessage('');
            } else {
                enqueueSnackbar("Please add message!", {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
                setIsMessageSending(false);
                return false;
            }

            setIsMessageSending(false);
        }
    };

    const { mutateAsync: sendMessage } = useMutation(
        async (data) => {
            try {
                const response = await axiosInstance.post(SUPPORT_TICKET_API_URL.sendMessage, data)
                if (response) {
                    setInputMessage('')
                    setUploadFile('');
                    setPreviewFile(null);
                    setIsFilePreview(false);
                }
            } catch (error) {
                if (error?.response?.data.errors) {
                    const errors = error?.response?.data.errors;
                    Object.keys(errors).map(function (key) {
                        return errors[key]
                        // return enqueueSnackbar(errors[key], { variant: "error" });
                    });
                }
            }
        }
    );

    useEffect(() => {
        refetch();
    }, [location, ticket, refetch]);

    const handleFileInputOnChange = async (event) => {
        event.preventDefault();
        const selectedFile = event.target.files[0];

        if (!checkFileType(['image/jpg', 'image/jpeg', 'image/png'], selectedFile?.type)) {
            enqueueSnackbar(fileTypeMismatchMsg, {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
            });
            return false;
        }

        if (!checkFileSize(selectedFile?.size)) {
            enqueueSnackbar(fileSizeExceedMsg, {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
            });
            return false;
        }
        setIsMessageSending(true);
        let messageData = {
            ticketId: ticket?._id,
            senderId: 2,
        }
        let response;
        const formData = new FormData();
        formData.append("images", selectedFile)
        response = await uploadImage(formData);
        if (response) {
            messageData.message = response?.[0];
            messageData.type = 2;
        }
        await sendMessage(messageData);
        setMessages(prevMessages => [...prevMessages, messageData]);
        setIsMessageSending(false);
    }

    const handleCloseImagePreview = (event) => {
        setIsFilePreview(false);
        setIsFilePreview('');
        setPreviewFile('');
        if (customFileInputRef.current) {
            customFileInputRef.current.value = '';
        }
    }

    const { mutateAsync: uploadImage } = useMutation(
        async (data) => {
            try {
                const response = await axiosInstance.post(USER_API_URL.uploadUserImage, data, {
                    headers: {
                        'Content-type': 'multipart/form-data',
                    },
                })
                if (response) {
                    if (customFileInputRef.current) {
                        customFileInputRef.current.value = '';
                    }
                }

                return response?.data;
            } catch (error) {
                if (error?.response?.data.errors) {
                    const errors = error?.response?.data.errors;
                    Object.keys(errors).map(function (key) {
                        return errors[key]
                        // return enqueueSnackbar(errors[key], { variant: "error" });
                    });
                }
            }
        }
    );

    const imgPreview = (img) => {
        setImagePreview(true);
        setImage(img)
    }


    return (
        <>
            {
                isLoading ? <Loader /> :
                    <div className="bg-card py-4 pb-[70px] flex flex-col h-full relative">
                        <div className="flex-grow p-8 h-full overflow-y-auto theme-scrollbar" ref={messageAreaRef}>
                            <div className="">
                                <div className="">
                                    <div className="flex justify-between items-start w-full mb-4">
                                        <div className="text">
                                            <h6 className="text-16 font-500 text-white mb-4">
                                                {ticket?.subject}
                                            </h6>
                                        </div>
                                        {messages?.[0]?.status === "1" ?
                                            <Button
                                                className="!text-white bg-green flex gap-4 items-center text-14 font-400 px-6 py-3 border border-light-100"
                                                variant="gray"
                                                size="sm">
                                                Open
                                            </Button> :
                                            messages?.[0]?.status === "2" ?
                                                <Button
                                                    className="!text-white bg-red flex gap-4 items-center text-14 font-400 px-6 py-3 border border-light-100"
                                                    variant="gray"
                                                    size="sm">
                                                    Closed
                                                </Button>
                                                : messages?.[0]?.status === "3" ?
                                                    <Button
                                                        className="!text-black bg-gray flex gap-4 items-center text-14 font-400 px-6 py-3 border border-light-100"
                                                        variant="gray"
                                                        size="sm">
                                                        Responded
                                                    </Button> : <></>
                                        }
                                    </div>
                                </div>
                                {
                                    messages.length > 0 && messages?.map((message, index) => {
                                        return (
                                            <>
                                                <ul className="chat-list flex flex-col items-start mt-6">
                                                    {
                                                        message?.senderId === 2 /* senderid 2 is user */ ? (
                                                            message?.type === 1 ? (
                                                                <>
                                                                    <li className="py-2 px-4 sm:max-w-[80%] max-w-[90%] bg-dark text-white inline-block break-words text-[14px] ms-auto text-start min-w-[120px] rounded-lg mb-1">
                                                                        {message?.message}
                                                                    </li>
                                                                    <p className="text-white text-end w-full text-[10px]">{moment(message.createdAt).format('h:mm A')}</p>
                                                                </>
                                                            ) : message?.type === 2 ? (
                                                                <>
                                                                    <li className="py-2 px-4 sm:max-w-[80%] max-w-[90%] bg-dark text-white inline-block break-words text-[14px] ms-auto text-start min-w-[120px] rounded-lg mb-1">
                                                                        <img src={files(message?.message, 'image')}
                                                                            alt="attachment"
                                                                            onClick={() => imgPreview(message?.message)}
                                                                            style={{
                                                                                height: "150px",
                                                                                minWidth: "150px",
                                                                                width: '100%',
                                                                                cursor: 'pointer',
                                                                                objectFit: 'cover',
                                                                                borderRadius: "10px "
                                                                            }} />
                                                                    </li>
                                                                    <p className="text-white text-end w-full text-[10px]">{moment(message.createdAt).format('h:mm A')}</p>
                                                                </>
                                                            ) : <>null</>

                                                        )
                                                            : message?.senderId === 1 /* senderid 2 is admin */ ?
                                                                (

                                                                    message?.type === 1 ? (
                                                                        <>
                                                                            <li className="py-2 px-4 sm:max-w-[80%] max-w-[90%] bg-dark text-white text-[14px] inline-block break-words me-auto text-start min-w-[120px] rounded-lg mb-1">
                                                                                {message?.message}
                                                                            </li>
                                                                            <p className="text-white text-[10px]">{moment(message.createdAt).format('h:mm A')}</p>
                                                                        </>
                                                                    ) : message?.type === 2 ? (
                                                                        <>
                                                                            <li className="py-2 px-4 sm:max-w-[80%] max-w-[90%] bg-dark text-white text-[14px] inline-block break-words me-auto text-start min-w-[120px] rounded-lg mb-1">
                                                                                <img src={files(message?.message, 'image')}
                                                                                    alt="attachment"
                                                                                    onClick={() => imgPreview(message?.message)}
                                                                                    style={{
                                                                                        height: "150px",
                                                                                        minWidth: "150px",
                                                                                        width: '100%',
                                                                                        cursor: 'pointer',
                                                                                        objectFit: 'cover',
                                                                                        borderRadius: "10px "
                                                                                    }} />
                                                                            </li>
                                                                            <p className="text-white text-[10px]">{moment(message.createdAt).format('h:mm A')}</p>
                                                                        </>
                                                                    ) : "krunika"
                                                                ) : <></>
                                                    }
                                                </ul>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        {
                            (ticket?.status == '1' || ticket?.status == '3') && (
                                <div className="absolute bottom-1 w-full left-0 bg-card">
                                    {isFilePreview && (
                                        <div style={{
                                            textAlign: 'left',
                                            margin: '7px',
                                            position: 'relative',
                                            width: '140px',
                                            height: '140px'
                                        }}>
                                            <img
                                                src={previewFile}
                                                alt="Selected Preview"
                                                style={{
                                                    height: '100%',
                                                    width: '100%',
                                                    borderRadius: '8px',
                                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                                    marginLeft: '0',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    height: '24px',
                                                    width: '24px',
                                                    top: '-5px',
                                                    right: '-5px',
                                                    backgroundColor: 'white',
                                                    borderRadius: '50%',
                                                    padding: '5px',
                                                    zIndex: 1,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={handleCloseImagePreview}
                                            >
                                                <IconClose />
                                            </div>
                                        </div>

                                    )}

                                    <div className="relative p-4 py-2 pt-3">
                                        <div className="rounded-full overflow-hidden border border-light-100 bg-dark">
                                            <input
                                                type="text"
                                                className="w-full !text-white text-14 font-500 bg-dark ps-[16px] pe-[14px] outline-0  py-[16px] max-w-[calc(100%-74px)]"
                                                value={inputMessage}
                                                placeholder="Message...."
                                                maxLength={500}
                                                onChange={(event) => handleMessageOnChange(event)}
                                                onKeyDown={handleKeyDown}
                                            />
                                            <div className="flex absolute justify-center items-center right-[18px] md:top-[14px] sm:top-[12px] top-[16px] gap-6">
                                                <div class="image-upload">
                                                    <label for="file-input" className="cursor-pointer">
                                                        <img
                                                            src={require("../../../assets/images/AttachDocument.png")}
                                                            alt=""
                                                        />
                                                    </label>
                                                    <input
                                                        id="file-input"
                                                        type="file"
                                                        ref={customFileInputRef}
                                                        accept=".png,.jpg,.jpeg"
                                                        style={{ display: 'none' }}
                                                        onChange={(ev) => {
                                                            handleFileInputOnChange(ev);
                                                        }
                                                        }
                                                    />
                                                </div>
                                                <div className="flex justify-center items-center bg-theme sm:w-[40px] w-[30px] sm:h-[40px] h-[30px] rounded-full cursor-pointer"
                                                    onClick={handleSendMessage}>
                                                    {isMessageSending ? <div className="w-12 h-12 rounded-full animate-spin absolute border-4 border-solid border-green-200 border-t-transparent shadow-md"></div> :
                                                        <img
                                                            src={SendMessageImage}
                                                            alt=""
                                                        />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        <ImagePreviewModal imagePreview={imagePreview} setImagePreview={setImagePreview} image={image} />
                    </div>
            }
        </>
    );
};

export default SupportDetail;

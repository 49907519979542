import React, { useEffect, useState } from 'react'
import { Button } from '../../components/ui/Button'
import IconCamera from "../../assets/svg/IconCamera"
// import ProfileImg from "../../assets/images/Profile.png"
import Input from '../../components/ui/Input'
import PhoneInput from 'react-phone-input-2'
import { Form, FormikProvider, useFormik } from 'formik'
import BasicFileUpload from "../../components/ui/BasicFileUpload";
import { USER_API_URL } from '../../api/api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import UseStore, { authStore } from '../../context/AuthProvider'
import { files } from '../../Helper'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import Loader from '../../components/ui/Loader'
import { t } from 'i18next'
import { profileDetailValidation } from '../../validation/CommonValidation'
import EmailAuthentication from '../../components/model/EmailAuthentication'
import MobileVerifiaction from '../../components/model/MobileVerifiaction'
import { values } from 'lodash'
const Setting = () => {
  const [phone, setPhone] = useState();
  const [countryCode, setCountryCode] = useState("90"); // Set the default country code to 'in'
  const [ProfileImg, setProfileImg] = useState();
  const [Loading, setLoading] = useState();
  const [PreImg, setPreImg] = useState();
  const [selectedImg, setSelectedImg] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const setUser = authStore((state) => state?.setUser)
  const [imageName, setImageName] = useState();
  const { user } = authStore();
  const axiosInstance = useAxiosPrivate();
  const [isPhoneVerify, setIsPhoneVerify] = useState();
  const queryClient = useQueryClient();
  const [emailAuthenticationModal, setEmailAuthenticationModal] = useState(false);
  const [test, setTest] = useState();

  async function getUserProfile() {
    const response = await axiosInstance.get(USER_API_URL.userProfile);
    const updatedUser = {
      ...user,
      ...response?.data
    };
    setUser(updatedUser);
    return response.data;
  }
  const { isLoading, data: userData, refetch } = useQuery(['userProfile'], () => getUserProfile(), { keepPreviousData: true, })

  useEffect(() => {

    if (userData?.isPhoneVerify == 1) {
      setTest(true);
    }


  }, [userData?.isPhoneVerify, test])


  useEffect(() => {
    if (userData) {
      const updatedUser = {
        ...user,
        ...userData
      };
      setUser(updatedUser);
      setIsPhoneVerify(updatedUser?.isPhoneVerify)
      setProfileImg(files(userData?.profile, "image"))
      setPreImg(files(userData?.profile, "image"))
      if (userData?.countryCode !== null && userData?.mobile !== null) {
        setPhone(userData?.countryCode + userData?.mobile);
      }
      setValues({
        ...formik.values,
        country_code: userData?.countryCode || '',
        mobile_number: userData?.mobile || '',
      })
    }

  }, [userData])





  //  Image upload events
  const handleFileInputOnChange = (event) => {
    event.preventDefault();
    const selectedFile = event?.target?.files[0];
    if (selectedFile.size > 1 * 1024 * 1024) {
      enqueueSnackbar('File size exceeds the maximum limit of 1 MB. Please choose a smaller file.', {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
      return;
    }
    setSelectedImg(event?.target?.files[0]);
    let userImgSrc = URL.createObjectURL(selectedFile);
    setPreImg(userImgSrc);
  }

  const { mutateAsync: uploadImage } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(USER_API_URL.uploadUserImage, data, {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        });
        if (response) {
          setImageName(response?.data?.[0]);
          return response?.data?.[0]
        }
      } catch (error) {
        setLoading(false);
        if (error?.response?.data.errors) {
          const errors = error?.response?.data.errors;
          Object.keys(errors)?.map(function (key) {
            enqueueSnackbar(errors[key], {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        }
      }
    }
  );


  const { mutateAsync: updateProfie } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(USER_API_URL.updateProfile, data)
        if (response) {
          enqueueSnackbar(response?.data.message, {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
          setLoading(false);
          queryClient.invalidateQueries('userProfile')
        }
      } catch (error) {
        setLoading(false);
        if (error?.response?.data.errors) {
          const errors = error?.response?.data.errors;
          Object.keys(errors).map(function (key) {
            enqueueSnackbar(errors[key], {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        }
      }
    }
  );


  const handlePhoneChange = (value, data, event, formattedValue) => {
    const countryCode = data?.dialCode;
    const phoneNumber = value?.replace(`+${countryCode}`, '');
    setCountryCode(countryCode)
    setPhone(phoneNumber);
    setValues({
      ...formik.values,
      country_code: countryCode,
      mobile_number: phoneNumber,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: user?.name !== null ? user?.name : "",
      user_name: user?.userName !== undefined ? user?.userName : "",
      country_code: userData?.countryCode ? userData?.countryCode : "",
      mobile_number: user ? userData?.mobile.replace(userData?.countryCode) : "",
      email: user ? user?.email : "",
    },

    validationSchema: profileDetailValidation,

    onSubmit: async (values) => {
      setLoading(true);
      let response = '';
      if (selectedImg) {
        const formData = new FormData();
        formData.append("images", selectedImg)
        response = await uploadImage(formData);
        setImageName(response);
      }
      let numbersAfterCountryCode;
      if (phone?.startsWith(countryCode)) {
        numbersAfterCountryCode = phone?.slice(countryCode?.length);
      }
      const data = {
        name: values?.name,
        user_name: values?.user_name,
        country_code:  values?.country_code ? values?.country_code : userData?.countryCode ? userData?.countryCode :  !numbersAfterCountryCode ? '90' : countryCode,
        // mobile_number: numbersAfterCountryCode ? numbersAfterCountryCode : "",
        mobile_number: values?.mobile_number ? values?.mobile_number?.replace(values?.country_code) : userData?.mobile?.replace(values?.country_code),
        email: values?.email,
        oldImage: response && user?.profile || '',
        image: response ? response : user?.profile
      }
      await updateProfie(data);
    },
  });

  const {
    handleSubmit,
    getFieldProps,
    errors,
    values,
    touched,
    setValues,
    setErrors,
    resetForm
  } = formik;

  const handleOpenEmailAuthenticationModal = () => {
    setEmailAuthenticationModal(true);
  };
  const handleCloseEmailAuthentication = () => {
    setEmailAuthenticationModal(false);
  };

  const handleVerifiedApi = async () => {

    const data = {
      "mobile": values?.mobile_number,
      "countryCode": values?.country_code,
    }

    try {
      const response = await axiosInstance.post(USER_API_URL.sendMobileOtp, data)
      if (response.status == 200) {

        enqueueSnackbar(response?.data?.message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });

        handleOpenEmailAuthenticationModal()

      }

    } catch (error) {

      const errors = error?.response?.data.errors;
      Object.keys(errors).map(function (key) {

        enqueueSnackbar(errors[key], {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });

      });
    }



  }


  return (
    <>
      {isLoading ? <Loader /> :
        <div className="bg-card min-h-full mx-auto flex flex-col p-12">
          <FormikProvider value={formik}>
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <div className="relative w-max mx-auto mb-20">
                <BasicFileUpload
                  accept="image/png, image/jpeg"
                  avatar={ProfileImg}
                  PreImg={PreImg}
                  userData={userData}
                  onChange={handleFileInputOnChange}
                />
              </div>
              <Input
                className="mb-8 bg-dark"
                lableClass="text-14"
                lable={t("Name")}
                errorMsg={errors.name && touched.name ? errors.name : null}
                placeholder={t("enter_name")}
                {...getFieldProps('name')}
              />
              <Input
                className="mb-8 bg-dark"
                lableClass="text-14"
                lable={t("User_Name")}
                errorMsg={errors.user_name && touched.user_name ? errors.user_name : null}
                placeholder={t("enter_username")}
                {...getFieldProps('user_name')}

              />
              <Input
                disabled
                className="mb-8 bg-dark truncate !pe-[76px]"
                lableClass="text-14"
                lable={t("Email_Address")}
                placeholder="Enter New Email Address"
                // suffix={
                //   <span onClick={() => handleVerifiedApi()}>
                //     {userData?.isPhoneVerify == 1 ? 'Verified' : 'Unverified'}

                //   </span>
                // }
                {...getFieldProps('email')}

              />
              <label htmlFor="" className="!text-white text-14 font-500 mb-4 block">
                {t("Mobile_Number")}
              </label>
              <div className="bg-dark rounded-full p-3 flex items-center w-full relative border border-light-100">
                <PhoneInput
                  country={'tr'}
                  value={phone}
                  className="w-full"
                  inputClass="!bg-transparent !border-0 text-white !w-full p-[14px] rounded-full !cursor-text"
                  buttonClass="!bg-transparent !border-0 hover:!bg-transparent"
                  onChange={handlePhoneChange}
                  disabled={(isPhoneVerify == 1 || test == 1) ? true : false}
                />
                <span className={`absolute cursor-pointer ${(isPhoneVerify == 1 || test == 1) ? 'bg-linear-horizontal' : 'bg-red'} !text-white text-[14px] p-3 rounded-full right-[50px] top-[50%] transform translate-x-1/2 -translate-y-1/2 font-500`} onClick={() => {
                  if (isPhoneVerify == 0) {
                    handleVerifiedApi()

                  }
                }
                }
                >
                  {(userData?.isPhoneVerify == 1 || (test == 1 && test !== undefined)) ? 'Verified' : 'Unverified'}

                </span>
              </div>
              <div className="mt-auto">
                <Button size="sm" className={`w-full mt-20 text-white text-[14px] ${Loading ? 'opacity-50 cursor-not-allowed' : ''}`}>
                  {Loading ? t("loading") : t("Save_Change")}
                </Button>
              </div>
            </Form>
          </FormikProvider>

        </div>}

      <MobileVerifiaction
        open={emailAuthenticationModal}
        data={values}
        setTest={setTest}
        handleClose={handleCloseEmailAuthentication}
        setClose={setEmailAuthenticationModal}

      />
    </>
  );
};

export default Setting;

import React from 'react'

const IconMenu = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none">
      <path d="M21.75 6H2.25C1.85218 6 1.47064 5.84196 1.18934 5.56066C0.908035 5.27936 0.75 4.89782 0.75 4.5C0.75 4.10218 0.908035 3.72064 1.18934 3.43934C1.47064 3.15804 1.85218 3 2.25 3H21.75C22.1478 3 22.5294 3.15804 22.8107 3.43934C23.092 3.72064 23.25 4.10218 23.25 4.5C23.25 4.89782 23.092 5.27936 22.8107 5.56066C22.5294 5.84196 22.1478 6 21.75 6ZM21.75 21H2.25C1.85218 21 1.47064 20.842 1.18934 20.5607C0.908035 20.2794 0.75 19.8978 0.75 19.5C0.75 19.1022 0.908035 18.7206 1.18934 18.4393C1.47064 18.158 1.85218 18 2.25 18H21.75C22.1478 18 22.5294 18.158 22.8107 18.4393C23.092 18.7206 23.25 19.1022 23.25 19.5C23.25 19.8978 23.092 20.2794 22.8107 20.5607C22.5294 20.842 22.1478 21 21.75 21ZM21.75 13.5H2.25C1.85218 13.5 1.47064 13.342 1.18934 13.0607C0.908035 12.7794 0.75 12.3978 0.75 12C0.75 11.6022 0.908035 11.2206 1.18934 10.9393C1.47064 10.658 1.85218 10.5 2.25 10.5H21.75C22.1478 10.5 22.5294 10.658 22.8107 10.9393C23.092 11.2206 23.25 11.6022 23.25 12C23.25 12.3978 23.092 12.7794 22.8107 13.0607C22.5294 13.342 22.1478 13.5 21.75 13.5Z" fill="currentcolor" />
    </svg>
  )
}

export default IconMenu
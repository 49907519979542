import React from 'react';

const IcnBlockUser = (props) => {
    return (
        <svg {...props} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M75.2429 39.9168C75.2429 59.3986 59.4553 75.1852 39.9744 75.1852C35.3434 75.186 30.7576 74.2742 26.4791 72.5019C22.2006 70.7296 18.3133 68.1316 15.0393 64.8564C11.7629 61.582 9.16375 57.6942 7.39053 53.4149C5.61733 49.1356 4.70479 44.5489 4.70508 39.9168C4.70508 20.4404 20.4971 4.64746 39.9744 4.64746C59.4553 4.64835 75.2429 20.4412 75.2429 39.9168Z" fill="#F9F9F9" />
            <path d="M39.9787 22.3525C42.3121 22.3535 44.5497 23.2812 46.1992 24.9316C47.8488 26.582 48.7754 28.82 48.7752 31.1534C48.7726 32.4781 48.4711 33.7852 47.893 34.977C47.3149 36.1689 46.4752 37.2149 45.4365 38.037C44.3254 39.2601 44.8845 40.213 45.8712 40.5934C50.7894 41.6814 54.3956 45.5365 54.3956 50.8619C54.3956 53.3801 54.6072 53.9304 51.8774 53.9304H27.488C25.3627 53.9304 25.5574 52.5144 25.5574 50.8619C25.5574 45.5365 29.1689 41.677 34.0845 40.5934C35.0614 40.213 35.6214 39.2734 34.544 38.0654C33.5668 37.297 32.7634 36.3302 32.1869 35.2287C31.6104 34.1273 31.274 32.9161 31.1996 31.6751C31.1252 30.4341 31.3148 29.1914 31.7556 28.029C32.1965 26.8666 32.8788 25.8108 33.7574 24.9313C34.574 24.1137 35.5439 23.4651 36.6114 23.0227C37.6789 22.5802 38.8232 22.3525 39.9787 22.3525Z" fill="#1A1A1A" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M39.9736 7.27832C21.9772 7.27832 7.33008 21.9228 7.33008 39.9174C7.33008 57.913 21.9781 72.561 39.9736 72.561C45.7514 72.561 51.1816 71.0428 55.8936 68.3948L51.9354 64.4365C48.2121 66.2618 44.1203 67.2105 39.9736 67.2099C24.867 67.2099 12.6803 55.0241 12.6803 39.9174C12.6695 33.8957 14.6562 28.0404 18.3292 23.2685L28.3994 33.337C27.9398 31.0605 28.3532 28.481 29.1807 26.5556L21.9967 19.3708C26.9689 15.0102 33.3603 12.6121 39.9736 12.6259C55.075 12.6259 67.2696 24.8152 67.2696 39.9174C67.2837 46.533 64.8839 52.9265 60.5203 57.8988L56.8536 54.2268C55.9327 56.0045 53.7283 56.7885 51.8483 56.7885L60.4092 65.3477C67.8438 59.3574 72.6181 50.1859 72.6181 39.9174C72.6172 21.9219 57.9674 7.27832 39.9736 7.27832Z" fill="#FF2A2A" />
        </svg>

    );
}

export default IcnBlockUser;

import React from 'react'
import { authStore } from '../context/AuthProvider'
import { Navigate } from 'react-router-dom';

const GuestGuard = ({ children }) => {
    const state = authStore();
    const isAuthenticated = state.isAuthenticated;

    if (isAuthenticated ) {
        return <Navigate to={"/dashboard"} />;
    }

    return (<>{children}</>)
}

export default GuestGuard
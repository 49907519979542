import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import SnackProvider from "./context/SnackbarProvider";
import SocketContextProvider from "./context/SocketProvider";
import { AuthProvider } from "./context/AuthProvider";
import "./custom.css";
import ClientProvider from "./react-query";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import AuthSocketContextProvider from "./context/AuthSocketProvider";
import { HelmetProvider } from "react-helmet-async";
import HelperContextProvider from "./context/HelperContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <SnackProvider>
            <ClientProvider>
              <AuthSocketContextProvider>
                <AuthProvider>
                  <HelperContextProvider>
                    <App />
                  </HelperContextProvider>
                </AuthProvider>
              </AuthSocketContextProvider>
            </ClientProvider>
          </SnackProvider>
        </BrowserRouter>
      </I18nextProvider>
    </HelmetProvider>
  </React.StrictMode>
);
